import React from "react";
import classNames from "classnames";
import { Image, Icon, Popup } from "semantic-ui-react";

const ImageGalleryThumb = ({
  src,
  alt,
  scale = 100,
  title,
  hoverIcon,
  className,
  onClick,
  style = {},
  ...props
}) => {
  const thumb = (
    <div
      {...props}
      className={classNames(
        "image-gallery-thumb",
        { dimmable: hoverIcon },
        { selectable: onClick },
        className
      )}
      onClick={onClick}
      style={{
        ...style,
        height: `calc(${
          className?.split(" ").includes("major") ? "9rem" : "7rem"
        } * ${scale / 100})`,
      }}
    >
      <Image src={src} alt={alt} />
      {hoverIcon && <Icon name={hoverIcon} size="large" />}
    </div>
  );

  if (!title) return thumb;

  return (
    <Popup
      content={title}
      position="bottom center"
      on="hover"
      inverted
      trigger={thumb}
    />
  );
};

export default ImageGalleryThumb;
