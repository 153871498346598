import React from "react";
import { useUserInitials } from "../auth";
import classNames from "classnames";
import { Image } from "semantic-ui-react";
import FallbackThumbnail from "assets/placeholders/profile-thumb.png";

const iconWithImage = (image, className, props) => (
  <Image
    src={image}
    circular
    className={classNames("user-icon", className)}
    {...props}
  />
);

const iconWithInitials = (initials, className, props) => (
  <div className={classNames("user-icon", className)} {...props}>
    {initials}
  </div>
);

const UserIcon = ({ image, className, userName, ...props }) => {
  if (image) {
    return iconWithImage(image, className, props);
  }

  if (userName) {
    const initials = useUserInitials(userName);
    return iconWithInitials(initials, className, props);
  }

  return iconWithImage(FallbackThumbnail, className, props);
};

export default UserIcon;
