import React from "react";
import { Link } from "react-router-dom";
import { useLoggedIn, NotFound } from "@redriver/cinnamon";
import { SystemPageContent } from "components/pages";
import { AppRoutes, SystemRoutes } from "constants/routes";

const PublicFallbackPage = () => {
  const loggedIn = useLoggedIn();
  return (
    <SystemPageContent>
      <NotFound />
      <p>
        Return to{" "}
        <Link to={loggedIn ? AppRoutes.Root : SystemRoutes.Login}>
          {loggedIn ? "Portal" : "Log In"}
        </Link>
      </p>
    </SystemPageContent>
  );
};

export default PublicFallbackPage;
