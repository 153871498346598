import React from "react";
import { Segment } from "semantic-ui-react";
import classNames from "classnames";

const MessageBox = ({
  children,
  className,
  icon,
  iconAlt = "icon",
  title,
  ...props
}) => {
  return (
    <Segment className={classNames("message-box", className)} {...props}>
      <header>
        {icon && (
          <img style={{ marginRight: "0.5rem" }} src={icon} alt={iconAlt} />
        )}

        {title}
      </header>
      {children}
    </Segment>
  );
};

export default MessageBox;
