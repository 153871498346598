import React from "react";
import { registerLookup } from "@redriver/cinnamon";
import { OrganisationLookupItem } from "components/organisations";
import {
  getEventStatuses,
  getEventFormats,
  getEventLocationTypes,
  getEventPartnerTypes,
  getEventPartnerPrivacy,
  getEventDetails,
  getNoteTypes,
  getProgrammeThemes,
  getProgrammeContentTypes,
  getParticipantTypes,
  getParticipationStatuses,
  getResourceTypes,
  getResourceScopes,
  getResourceParticipantAccess,
  getParticipantEventDetails,
  getSocialMediaTypes,
  getEventCategories,
  getIdeaDetails,
  getIdeaStatuses,
  getIdeaTeamMembers,
  getEventTeamMembers,
  getConceptStatuses,
  getSeriesDetails,
  getOrganisationDetails,
  getOrganisationTypes,
  getMyProfileImages,
  getConceptDetails,
  getRegions,
  getCountries,
  getContactDetails,
  getGenders,
  getContactPreferences,
  getSiteTypes,
  getPriorities,
  getOnlineOfflineStatuses,
  getFundingStatuses,
  getParticipantFeeTypes,
  getParticipantPaymentMethods,
  getQuarters,
  getOdaEligibility,
  getSectors,
  getProgrammeSubThemes,
  getParticipantUnableReasons,
  getParticipantRepresentations,
  getParticipantAttendanceCategories,
  getFriendsOfWiltonPark,
  GetFundingPaymentScheduleStatuses,
  getContactStatuses,
  getOrganisationStatuses,
  getAvailableOrganisations,
  getDepartmentDetails,
  getPurchaseOrderCostTypes,
  getTimeRelativity,
  getEventSecurityLevels,
  getAvailableOriginatingEvents,
  getPurchaseOrderDetails,
} from "./actions";

export const eventStatusLookup = registerLookup(
  "eventStatus",
  getEventStatuses
);

export const eventFormatLookup = registerLookup("eventFormat", getEventFormats);

export const eventLocationTypeLookup = registerLookup(
  "eventLocationType",
  getEventLocationTypes
);

export const eventPartnerTypeLookup = registerLookup(
  "eventPartnerType",
  getEventPartnerTypes
);

export const eventPartnerPrivacyLookup = registerLookup(
  "eventPartnerPrivacy",
  getEventPartnerPrivacy
);

export const eventDetailsLookup = registerLookup(
  "eventDetailsLookup",
  getEventDetails,
  {
    cacheExpiry: 5000,
  }
);

export const noteTypeLookup = registerLookup("noteType", getNoteTypes);

export const programmeThemeLookup = registerLookup(
  "programmeTheme",
  getProgrammeThemes,
  { cache: false }
);

export const programmeContentTypeLookup = registerLookup(
  "programmeContentType",
  getProgrammeContentTypes
);

export const participantTypeLookup = registerLookup(
  "participantType",
  getParticipantTypes,
  {
    cache: false,
  }
);

export const participationStatusLookup = registerLookup(
  "participationStatus",
  getParticipationStatuses
);

export const resourceTypeLookup = registerLookup(
  "resourceType",
  getResourceTypes
);

export const resourceScopeLookup = registerLookup(
  "resourceScope",
  getResourceScopes
);

export const resourceParticipantAccessLookup = registerLookup(
  "resourceParticipantAccess",
  getResourceParticipantAccess
);

export const participantEventDetailsLookup = registerLookup(
  "participantEventDetailsLookup",
  getParticipantEventDetails,
  {
    cacheExpiry: 5000,
  }
);

export const socialMediaTypesLookup = registerLookup(
  "socialMediaTypes",
  getSocialMediaTypes
);

export const eventCategoriesLookup = registerLookup(
  "eventCategories",
  getEventCategories
);

export const ideaDetailsLookup = registerLookup(
  "ideaDetailsLookup",
  getIdeaDetails,
  {
    cacheExpiry: 5000,
  }
);

export const ideaStatusLookup = registerLookup("ideaStatus", getIdeaStatuses);

export const ideaTeamMembersLookup = registerLookup(
  "ideaTeamMembersLookup",
  getIdeaTeamMembers,
  { cacheLimit: 1 }
);

export const eventTeamMembersLookup = registerLookup(
  "eventTeamMembersLookup",
  getEventTeamMembers,
  { cacheLimit: 1 }
);

export const conceptStatusLookup = registerLookup(
  "conceptStatus",
  getConceptStatuses
);

export const seriesDetailsLookup = registerLookup(
  "seriesDetailsLookup",
  getSeriesDetails,
  {
    cacheExpiry: 5000,
  }
);

export const organisationDetailsLookup = registerLookup(
  "organisationDetailsLookup",
  getOrganisationDetails,
  {
    cacheExpiry: 5000,
  }
);

export const myProfileImagesLookup = registerLookup(
  "myProfileImages",
  getMyProfileImages
);

export const organisationTypeLookup = registerLookup(
  "organisationType",
  getOrganisationTypes,
  {
    cache: false,
  }
);

export const conceptDetailsLookup = registerLookup(
  "conceptDetailsLookup",
  getConceptDetails,
  {
    cacheExpiry: 5000,
  }
);

export const regionsLookup = registerLookup("regionsLookup", getRegions);
export const countriesLookup = registerLookup("countriesLookup", getCountries);

export const genderLookup = registerLookup("genderLookup", getGenders);

export const contactDetailsLookup = registerLookup(
  "contactDetailsLookup",
  getContactDetails,
  {
    cacheExpiry: 5000,
  }
);

export const contactPreferencesLookup = registerLookup(
  "contactPreferencesLookup",
  getContactPreferences
);

export const siteTypesLookup = registerLookup("siteTypes", getSiteTypes);
export const prioritiesLookup = registerLookup(
  "prioritiesLookup",
  getPriorities
);
export const onlineOfflineStatusesLookup = registerLookup(
  "onlineOfflineStatusesLookup",
  getOnlineOfflineStatuses
);

export const fundingStatusesLookup = registerLookup(
  "fundingStatusesLookup",
  getFundingStatuses
);

export const participantFeeTypeLookup = registerLookup(
  "participantFeeTypeLookup",
  getParticipantFeeTypes
);

export const participantPaymentMethodLookup = registerLookup(
  "participantPaymentMethodLookup",
  getParticipantPaymentMethods
);

export const quarterLookup = registerLookup("quarterLookup", getQuarters);

export const odaEligibilityLookup = registerLookup(
  "odaEligibilityLookup",
  getOdaEligibility
);
export const sectorsLookup = registerLookup("getSectors", getSectors);

export const programmeSubThemesLookup = registerLookup(
  "programmeSubThemesLookup",
  getProgrammeSubThemes
);

export const friendsOfWiltonParkLookup = registerLookup(
  "friendsOfWiltonParkLookup",
  getFriendsOfWiltonPark
);

export const participantUnableReasonsLookup = registerLookup(
  "participantUnableReasonsLookup",
  getParticipantUnableReasons
);

export const participantRepresentationsLookup = registerLookup(
  "participantRepresentationsLookup",
  getParticipantRepresentations
);

export const participantAttendanceCategoriesLookup = registerLookup(
  "participantAttendanceCategoriesLookup",
  getParticipantAttendanceCategories
);

export const contactStatusLookup = registerLookup(
  "contactStatusLookup",
  getContactStatuses
);

export const organisationStatusLookup = registerLookup(
  "organisationStatusLookup",
  getOrganisationStatuses
);

export const fundingPaymentScheduleStatusesLookup = registerLookup(
  "fundingPaymentScheduleStatusesLookup",
  GetFundingPaymentScheduleStatuses
);

export const availableOrganisationsLookup = registerLookup(
  "availableOrganisationsLookup",
  getAvailableOrganisations,
  {
    transform: (data) => {
      return data.results.map((x) => ({
        text: x.text,
        value: x.value,
        content: (
          <OrganisationLookupItem
            name={x.text}
            townOrCity={x.townOrCity}
            country={x.country}
          />
        ),
      }));
    },
  }
);

export const departmentDetailsLookup = registerLookup(
  "departmentDetailsLookup",
  getDepartmentDetails,
  {
    cacheExpiry: 5000,
  }
);

export const purchaseOrderCostTypesLookup = registerLookup(
  "purchaseOrderCostTypesLookup",
  getPurchaseOrderCostTypes
);

export const timeRelativityLookup = registerLookup(
  "timeRelativityLookup",
  getTimeRelativity
);

export const eventSecurityLevelsLookup = registerLookup(
  "eventSecurityLevelsLookup",
  getEventSecurityLevels
);

export const availableOriginatingEventsLookup = registerLookup(
  "availableOriginatingEventsLookup",
  getAvailableOriginatingEvents
);

export const purchaseOrderDetailsLookup = registerLookup(
  "purchaseOrderDetailsLookup",
  getPurchaseOrderDetails,
  {
    cacheExpiry: 5000,
  }
);
