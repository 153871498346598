import { ActionTypes } from "./actions";
import { DateTime } from "luxon";
import {
  requestIsBusy,
  requestIsSuccess,
  requestResponse,
} from "@redriver/cinnamon";
import { produce } from "immer";

const defaultVisibleDate = DateTime.local()
  .startOf("month")
  .startOf("week")
  .toISODate();
const defaultDate = DateTime.local().startOf("week").toISODate();
const initialState = {
  selectedWeek: defaultDate,
  visibleFrom: defaultVisibleDate,
  visibleWeeks: [],
  items: [],
  filteredItems: null,
  loadingItems: false,
  filters: { isMonthView: true }, // default month view to true
  hasActiveFilters: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SetSelectedWeek: {
      const nextSelected = DateTime.fromISO(action.selectedWeek);
      const prevSelected = DateTime.fromISO(state.selectedWeek);

      const nextState = produce(state, (draft) => {
        let nextVisibleWeeks = [draft.visibleFrom];
        const visibleWeekCount =
          action.visibleWeekCount || state.visibleWeeks.length;
        while (
          nextVisibleWeeks.length < visibleWeekCount &&
          state.visibleWeeks.length != 0
        ) {
          const last = DateTime.fromISO(
            nextVisibleWeeks[nextVisibleWeeks.length - 1]
          );
          nextVisibleWeeks.push(last.plus({ weeks: 1 }).toISODate());
        }

        const isCurrentlyVisible = draft.visibleWeeks.some(
          (x) => x == action.selectedWeek
        );

        //handle moving after the lastest week in the array
        if (nextSelected > prevSelected && !isCurrentlyVisible) {
          let last = DateTime.fromISO(
            nextVisibleWeeks[nextVisibleWeeks.length - 1]
          );
          while (last < nextSelected) {
            last = last.plus({ weeks: 1 });
            nextVisibleWeeks.push(last.toISODate());
            if (nextVisibleWeeks.length > visibleWeekCount) {
              const sizeDiff = nextVisibleWeeks.length - visibleWeekCount;
              nextVisibleWeeks.splice(0, sizeDiff);
            }
          }
        }
        // handle moving before the earliest week in the array
        else if (nextSelected < prevSelected && !isCurrentlyVisible) {
          let first = DateTime.fromISO(nextVisibleWeeks[0]);
          while (first > nextSelected) {
            first = first.plus({ weeks: -1 });
            nextVisibleWeeks.unshift(first.toISODate());
            if (nextVisibleWeeks.length > visibleWeekCount) {
              const sizeDiff = nextVisibleWeeks.length - visibleWeekCount;
              nextVisibleWeeks.splice(
                nextVisibleWeeks.length - sizeDiff,
                sizeDiff
              );
            }
          }
        }
        // handle if target week is not visible
        else if (!isCurrentlyVisible) {
          const selectedWeek = DateTime.fromISO(action.selectedWeek);
          const selectedMonth = selectedWeek.startOf("month").startOf("week");
          const weeksDiff = selectedWeek.diff(selectedMonth, "weeks");
          // when there is space display from start of month otherwise from the selected week
          nextVisibleWeeks =
            weeksDiff.weeks < visibleWeekCount
              ? [selectedMonth.toISODate()]
              : [selectedWeek.toISODate()];
          while (nextVisibleWeeks.length < visibleWeekCount) {
            const last = DateTime.fromISO(
              nextVisibleWeeks[nextVisibleWeeks.length - 1]
            );
            nextVisibleWeeks.push(last.plus({ weeks: 1 }).toISODate());
          }
        }

        draft.selectedWeek = action.selectedWeek;
        draft.visibleWeeks = nextVisibleWeeks;
        draft.visibleFrom = nextVisibleWeeks[0];
      });

      return nextState;
    }

    case ActionTypes.LoadCalendarItems: {
      const loading = requestIsBusy(action);
      const success = requestIsSuccess(action);
      const response = requestResponse(action);

      return produce(state, (draft) => {
        // merge incoming data and add luxon versions of to/from date
        let next = [];
        if (success) {
          next = draft.items;
          response.forEach((x) => {
            x.fromDate = DateTime.fromISO(x.from);
            x.toDate = DateTime.fromISO(x.to);
            if (x.ownerTeamMember) {
              x.teamMembers.unshift(x.ownerTeamMember);
            }
            const existingIndex = next.findIndex((a) => a.id == x.id);
            if (existingIndex > -1) {
              next[existingIndex] = x;
            } else {
              next.push(x);
            }
          });
          next = next.sort((a, b) => {
            if (!a.isExternal && b.isExternal) return -1;
            if (a.isExternal && !b.isExternal) return 1;
            if (a.fromDate > b.fromDate) return 1;
            if (a.fromDate < b.fromDate) return -1;
            return 0;
          });
        }

        draft.loadingItems = loading;
        draft.items = success ? next : state.items;

        if (success) {
          draft.filteredItems = calcFilteredItems(draft.items, draft.filters);
        }
      });
    }

    case ActionTypes.ClearCalendarItems:
      return {
        ...state,
        items: [],
        filteredItems: [],
      };

    case ActionTypes.UpdateFilters:
      return produce(state, (draft) => {
        draft.filters = action.filters;
        // cache if there are currently active filters
        draft.hasActiveFilters =
          !!(action.filters.search || "").trim() ||
          !!(
            (action.filters.teamMembersIds &&
              action.filters.teamMembersIds.length > 0) ||
            null
          ) ||
          !!(
            (action.filters.categories &&
              action.filters.categories.length > 0) ||
            null
          ) ||
          !!(
            (action.filters.formats && action.filters.formats.length > 0) ||
            null
          );
        // apply filters to existing data items
        draft.filteredItems = calcFilteredItems(draft.items, draft.filters);
      });

    case ActionTypes.ResetCalendarItems: {
      return {
        ...state,
        items: [],
        filteredItems: null,
        selectedWeek: defaultDate,
        visibleFrom: defaultVisibleDate,
        visibleWeeks: [],
      };
    }

    case ActionTypes.RemoveItemFromCalendar:
      return produce(state, (draft) => {
        draft.items = draft.items.filter((x) => x.id != action.id);
        draft.filteredItems = draft.filteredItems.filter(
          (x) => x.id != action.id
        );
      });

    default:
      return state;
  }
};

function calcFilteredItems(items, filters) {
  return (items || []).map((x) => {
    const searchTerm = (filters.search || "").trim().toLowerCase();
    const code = x.code || "";
    const name = x.name || "";
    let matchesSearch =
      searchTerm !== ""
        ? code.toLowerCase().includes(searchTerm) ||
          name.toLowerCase().includes(searchTerm)
        : true;

    const teamMembersIds = filters.teamMembersIds || [];
    const teamMembers = x.teamMembers || [];
    let matchesTeamMembers =
      teamMembersIds.length > 0
        ? teamMembersIds.filter((id) =>
            teamMembers.map((y) => y.id).includes(id)
          ).length > 0
        : true;

    const categories = filters.categories || [];
    const category = x.category || [];
    let matchesCategories =
      categories.length > 0
        ? categories.filter((id) => id == category).length > 0
        : true;

    const formats = filters.formats || [];
    const format = x.format || [];
    let matchesFormats =
      formats.length > 0
        ? formats.filter((id) => id == format).length > 0
        : true;

    return {
      ...x,
      matchesFilter:
        !!matchesSearch &&
        !!matchesTeamMembers &&
        !!matchesCategories &&
        !!matchesFormats,
    };
  });
}
