import React from "react";
import { Header, Button } from "semantic-ui-react";
import { useApiAction, ErrorMessage } from "@redriver/cinnamon";
import { ResourceIcon } from "components/icons";
import { ResourceLink } from "components/navigation";
import { ResourceType } from "constants/enums";
import {
  ResourceFileType,
  ResourceUrlType,
  getResourceFileType,
  getResourceUrlType,
} from "modules/helpers";
import { getResource } from "./actions";

const resourceActionLabel = (resource) => {
  if (resource.type === ResourceType.File) {
    const fileType = getResourceFileType(resource.fileName);
    if (fileType === ResourceFileType.PDF) {
      return "View PDF";
    } else if (fileType === ResourceFileType.Image) {
      return "View Image";
    }
    return "Download File";
  } else if (resource.type === ResourceType.Url) {
    const urlType = getResourceUrlType(resource.url);
    if (urlType === ResourceUrlType.Video) {
      return "Play Video";
    }
    return "Follow Link";
  }
  return "View Resource";
};

const ViewPublicResource = ({ token }) => {
  const [{ busy, slow, error, response, success }] = useApiAction(getResource, {
    actionParams: token,
    lazy: false,
  });

  if (success && response) {
    return (
      <div className="public-resource">
        <Header as="h4">{response.name}</Header>
        <ResourceLink resource={response} token={token}>
          {({ busy, error }) => (
            <React.Fragment>
              <Button
                primary
                size="large"
                fluid
                icon={<ResourceIcon resource={response} />}
                labelPosition="right"
                content={resourceActionLabel(response)}
                loading={busy}
                disabled={busy}
              />
              {error && <ErrorMessage error={error} omitCodes />}
            </React.Fragment>
          )}
        </ResourceLink>
      </div>
    );
  }

  if (error) {
    return <ErrorMessage error={error} header="Access denied" omitCodes />;
  }

  if (busy && slow) {
    return <p>Retrieving requested resource...</p>;
  }

  return null;
};

export default ViewPublicResource;
