import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "USER_LAST_ACCESSED";

const ActionTypes = {
  UpdateUserLastAccessed: `${NAMESPACE}/UPDATE_USER_LAST_ACCESSED`,
};

export const updateUserLastAccessed = () =>
  apiPut(ActionTypes.UpdateUserLastAccessed, `users/last-accessed`);
