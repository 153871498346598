import React from "react";
import classNames from "classnames";
import { Form } from "@redriver/cinnamon";

const RemovableArrayRow = ({ columns = 1, children, className, ...props }) => (
  <div
    className={classNames(
      "removable-array-row",
      {
        "one-col": columns === 1,
        "two-col": columns === 2,
        "three-col": columns === 3,
      },
      className
    )}
    {...props}
  >
    {children}
    <Form.ArrayRemover as="a" style={{ padding: "0.67857143em 0" }}>
      Remove
    </Form.ArrayRemover>
  </div>
);

export default RemovableArrayRow;
