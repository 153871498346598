import { apiPost, apiPut } from "@redriver/cinnamon";
import { requestAuthentication } from "../AuthenticatorCheck";

const NAMESPACE = "SYSTEM/AUTHENTICATOR_SETUP";

const ActionTypes = {
  RequestRegistration: `${NAMESPACE}/REQUEST_REGISTRATION`,
  CompleteRegistration: `${NAMESPACE}/COMPLETE_REGISTRATION`,
};

export const requestRegistration = (token) =>
  apiPost(
    ActionTypes.RequestRegistration,
    `auth/mfa/totp/register`,
    { token },
    { auth: false, preventErrorNotification: true }
  );

export const completeRegistration = ({ totp }, { token, recovery }) => async (
  dispatch
) => {
  const regAction = apiPut(
    ActionTypes.CompleteRegistration,
    `auth/mfa/totp/register`,
    { token, totp, recovery },
    { auth: false, preventErrorNotification: true }
  );

  const result = await dispatch(regAction);
  if (!result.success) return result;

  const authResult = await dispatch(requestAuthentication({ totp }, token));
  if (!authResult.success) return authResult;

  return result;
};
