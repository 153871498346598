import React from "react";
import HtmlToolbar from "./HtmlToolbar";

const DefaultToolbar = ({ id }) => (
  <HtmlToolbar id={id}>
    <HtmlToolbar.Group>
      <HtmlToolbar.HeadingIcon />
    </HtmlToolbar.Group>
    <HtmlToolbar.Group>
      <HtmlToolbar.BoldIcon />
      <HtmlToolbar.ItalicIcon />
      <HtmlToolbar.UnderlineIcon />
      <HtmlToolbar.StrikethroughIcon />
    </HtmlToolbar.Group>
    <HtmlToolbar.Group>
      <HtmlToolbar.OrderedListIcon />
      <HtmlToolbar.BulletListIcon />
    </HtmlToolbar.Group>
    <HtmlToolbar.Group>
      <HtmlToolbar.LinkIcon />
    </HtmlToolbar.Group>
    <HtmlToolbar.Group>
      <HtmlToolbar.TextColourIcon />
      <HtmlToolbar.BackgroundColourIcon />
    </HtmlToolbar.Group>
    <HtmlToolbar.Group>
      <HtmlToolbar.AlignLeftIcon />
      <HtmlToolbar.AlignMiddleIcon />
      <HtmlToolbar.AlignRightIcon />
    </HtmlToolbar.Group>
    <HtmlToolbar.Group>
      <HtmlToolbar.ClearFormattingIcon />
    </HtmlToolbar.Group>
    <HtmlToolbar.Group>
      <HtmlToolbar.UndoIcon />
      <HtmlToolbar.RedoIcon />
    </HtmlToolbar.Group>
  </HtmlToolbar>
);

export default DefaultToolbar;
