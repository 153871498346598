import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Feed } from "semantic-ui-react";
import { UserAlertType } from "constants/enums";
import {
  AccountRoutes,
  AdminRoutes,
  OrganisationRoutes,
} from "constants/routes";
import AlertIcon from "./AlertIcon";
import { DateTime, Interval } from "luxon";

const getAlertTitle = (alert) => {
  if (!alert) return undefined;
  switch (alert?.type) {
    case UserAlertType.PrivacySettingsConfirmation:
      return "Please confirm your privacy settings";
    case UserAlertType.NewOrganisation:
    case UserAlertType.NewPendingUser:
      return alert.message;
    default:
      return undefined;
  }
};

const getAlertLink = (alert) => {
  if (!alert) return undefined;
  switch (alert.type) {
    case UserAlertType.PrivacySettingsConfirmation:
      return AccountRoutes.Privacy;
    case UserAlertType.NewOrganisation:
      return OrganisationRoutes.Organisation(alert.entityId);
    case UserAlertType.NewPendingUser:
      return AdminRoutes.Users;
    default:
      return undefined;
  }
};

const AlertListItem = ({ alert, onClick }) => {
  const title = getAlertTitle(alert);
  if (!title) return undefined;

  const link = getAlertLink(alert);
  const linkProps = link
    ? {
        as: Link,
        to: link,
      }
    : {};

  const created = DateTime.fromISO(alert.createdUtc, { zone: "utc" });
  const minsAgo = Interval.fromDateTimes(created, DateTime.now()).length(
    "minutes"
  );
  const relativeDate = minsAgo < 1 ? "Just now" : created.toRelative();

  return (
    <Feed.Event
      className={classNames("alert-item", {
        outstanding: alert.requiresReading || alert.requiresAction,
      })}
      {...linkProps}
      onClick={onClick}
    >
      <Feed.Label>
        <AlertIcon alertType={alert.type} />
      </Feed.Label>
      <Feed.Content>
        <Feed.Summary>{title}</Feed.Summary>
        <Feed.Date>{relativeDate}</Feed.Date>
      </Feed.Content>
    </Feed.Event>
  );
};

export default AlertListItem;
