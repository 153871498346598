import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { lookupRequest, getLookupData } from "@redriver/cinnamon";
import { EventRoutes } from "constants/routes";
import { useRouteMatch } from "react-router";
import { participantEventDetailsLookup } from "modules/lookups";
import ParticipantEventMenuItems from "components/menus/ParticipantEventMenuItems";
import {
  canParticipantViewProgramme,
  canParticipantViewResources,
} from "modules/helpers";

const ParticipantEventSubMenu = () => {
  const eventsMatch = useRouteMatch(EventRoutes.Event());
  const eventId = eventsMatch?.params?.eventId;

  const eventData = useSelector((state) =>
    getLookupData(state, participantEventDetailsLookup, eventId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (eventId)
      dispatch(lookupRequest(participantEventDetailsLookup, eventId));
  }, []);

  if (eventData.loading || !eventData.response) return null;

  const {
    status,
    showResourcesUntilUtc,
    participationStatus,
  } = eventData.response;

  const showResources = canParticipantViewResources(
    status,
    showResourcesUntilUtc
  );
  const showProgramme = canParticipantViewProgramme(
    showResources,
    participationStatus
  );

  return (
    <Menu.Menu className="submenu">
      <ParticipantEventMenuItems
        eventId={eventId}
        showProgramme={showProgramme}
        showResources={showResources}
      />
    </Menu.Menu>
  );
};

export default ParticipantEventSubMenu;
