import { apiPost, authenticate } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/LOGIN";
export const REQUEST_LOGIN = `${NAMESPACE}/REQUEST`;
export const REFRESH_JWT = `${NAMESPACE}/REFRESH_JWT`;

export const requestLogin = ({ email, password }) => async (dispatch) => {
  // make the login api call
  const loginAction = apiPost(
    REQUEST_LOGIN,
    "auth/token/credentials",
    { key: email, secret: password },
    { auth: false, preventErrorNotification: true }
  );

  const result = await dispatch(loginAction);

  const { success, response } = result;

  if (success) {
    const { tokens, secondFactor } = response;
    if (tokens && (secondFactor?.availableTypes ?? []).length === 0) {
      // authenticate the user session
      const { access, refresh } = tokens;
      dispatch(authenticate(access, refresh));
    }
  }

  return result;
};

export const onRefreshJwt = (token) =>
  apiPost(
    REFRESH_JWT,
    "auth/token/refresh",
    { token },
    { auth: false, preventErrorNotification: true }
  );
