import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useLoggedIn } from "@redriver/cinnamon";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";
import { AuthenticatorCheck, AuthenticatorSetup } from "features/System";

const AuthenticationPage = () => {
  const loggedIn = useLoggedIn();
  const location = useLocation();

  const {
    registeredTypes,
    availableTypes,
    recoveryTypes,
    token,
    loginTokens,
    nextPath,
  } = location.state ?? {};

  const totpAvailable = availableTypes?.includes("TOTP");
  const totpRegistered = registeredTypes?.includes("TOTP");

  const [recovered, setRecovered] = useState(false);
  const [skipped, setSkipped] = useState(false);

  const setupRequired = (!totpRegistered || recovered) && !skipped;
  const setupRecovery = !recovered && (recoveryTypes ?? []).length === 0;

  if (!setupRequired && loggedIn) {
    return <Redirect to={nextPath ?? AppRoutes.Root} />;
  }

  if (!token || !totpAvailable) {
    return <Redirect to={SystemRoutes.Login} />;
  }

  return (
    <SystemPageContent title="Two-Factor Authentication" compact>
      {!setupRequired ? (
        <AuthenticatorCheck
          token={token}
          recoveryTypes={recoveryTypes}
          onRecovered={() => setRecovered(true)}
        />
      ) : (
        <AuthenticatorSetup
          token={token}
          loginTokens={loginTokens}
          setupRecovery={setupRecovery}
          onSkipped={() => setSkipped(true)}
        />
      )}
    </SystemPageContent>
  );
};

export default AuthenticationPage;
