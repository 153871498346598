import React, { useState } from "react";
import { Segment } from "semantic-ui-react";
import classNames from "classnames";
import { Chevron } from "components/icons";

const ExpandBox = ({
  as: As = Segment,
  header,
  children,
  className,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const css = classNames("expand-box", { expanded }, className);
  return (
    <As
      {...props}
      className={css}
      onClick={(e) => {
        setExpanded(!expanded);
        e.stopPropagation();
      }}
    >
      <header>
        <div className="header-content">
          {typeof header === "function" ? header({ expanded }) : header}
        </div>
        <Chevron className="expand" flipped={!expanded} />
      </header>
      <footer onClick={(e) => e.stopPropagation()}>
        {expanded ? children : null}
      </footer>
    </As>
  );
};

export default ExpandBox;
