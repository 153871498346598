import React from "react";
import classNames from "classnames";
import { Image, Header } from "semantic-ui-react";
import Logo from "assets/logos/logo-blue.svg";
import { PageContent } from "@redriver/cinnamon";

const SimplePageContent = ({ title, className, children }) => (
  <div className={classNames("simple-page-content", className)}>
    <div className="header">
      <Image src={Logo} alt="Wilton Park" className="logo" />
    </div>
    <PageContent>
      {typeof title === "string" ? <Header as="h2">{title}</Header> : title}
      {children}
    </PageContent>
  </div>
);

export default SimplePageContent;
