import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "PUBLIC/UPDATE_DATA_RETENTION";

const ActionTypes = {
  VerifyToken: `${NAMESPACE}/VERIFY_TOKEN`,
  UpdateResponse: `${NAMESPACE}/UPDATE_RESPONSE`,
};

export const verifyToken = (token) =>
  apiGet(
    ActionTypes.VerifyToken,
    `public/data-retention/${token}`,
    {},
    {
      auth: false,
      preventErrorNotification: true,
    }
  );

export const updateResponse = ({ token, response }) =>
  apiPut(
    ActionTypes.UpdateResponse,
    `public/data-retention/${token}`,
    { response },
    {
      auth: false,
      preventErrorNotification: true,
    }
  );
