import React from "react";
import { Form, useFormState } from "@redriver/cinnamon";
import { ImageCropper } from "components/forms";

const ResetImage = (props) => <a {...props}>Choose another file</a>;

// NOTE: this is a grouping of specific fields. in future might be worth turning into a full form field component.
const EventImageFormControl = () => {
  const { fields } = useFormState();

  return fields.image ? (
    <React.Fragment>
      <ImageCropper
        field="crop"
        file={fields.image}
        minHeight={50}
        minWidth={50}
        maxAspect={3 / 2}
        minAspect={2 / 3}
        fluid
        required
      />
      <Form.Clearer as={ResetImage} />
    </React.Fragment>
  ) : (
    <Form.FileUpload
      field="image"
      fluid
      required
      uploadText="Drag and drop an image file here"
      fileExtensionTypes={["png", "jpg", "jpeg"]}
      fileTypesError="Image must be an image file of type PNG or JPG"
    />
  );
};

export default EventImageFormControl;
