import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { ConceptRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";

const ConceptMenuItems = ({ conceptId, header }) => {
  const canViewNotes = usePermission(Targets.ConceptNote, Actions.View);
  const canViewBudget = usePermission(Targets.ConceptBudget, Actions.View);
  const canViewPartners = usePermission(Targets.ConceptPartner, Actions.View);
  const canViewParticipants = usePermission(
    Targets.ConceptParticipant,
    Actions.View
  );
  const canViewContactSearch = usePermission(
    Targets.ConceptContactSearch,
    Actions.View
  );

  return (
    <React.Fragment>
      {header && <NavMenu.Header>{header}</NavMenu.Header>}
      <NavMenu.Item link={ConceptRoutes.Concept(conceptId)} exact>
        <span>Overview</span>
      </NavMenu.Item>
      {canViewParticipants && (
        <NavMenu.Item link={ConceptRoutes.Participants(conceptId)}>
          <span>Participants</span>
        </NavMenu.Item>
      )}
      {canViewNotes && (
        <NavMenu.Item link={ConceptRoutes.Notes(conceptId)}>
          <span>Notes</span>
        </NavMenu.Item>
      )}
      {canViewBudget && (
        <NavMenu.Item link={ConceptRoutes.Budget(conceptId)}>
          <span>Budget</span>
        </NavMenu.Item>
      )}
      {canViewPartners && (
        <NavMenu.Item link={ConceptRoutes.Partners(conceptId)}>
          <span>Partners & Funding</span>
        </NavMenu.Item>
      )}
      {canViewContactSearch && (
        <NavMenu.Item link={ConceptRoutes.ContactSearch(conceptId)}>
          <span>Advanced Contact Search</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default ConceptMenuItems;
