import React from "react";
import { Lookup, Form } from "@redriver/cinnamon";
import { conceptStatusLookup } from "modules/lookups";

const ConceptStatusCheckboxGroup = (props) => (
  <Lookup
    lookup={conceptStatusLookup}
    render={({ response }) => (
      <Form.CheckboxGroup {...props} options={response || []} />
    )}
  />
);

export default ConceptStatusCheckboxGroup;
