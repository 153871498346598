import React from "react";

const ActiveIcon = ({
  color = "#0b0c10",
  disabled = false,
  title,
  ...props
}) => {
  const selectedColour = disabled ? "#c9c9d2" : color;
  return (
    <svg
      enableBackground="new 0 0 32 32"
      height="512"
      viewBox="0 0 32 32"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <g>
        <path
          d="m30.91998 4.28998h-3.19v-.56c-.13-4.91998-7.33997-4.91998-7.46997 0v.56h-8.52002c.26001-2.20001-1.46997-4.33997-3.72998-4.28998-2.29999-.01001-3.97003 2.04999-3.73999 4.28998h-3.19c-.59003 0-1.07001.47003-1.07001 1.06v25.58002c0 .58996.47998 1.07 1.07001 1.07h29.83997c.59003 0 1.07001-.48004 1.07001-1.07001v-25.58001c-.00001-.58997-.47999-1.06-1.07002-1.06zm-8.51996-.56c.02997-2.09998 3.15997-2.09998 3.19 0v3.27002c-.03003 2.09998-3.16003 2.09998-3.19 0 0-.81-.01001-2.45001 0-3.27002zm-15.99005 0c.03003-2.09998 3.16003-2.09998 3.19 0v3.27002c-.02997 2.09998-3.14996 2.10999-3.19 0zm15.06 15.78998-6.37994 6.37006c-.39001.39996-1.02002.41998-1.44.06l-3.05005-2.59003c-1.06-.90002.31-2.52002 1.38-1.62l2.30005 1.95001 5.67999-5.67999c.98999-.98004 2.48999.51996 1.50995 1.50995zm8.39002-7.62h-27.71998v-5.47998h2.13c-.37 5.71002 7.83997 5.71002 7.46997 0h8.52002v.58002c.27002 5.31 7.90997 4.72998 7.46997-.58002h2.13v5.47998z"
          stroke={selectedColour}
          fill={selectedColour}
        />
      </g>
    </svg>
  );
};

export default ActiveIcon;
