import React from "react";
import { Form, useFormField } from "@redriver/cinnamon";
import { eventSecurityLevelsLookup } from "modules/lookups";
import { Popup, Icon } from "semantic-ui-react";
import { EventSecurityLevel } from "constants/enums";
import { InfoIcon } from "components/icons";

const tooltipDescriptions = {
  [EventSecurityLevel.High]:
    "High security VIP visit. Police to be notified well in advance as will need to do intelligence gathering and full security sweep of premises.  On site security to patrol, full lock down.",
  [EventSecurityLevel.Sensitive]:
    "Sensitive topic or participants. Police notified and parts list to be sent. No show rounds.",
  [EventSecurityLevel.InviteOnly]: "Invitation only, not on website",
  [EventSecurityLevel.None]: "No additional security measures",
};

const EventSecurityLevelDropdown = (props) => {
  const [securityLevel, setSecurityLevel] = useFormField(props.field);

  return (
    <Form.Dropdown
      label={
        <label>
          Security Level{" "}
          <Popup
            inverted
            trigger={<InfoIcon />}
            content={
              tooltipDescriptions[securityLevel] ??
              "Select an option in order to view its description."
            }
            position="right center"
          />
        </label>
      }
      lookup={eventSecurityLevelsLookup}
      placeholder=" "
      {...props}
    />
  );
};

export default EventSecurityLevelDropdown;
