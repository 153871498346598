import { ActionTypes } from "./actions";
import {
  requestIsBusy,
  requestIsSuccess,
  requestResponse,
} from "@redriver/cinnamon";
import { produce } from "immer";
import { ConceptStatus } from "constants/enums";

const initialState = {
  stages: [],
  loadingAll: false,
  loadAllTriggeredAt: 0,
  filters: {
    statuses: [ConceptStatus.Live, ConceptStatus.OnHold],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SetFilters: {
      return produce(state, (draft) => {
        draft.filters = action.filters;
      });
    }
    case ActionTypes.SetLoadingAll: {
      return produce(state, (draft) => {
        draft.loadingAll = action.loading;
        if (action.loading) {
          draft.stages = []; // reset the stored data on stages when starting loading all
          draft.loadAllTriggeredAt = action.triggeredAt;
        }
      });
    }

    case ActionTypes.LoadMoreFromIndividualStage: {
      const stageType = action.customData.stageType;
      const loading = requestIsBusy(action);
      const response = requestResponse(action);
      const success = requestIsSuccess(action);

      return produce(state, (draft) => {
        const stage = draft.stages.find((x) => x.stageType == stageType);
        if (stage) {
          stage.loading = loading;
          if (success) {
            const existingIds = stage.results.map((x) => x.id);
            const { results, totalResults, confirmedFunding } = response;
            stage.pageNumber = stage.pageNumber + 1;
            stage.results = stage.results.concat(
              results.filter((x) => existingIds.every((y) => y != x.id))
            );
            stage.totalResults = totalResults;
            stage.confirmedFunding = confirmedFunding;
            stage.hasMore = stage.results.length < totalResults;
          }
        }
      });
    }

    case ActionTypes.UpdateAllStages: {
      const next = produce(state, (draft) => {
        action.stagesData?.forEach((x) => {
          updateSingleStage(draft, x);
        });

        // only stop loading the latest filter trigger is the same - helps stop the flickering if user spams filter changes by maintaining the loading all spinner
        if (action.triggeredAt == draft.loadAllTriggeredAt)
          draft.loadingAll = false;
      });

      return next;
    }

    default:
      return state;
  }
};

function updateSingleStage(draft, data) {
  const stageType = data.stage;

  let stage = draft.stages.find((x) => x.stageType == stageType);
  if (!stage) {
    stage = { stageType };
    draft.stages.push(stage);
  }

  stage.pageNumber = 1;
  stage.loading = false;
  if (data.success) {
    const { results, totalResults, confirmedFunding } = data.response;
    stage.results = results;
    stage.totalResults = totalResults;
    stage.confirmedFunding = confirmedFunding;
    stage.hasMore = results.length < totalResults;
    stage.error = null;
  } else {
    stage.results = [];
    stage.totalResults = 0;
    stage.confirmedFunding = 0;
    stage.hasMore = false;
    stage.error = data.error?.message ?? "Error loading data";
  }
}
