export const UserType = {
  Internal: "Internal",
  External: "External",
};

export const UserStatus = {
  Pending: "Pending",
  Unverified: "Unverified",
  Active: "Active",
  Inactive: "Inactive",
  Rejected: "Rejected",
};

export const PortalStatus = {
  NotInvited: "NotInvited",
  ...UserStatus,
};

export const UserAlertType = {
  Information: "Information",
  Message: "Message",
  PrivacySettingsConfirmation: "PrivacySettingsConfirmation",
  NewOrganisation: "NewOrganisation",
  NewPendingUser: "NewPendingUser",
};

export const EventStatus = {
  Draft: "Draft",
  Active: "Active",
  Cancelled: "Cancelled",
  Postponed: "Postponed",
  ClosedDown: "ClosedDown",
};

export const EventFormat = {
  InPerson: "InPerson",
  Virtual: "Virtual",
  Hybrid: "Hybrid",
};

export const EventLocationType = {
  Internal: "Internal",
  External: "External",
};

export const EventImageType = {
  Main: "Main",
  Gallery: "Gallery",
};

export const EventPartnerType = {
  Major: "Major",
  Minor: "Minor",
};

export const EventPartnerPrivacy = {
  Public: "Public",
  Private: "Private",
};

export const EventSecurityLevel = {
  High: "High",
  Sensitive: "Sensitive",
  InviteOnly: "InviteOnly",
  None: "None",
};

export const EventEmailSender = {
  User: "User",
  Event: "Event",
  WiltonPark: "WiltonPark",
};

export const NoteType = {
  General: "General",
  Funding: "Funding",
  EntityCreated: "EntityCreated",
  EntityUpdated: "EntityUpdated",
  EntityDeleted: "EntityDeleted",
  EntityMigrated: "EntityMigrated",
  Email: "Email",
};

export const ParticipationStatus = {
  Reserve: "Reserve",
  Invited: "Invited",
  Expected: "Expected",
  Confirmed: "Confirmed",
  Unable: "Unable",
  Interested: "Interested",
  Waiting: "Waiting",
};

export const ResourceType = {
  File: "File",
  Url: "Url",
};

export const ResourceParticipantAccess = {
  Invited: "Invited",
  Expected: "Expected",
  Confirmed: "Confirmed",
};

export const ProgrammeContentType = {
  ProgrammeItem: "ProgrammeItem",
  LiveSession: "LiveSession",
};

export const GlobalSearchAreaType = {
  Events: "Events",
  Concepts: "Concepts",
  Ideas: "Ideas",
  Series: "Series",
  Contacts: "Contacts",
  Organisations: "Organisations",
  Participants: "Participants",
};

export const EventCategory = {
  Policy: "Policy",
  Commercial: "Commercial",
  Other: "Other",
};

export const EventState = {
  Event: "Event",
  Idea: "Idea",
  Concept: "Concept",
};

export const SocialMediaLinkType = {
  LinkedIn: "LinkedIn",
  Twitter: "Twitter",
  Instagram: "Instagram",
  Facebook: "Facebook",
  //Custom: "Custom", NOTE: we are not handling custom types yet so leaving commented
};

export const ContactPointIcon = {
  Email: "Email",
  Phone: "Phone",
  Mobile: "Mobile",
  Skype: "Skype",
  Website: "Website",
};

export const ProfileImageType = {
  User: "User",
  Background: "Background",
};

export const IdeaStatus = {
  Live: "Live",
  OnHold: "OnHold",
  Dead: "Dead",
};

export const ConceptStatus = {
  Live: "Live",
  OnHold: "OnHold",
  Dead: "Dead",
};

export const OrganisationImageType = {
  Logo: "Logo",
};

export const GenderTypes = {
  Woman: "Woman",
  Man: "Man",
  PreferNotToSay: "PreferNotToSay",
  Other: "Other",
};

export const ParticipantSortOrder = {
  Default: "Default",
  FirstNameAZ: "FirstNameAZ",
  FirstNameZA: "FirstNameZA",
  LastNameAZ: "LastNameAZ",
  LastNameZA: "LastNameZA",
};

export const ParticipantColumnType = {
  Title: "Title",
  Email: "Email",
  ParticipantType: "ParticipantType",
  ParticipationStatus: "ParticipationStatus",
  PortalStatus: "PortalStatus",
  Organisation: "Organisation",
  Position: "Position",
  DisplayName: "DisplayName",
  Category: "Category",
  Comments: "Comments",
  MainPhone: "MainPhone",
  MainMobile: "MainMobile",
  PreviousEventsAttended: "PreviousEventsAttended",
  PreferredContactMethod: "PreferredContactMethod",
  LiaseWithAssistant: "LiaseWithAssistant",
  TownOrCity: "TownOrCity",
  Country: "Country",
  Citizenship: "Citizenship",
  Gender: "Gender",
  AttendanceCategory: "AttendanceCategory",
  UnableReason: "UnableReason",
  Representing: "Representing",
  GroupPhotoConsent: "GroupPhotoConsent",
  RegistrationStatus: "RegistrationStatus",
  RegistrationStarted: "RegistrationStarted",
  RegistrationCompleted: "RegistrationCompleted",
  RegistrationUpdated: "RegistrationUpdated",
  RegistrationUpdatedBy: "RegistrationUpdatedBy",
  TravelAndAccommodation: "TravelAndAccommodation",
};

export const SiteType = {
  Travel: "Travel",
  Accommodation: "Accommodation",
  Venue: "Venue",
};

export const enumOptionsArray = (enumObj, labelObj = {}) => {
  return Object.keys(enumObj).map((k) => ({
    value: k,
    text: labelObj[k] ?? enumObj[k],
  }));
};

export const FriendsOfWiltonPark = {
  FriendsOfWiltonPark: "FriendsOfWiltonPark",
  InvitedNotAccepted: "InvitedNotAccepted",
  NotInvited: "NotInvited",
};

export const FundingStatus = {
  Live: "Live",
  Cancelled: "Cancelled",
};

export const ParticipantFeeType = {
  Participation: "Participation",
  Additional: "Additional",
  Cancellation: "Cancellation",
};

export const ParticipantAddressType = {
  Invoice: "Invoice",
};

export const FundingAllocationType = {
  EventFunding: "EventFunding",
  SeriesFunding: "SeriesFunding",
};

export const PipelineStageTypes = {
  StageOne: "StageOne",
  StageTwo: "StageTwo",
  ReadyToConvert: "ReadyToConvert",
};

export const FundingAddressType = {
  Invoice: "Invoice",
};

export const FundingPaymentScheduleStatus = {
  Live: "Live",
  Cancelled: "Cancelled",
};

export const FundingType = {
  Organisation: "Organisation",
  Series: "Series",
  Event: "Event",
};

export const ContactResponseType = {
  HappyToBeContacted: "HappyToBeContacted",
  RemoveContactData: "RemoveContactData",
};

export const ContactStatus = {
  Active: "Active",
  Inactive: "Inactive",
};

export const OrganisationStatus = {
  Active: "Active",
  Inactive: "Inactive",
};

export const DashboardType = {
  Events: "Events",
  Participants: "Participants",
  Financial: "Financial",
  BusinessDevelopment: "BusinessDevelopment",
  ImpactsAndEvidence: "ImpactsAndEvidence",
};

export const EventObjectiveMet = {
  YesMet: "YesMet",
  PartMet: "PartMet",
  NoNotMet: "NoNotMet",
};

export const ContactSearchConditionMatch = {
  All: "All",
  Any: "Any",
  None: "None",
};

export const ContactSearchComparatorType = {
  Text: "Text",
  LocalDate: "LocalDate",
  InstantDate: "InstantDate",
  Boolean: "Boolean",
  Integer: "Integer",
  Float: "Float",
  Currency: "Currency",
  StaticLookup: "StaticLookup",
  DynamicLookup: "DynamicLookup",
};

export const ContactSearchComparatorOperator = {
  Equals: "Equals",
  NotEquals: "NotEquals",
  Contains: "Contains",
  NotContains: "NotContains",
  IsEmpty: "IsEmpty",
  LessThan: "LessThan",
  GreaterThan: "GreaterThan",
  Before: "Before",
  After: "After",
  Between: "Between",
};

export const ContactSearchComparatorLookup = {
  Organisation: "Organisation",
};

export const ContactSearchAccessibility = {
  Private: "Private",
  Public: "Public",
};

export const RegistrationItemTypes = {
  Blank: "Blank",
  TextInput: "TextInput",
  MultiLineTextInput: "MultiLineTextInput",
  YesNoInput: "YesNoInput",
  // item specific fields
  ContactYearOfBirth: "ContactYearOfBirth",
  ProfileBiography: "ProfileBiography",
  ContactGender: "ContactGender",
  ContactTitle: "ContactTitle",
  ContactFirstName: "ContactFirstName",
  ContactLastName: "ContactLastName",
  ContactGong: "ContactGong",
  ProfileCitizenship: "ProfileCitizenship",
  ProfileSecondaryCitizenship: "ProfileSecondaryCitizenship",
  ProfileDisplayName: "ProfileDisplayName",
  ProfileJobTitle: "ProfileJobTitle",
  ProfileOrganisation: "ProfileOrganisation",
  ProfileTown: "ProfileTown",
  ProfileCountry: "ProfileCountry",
  ProfilePhone: "ProfilePhone",
  ProfilePhonePublic: "ProfilePhonePublic",
  ProfileMobile: "ProfileMobile",
  ProfileMobilePublic: "ProfileMobilePublic",
  ProfileTwitter: "ProfileTwitter",
  ProfileTwitterPublic: "ProfileTwitterPublic",
  ProfileThemes: "ProfileThemes",
  ProfileSubThemes: "ProfileSubThemes",

  TravelBookingFlightAssistance: "TravelBookingFlightAssistance",
  TravelBookingLocalTravelAssistance: "TravelBookingLocalTravelAssistance",
  TravelBookingInfo: "TravelBookingInfo",
  AccommodationRequired: "AccommodationRequired",
  AttendanceDates: "AttendanceDates",
  SpecialRequirements: "SpecialRequirements",
  SpecialMealRequirements: "SpecialMealRequirements",
  PreferencesFutureDetails: "PreferencesFutureDetails",
  PreferencesGroupPhoto: "PreferencesGroupPhoto",

  LiaiseWithAssistant: "LiaiseWithAssistant",
  AssistantDetails: "AssistantDetails",
  ProfileEmailPublic: "ProfileEmailPublic",

  //other specific fields
  EventDetails: "EventDetails",
  Conclusion: "Conclusion",
  FriendsOfWiltonPark: "FriendsOfWiltonPark",
};

export const RegistrationItemWidths = {
  Full: "Full",
  Half: "Half",
  Thirds: "Thirds",
};
export const ParticipantAttendanceCategory = {
  InPerson: "InPerson",
  Virtual: "Virtual",
};

export const EventPartnerImageType = {
  MainLogo: "MainLogo",
  AdditionalLogo: "AdditionalLogo",
};

export const PurchaseOrderStatus = {
  Requested: "Requested",
  OnHold: "OnHold",
  Approved: "Approved",
  Cancelled: "Cancelled",
  Ordered: "Ordered",
  GoodsReceived: "GoodsReceived",
  InQuery: "InQuery",
};

export const PurchaseOrderPaymentStatus = {
  Invoiced: "Invoiced",
  PartInvoiced: "PartInvoiced",
  Paid: "Paid",
  PartPaid: "PartPaid",
  InQuery: "InQuery",
};

export const PurchaseOrderCostType = {
  Event: "Event",
  Capex: "Capex",
  Reimbursement: "Reimbursement",
  Expenses: "Expenses",
  Other: "Other",
};

export const PurchaseOrderRecurrence = {
  None: "None",
  Annual: "Annual",
  Quarterly: "Quarterly",
  Monthly: "Monthly",
};

export const PurchaseOrderFilter = {
  ShowMine: "ShowMine",
  ShowDepartment: "ShowDepartment",
  ShowAll: "ShowAll",
};

export const EventBudgetSection = {
  Details: "Details",
  ProductionPrice: "ProductionPrice",
  ResidentialPrice: "ResidentialPrice",
  TravelCosts: "TravelCosts",
  ExternalCosts: "ExternalCosts",
  AdditionalCosts: "AdditionalCosts",
};

export const RegistrationResultStatus = {
  NotStarted: "NotStarted",
  Incomplete: "Incomplete",
  ReachedEnd: "ReachedEnd",
  FullyComplete: "FullyComplete",
};

export const TimeRelativity = {
  Before: "Before",
  After: "After",
};

export const DashboardWidgetType = {
  EventLocation: "EventLocation",
  EventRegistrationStatus: "EventRegistrationStatus",
  EventParticipantStatus: "EventParticipantStatus",
  EventParticipantDemographics: "EventParticipantDemographics",
  EventParticipantOrganisations: "EventParticipantOrganisations",
  EventObjectives: "EventObjectives",
  EventFunding: "EventFunding",
  EventBudget: "EventBudget",
  EventBudgetVsFunding: "EventBudgetVsFunding",
};

export const PurchaseOrderSortOrder = {
  RequisitionDateAscending: "RequisitionDateAscending",
  RequisitionDateDescending: "RequisitionDateDescending",
};

export const SupplierInvoiceStatus = {
  Received: "Received",
  Approved: "Approved",
  OnHold: "OnHold",
  InQuery: "InQuery",
};

export const DataRetentionResponse = {
  Consent: "Consent",
  ConsentIncludingFriendsOfWiltonPark: "ConsentIncludingFriendsOfWiltonPark",
  ConsentExcludingFriendsOfWiltonPark: "ConsentExcludingFriendsOfWiltonPark",
  DoNotConsent: "DoNotConsent",
};
