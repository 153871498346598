import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { lookupRequest, getLookupData } from "@redriver/cinnamon";
import { SeriesRoutes } from "constants/routes";
import { useRouteMatch } from "react-router";
import { SeriesMenuItems } from "components/menus";
import { seriesDetailsLookup } from "modules/lookups";

const SeriesSubMenu = () => {
  const seriesMatch = useRouteMatch(SeriesRoutes.Series());
  const seriesId = seriesMatch?.params?.seriesId;

  const seriesData = useSelector((state) =>
    getLookupData(state, seriesDetailsLookup, seriesId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (seriesId) dispatch(lookupRequest(seriesDetailsLookup, seriesId));
  }, []);

  if (!seriesId || seriesData.loading || !seriesData.response) return null;

  return (
    <Menu.Menu className="submenu">
      <SeriesMenuItems seriesId={seriesId} header={seriesData.response.code} />
    </Menu.Menu>
  );
};

export default SeriesSubMenu;
