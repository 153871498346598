import React from "react";
import { Form } from "@redriver/cinnamon";
import { sectorsLookup } from "modules/lookups";

const SectorDropdown = (props) => {
  return (
    <Form.Dropdown
      label="Sector"
      lookup={sectorsLookup}
      placeholder="Select sector"
      {...props}
    />
  );
};

export default SectorDropdown;
