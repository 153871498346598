import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";

const duplicateEntities = (list) => {
  let entities = [];
  let size = list.length;
  if (size > 10) {
    entities = list.slice(0, 10).map((x) => x.value);
    entities.push("and " + (size - 10) + " more.");
  } else {
    entities = list.map((x) => x.value);
  }

  return entities.map((e, index) => {
    return (
      <strong key={index}>
        {e}
        <br />
      </strong>
    );
  });
};

const DuplicateConfirmationModal = ({
  duplicates,
  modalHeader,
  onSubmit,
  onCancel,
}) => {
  return (
    <ModalBuilder
      onSubmitted={onSubmit}
      onCancelled={onCancel}
      renderModal={(modalProps, data, state, events) => (
        <Modal.Confirmation
          {...modalProps}
          header={`Duplicate ${modalHeader}(s) Found`}
          submitLabel="Save New Record"
          cancelLabel="Cancel"
          onSubmit={events.onSubmit}
        >
          This record may be a duplicate, please check before saving the new
          record.
          <br />
          Possible duplicate(s) found:
          <p />
          {duplicateEntities(duplicates)}
        </Modal.Confirmation>
      )}
    />
  );
};

export default DuplicateConfirmationModal;
