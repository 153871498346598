import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/ACCOUNT";

const ActionTypes = {
  GetUserDetails: `${NAMESPACE}/GET_USER_DETAILS`,
  UpdateUserDetails: `${NAMESPACE}/UPDATE_USER_DETAILS`,
};

export const getUserAccountDetails = () =>
  apiGet(ActionTypes.GetUserDetails, `account`);

export const updateUserAccountDetails = (data) =>
  apiPut(ActionTypes.UpdateUserDetails, `account`, data);
