const seenRegistrationPromptKey = "WP_SeenRegistrationPrompt";
export const setSeenRegistrationPrompt = (value) => {
  if (value === null || value === undefined) {
    localStorage.removeItem(seenRegistrationPromptKey);
  } else {
    localStorage.setItem(seenRegistrationPromptKey, value);
  }
};
export const getSeenRegistrationPrompt = () =>
  localStorage.getItem(seenRegistrationPromptKey) === "true";
