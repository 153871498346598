import React from "react";
import { Message } from "semantic-ui-react";
import classNames from "classnames";

const MessagePanel = ({ header, children, className, colour, ...props }) => {
  return (
    <Message
      className={classNames(
        "message-panel",
        { orange: colour === "orange" },
        { blue: colour === "blue" },
        className
      )}
      {...props}
    >
      <Message.Header>{header}</Message.Header>
      <div className="message-content">{children}</div>
    </Message>
  );
};

export default MessagePanel;
