import React, { useState, useMemo } from "react";
import { Icon, Label } from "semantic-ui-react";
import {
  NavMenu,
  ActionMenu,
  useSignalR,
  useSignalRCallback,
} from "@redriver/cinnamon";
import { AlertList } from "components/alerts";
import { ScrollableContent } from "components/containers";
import { DateTime } from "luxon";

const AlertsNavItem = () => {
  const [alerts, setAlerts] = useState([]);
  const [lastViewed, setLastViewed] = useState(null);

  const alertsHub = useSignalR("signalr/alerts", {
    onConnected: (hub) => {
      const getAlerts = async () => {
        const latestAlerts = await hub.invoke("GetUserAlerts");
        setAlerts(latestAlerts);
      };
      getAlerts();
    },
  });

  useSignalRCallback(
    alertsHub,
    "OnUserAlertUpdated",
    (updatedAlert) => {
      setAlerts((alerts) =>
        alerts
          .map((a) => {
            if (a.id !== updatedAlert.id) return a;
            return updatedAlert;
          })
          .filter((a) => !a.archivedUtc)
      );
    },
    []
  );

  useSignalRCallback(
    alertsHub,
    "OnUserAlertCreated",
    (newAlert) => {
      setAlerts((alerts) => [newAlert, ...alerts]);
    },
    []
  );

  const activeAlerts = useMemo(
    () =>
      alerts.filter(
        (a) =>
          !a.archivedUtc ||
          DateTime.fromISO(a.archivedUtc, { zone: "utc" }) > DateTime.now()
      ),
    [alerts, lastViewed]
  );

  const outstandingTotal = alerts.filter(
    (a) => a.requiresReading || a.requiresAction
  ).length;

  return (
    <ActionMenu
      compact
      className="alerts-menu"
      trigger={
        <NavMenu.Item
          className="alerts-nav-item"
          onMouseEnter={() => setLastViewed(Date.now())}
          onMouseDown={() => setLastViewed(Date.now())}
        >
          <Icon name="bell outline" />
          {outstandingTotal > 0 && (
            <Label color="red" circular size="tiny">
              {outstandingTotal}
            </Label>
          )}
        </NavMenu.Item>
      }
    >
      <ActionMenu.Header>Notifications</ActionMenu.Header>
      <ScrollableContent>
        <AlertList>
          {activeAlerts.map((a) => (
            <AlertList.Item
              key={`${a.id}_${lastViewed}`}
              alert={a}
              onClick={() => {
                if (alertsHub?.connectionId) {
                  alertsHub.invoke("ReadUserAlert", a.id);
                }
              }}
            />
          ))}
          {activeAlerts.length === 0 && (
            <AlertList.Content>No new notifications</AlertList.Content>
          )}
        </AlertList>
      </ScrollableContent>
    </ActionMenu>
  );
};

export default AlertsNavItem;
