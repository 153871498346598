import React, { useState } from "react";
import { Header } from "semantic-ui-react";
import {
  ErrorMessage,
  TriggerBuilder,
  useApiAction,
  useLoggedIn,
} from "@redriver/cinnamon";
import { verifyToken, updateResponse } from "./actions";
import { DataRetentionResponse } from "constants/enums";
import { WiltonPark } from "constants/contacts";

const UpdateDataRetention = ({ token }) => {
  const [{ busy, slow, error, response, success }] = useApiAction(verifyToken, {
    actionParams: token,
    lazy: false,
  });

  const loggedIn = useLoggedIn();
  const [consentAgreed, setConsentAgreed] = useState(null);
  const [fowpAgreed, setFowpAgreed] = useState(null);

  const resetConsentOptions = () => {
    setConsentAgreed(null);
    setFowpAgreed(null);
  };

  if (success && response) {
    return (
      <div className="data-retention">
        <Header as="h2">
          {consentAgreed === false
            ? "Sorry you're leaving"
            : "Staying in touch"}
        </Header>
        {consentAgreed === false ? (
          <div className="consent-confirmation">
            <p>
              Thank you for confirming that you no longer wish to receive
              communications from Wilton Park.
            </p>
            <p>
              Your data will be removed from our database within the next
              calendar month. Or you can choose to{" "}
              <a onClick={resetConsentOptions}>amend your consent options</a>.
            </p>
          </div>
        ) : fowpAgreed !== null ? (
          <div className="consent-confirmation">
            <p>
              Thank you for confirming your choices. We look forward to staying
              in touch.
            </p>
            <p>
              If you would like any further information on Friends of Wilton
              Park and the benefits of joining, contact{" "}
              <a href={`mailto:${WiltonPark.HeadOfPartnerships}`}>
                {WiltonPark.HeadOfPartnerships}
              </a>
              . Or you can choose to{" "}
              <a onClick={resetConsentOptions}>amend your consent options</a>.
            </p>
          </div>
        ) : consentAgreed ? (
          <div className="fowp-options">
            <p>
              Thank you for confirming that you are happy for us to retain your
              data. We look forward to staying in touch.
            </p>
            <Header as="h3">Friends of Wilton Park</Header>
            <p>
              We would also like to invite you to join our Friends of Wilton
              Park community. This group offers you the chance to network with
              other Wilton Park participants, past and future. We share
              information and updates with the group and host exclusive events
              for members throughout the year.
            </p>
            <p>Please click one of the options below:</p>
            <TriggerBuilder
              submitAction={updateResponse}
              submitParams={{
                token,
                response:
                  DataRetentionResponse.ConsentIncludingFriendsOfWiltonPark,
              }}
              onSubmitted={() => setFowpAgreed(true)}
              renderTrigger={(fireTrigger) => (
                <div className="option">
                  <a onClick={fireTrigger}>
                    YES, I would like to join the Friends of Wilton Park network
                  </a>
                </div>
              )}
            />
            <TriggerBuilder
              submitAction={updateResponse}
              submitParams={{
                token,
                response:
                  DataRetentionResponse.ConsentExcludingFriendsOfWiltonPark,
              }}
              onSubmitted={() => setFowpAgreed(false)}
              renderTrigger={(fireTrigger) => (
                <div className="option">
                  <a onClick={fireTrigger}>
                    NO, I would not like to join the Friends of Wilton Park
                    network
                  </a>
                </div>
              )}
            />
            <p>
              If you would like any further information on Friends of Wilton
              Park and benefits of joining, contact{" "}
              <a href={`mailto:${WiltonPark.HeadOfPartnerships}`}>
                {WiltonPark.HeadOfPartnerships}
              </a>
              .
            </p>
            <Header as="h3">Partnerships with Wilton Park</Header>
            <p>
              If there is a particular global challenge that you feel we could
              be addressing together, please share your thoughts by emailing{" "}
              <a href={`mailto:${WiltonPark.HeadOfPartnerships}`}>
                {WiltonPark.HeadOfPartnerships}
              </a>
              .
            </p>
          </div>
        ) : (
          <div className="consent-options">
            <p>Please choose an option below:</p>
            <TriggerBuilder
              submitAction={updateResponse}
              submitParams={{ token, response: DataRetentionResponse.Consent }}
              onSubmitted={() => setConsentAgreed(true)}
              renderTrigger={(fireTrigger) => (
                <div className="option">
                  <a onClick={fireTrigger}>
                    YES, I consent to Wilton Park holding my data to send me
                    invitations, details about future events and other
                    information of interest.
                  </a>
                </div>
              )}
            />
            <TriggerBuilder
              submitAction={updateResponse}
              submitParams={{
                token,
                response: DataRetentionResponse.DoNotConsent,
              }}
              onSubmitted={() => setConsentAgreed(false)}
              renderTrigger={(fireTrigger) => (
                <div className="option">
                  <a onClick={fireTrigger}>
                    NO, I do not consent to Wilton Park holding my data to send
                    me invitations, details about future events and other
                    information of interest.
                  </a>
                </div>
              )}
            />
          </div>
        )}
      </div>
    );
  }

  if (error) {
    return <ErrorMessage error={error} header="Access denied" omitCodes />;
  }

  if (busy && slow) {
    return <p>Loading consent options...</p>;
  }

  return null;
};

export default UpdateDataRetention;
