import React from "react";
import { useParams } from "react-router-dom";
import { SimplePageContent } from "components/pages";
import { UpdateDataRetention } from "features/Public";

const DataRetentionPage = () => {
  const { token } = useParams();
  return (
    <SimplePageContent>
      <UpdateDataRetention token={token} />
    </SimplePageContent>
  );
};

export default DataRetentionPage;
