import React from "react";
import { Form } from "@redriver/cinnamon";
import { programmeThemeLookup } from "modules/lookups";
import { Icon, Popup } from "semantic-ui-react";
import { InfoIcon } from "components/icons";

const ProgrammeThemeDropdown = ({
  label = "Themes",
  showSelectionInfo,
  ...props
}) => (
  <Form.Dropdown
    label={
      label ? (
        <label>
          {label}
          {showSelectionInfo && (
            <Popup
              inverted
              content="You can select one or more themes of interest by clicking to add to the list."
              trigger={<InfoIcon />}
            />
          )}
        </label>
      ) : undefined
    }
    placeholder=" "
    lookup={programmeThemeLookup}
    {...props}
  />
);

export default ProgrammeThemeDropdown;
