import React from "react";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from "react-quill";
import DOMPurify from "dompurify";
import { Field, FormUtils, connectForm } from "@redriver/cinnamon";
import DefaultToolbar from "./DefaultToolbar";
import InsertLink from "./InsertLink";
import { registerCustomFormats } from "./formats";

registerCustomFormats();

let nextToolbarId = 1;

class HtmlEditor extends React.Component {
  _quill = undefined;
  _uniqueToolbarName = undefined;
  _modules = undefined;

  constructor(props) {
    super(props);

    const toolbarId = nextToolbarId;
    nextToolbarId = toolbarId + 1;
    this._uniqueToolbarName = `html-toolbar-${toolbarId}`;

    this._modules = this.mergeDefaultModules(props.modules);

    this.state = {
      insertLinkOpen: false,
      blockInitialChange: !!props.value,
      currentValue: props.value,
    };
  }

  componentDidMount() {
    if (this._quill) this._quill.getEditor().history.clear();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.currentValue && props.value) {
      return {
        blockInitialChange: !!props.value,
        currentValue: props.value,
      };
    }
    return null;
  }

  onChange = (html, delta, source, editor) => {
    if (source === "api" && this.state.blockInitialChange) {
      // if initial value passed to quill editor an undesirable initial 'api' change will be fired
      this.setState({ blockInitialChange: false });
      if (this._quill) this._quill.getEditor().history.clear();
      return;
    }

    this.props.onChange({
      html: html,
      text: this._quill
        ? this._quill.getEditor().getText()
        : DOMPurify.sanitize(html),
    });
  };

  mergeDefaultModules = (modules = {}) => ({
    ...modules,
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
      ...modules.history,
    },
    toolbar: {
      container: modules.toolbar?.container ?? `#${this._uniqueToolbarName}`,
      handlers: {
        link: () => {
          this.setState({ insertLinkOpen: true });
        },
        undo: () => {
          if (this._quill) this._quill.getEditor().history.undo();
        },
        redo: () => {
          if (this._quill) this._quill.getEditor().history.redo();
        },
        ...modules.toolbar?.handlers,
      },
    },
    clipboard: {
      matchVisual: false,
      ...modules.clipboard,
    },
  });

  render() {
    const {
      value,
      onChange,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      readOnly,
      label,
      actions,
      width,
      fluid,
      required,
      modules,
      formats,
      placeholder,
      toolbar,
      forwardedRef,
      className,
    } = this.props;
    return (
      <Field
        required={required}
        disabled={disabled}
        renderReadOnly={readOnly && this.renderReadOnly}
        width={width}
        fluid={fluid}
        label={label}
        actions={actions}
        errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
        className="html-editor"
      >
        {toolbar ?? <DefaultToolbar id={this._uniqueToolbarName} />}
        <ReactQuill
          className={className}
          ref={(x) => {
            this._quill = x;
            if (forwardedRef) {
              if (typeof forwardedRef === "function") {
                forwardedRef(x);
              } else {
                forwardedRef.current = x;
              }
            }
          }}
          value={value?.html ?? value ?? ""}
          theme="snow"
          placeholder={placeholder}
          modules={this._modules}
          formats={formats}
          onChange={this.onChange}
        />
        <InsertLink
          quill={this._quill}
          open={this.state.insertLinkOpen}
          onClose={() => this.setState({ insertLinkOpen: false })}
        />
      </Field>
    );
  }
}

const requiredHtmlField = (getComparisonValue = (v) => v) => ({
  propTypes: {
    required: PropTypes.bool.isRequired,
    requiredError: PropTypes.string,
  },
  defaultProps: {
    required: false,
  },
  shouldValidate: (props) => {
    return props.required === true;
  },
  getErrors: (field, value, props) => {
    const comparison = getComparisonValue(value);
    const text = comparison?.text ?? comparison;

    if (
      text === null ||
      text === undefined ||
      text === "" ||
      text === false ||
      (Array.isArray(text) && text.length === 0) ||
      (typeof text === "string" && !/\S/.test(text)) // String does not contain any characters that are not whitespace
    ) {
      return [props.requiredError || `${field} is required`];
    }
    return [];
  },
});

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string"
      ? props.label
      : FormUtils.prettifyField(props.field),
  validators: [requiredHtmlField()],
})(HtmlEditor);
