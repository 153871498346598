import { ActionTypes } from "./actions";
import { produce } from "immer";
import { uniq, groupBy } from "lodash";
import { getStepsHelper, getTemplateQuestionsHelper } from "./selectors";
import { optionallyFilterSpecialMealRequirements } from "./utils";

const initialState = {
  template: null,
  loading: false,
  stepsFormState: {}, // dictionary of form state for each step
  stepsValidationState: {}, // dictionary of form validation state for each step
  showValidationErrors: false,
  unlockedToIndex: null,
  saving: false,
  isInEditMode: false,
  hasParticipantsRegistered: false,
  fullyComplete: false,
  currentStepFormIsDirty: false,
  hasPublishedRegistration: false,
  isVirtualAttendance: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SetAnswers: {
      return produce(state, (draft) => {
        const sectionAnswers = groupBy(action.answers, (x) => x.sectionId);
        Object.keys(sectionAnswers).forEach((k) => {
          let formData = sectionAnswers[k].reduce(
            (acc, item) => ({
              ...acc,
              [item.registrationItemId]: item.value,
            }),
            {}
          );

          // merge in extra fields (such as name field for a dropdown)
          if (action.extras) {
            formData = {
              ...formData,
              ...action.extras,
            };
          }

          draft.stepsFormState[k] = formData || {};
          if (!action.preserveProgressIndex) {
            draft.unlockedToIndex = action.sectionProgressIndex || null;
          }
        });

        draft.isVirtualAttendance = !!action.isVirtualAttendance;
      });
    }

    case ActionTypes.SetRegistrationTemplate: {
      return produce(state, (draft) => {
        draft.template = action.template;
        // create blank form states for each section
        draft.stepsFormState = action.template.sections?.reduce(
          (acc, section) => ({
            ...acc,
            [section.id]: draft.stepsFormState[section.id] || {},
          }),
          {}
        );

        if (action.unlockAll) {
          draft.unlockedToIndex = action.template.sections.length;
        }
      });
    }

    case ActionTypes.UpdateStepFormState: {
      return {
        ...state,
        currentStepFormIsDirty: true,
        stepsFormState: {
          ...state.stepsFormState,
          [action.stepKey]: action.applyChanges(
            state.stepsFormState[action.stepKey]
          ),
        },
      };
    }

    case ActionTypes.ClearRegistration:
      return {
        ...state,
        stepsFormState: {},
      };

    case ActionTypes.SetUnlockedToIndex:
      return {
        ...state,
        unlockedToIndex: action.targetIndex,
      };

    case ActionTypes.SetSaving: {
      return {
        ...state,
        saving: action.saving,
      };
    }

    case ActionTypes.SetEditMode: {
      return {
        ...state,
        isInEditMode: !!action.isInEditMode,
        hasPublishedRegistration: !!action.hasPublishedRegistration,
      };
    }

    case ActionTypes.SetStepValidationState: {
      return {
        ...state,
        stepsValidationState: {
          ...state.stepsValidationState,
          [action.stepKey]: action.isValid,
        },
      };
    }

    case ActionTypes.SetShowValidationErrors: {
      return {
        ...state,
        showValidationErrors: !!action.showErrors,
      };
    }

    case ActionTypes.CalcFullyCompleted: {
      let isFullyComplete = false;
      if (state.template) {
        const allQuestions = getTemplateQuestionsHelper(state.template);
        const allStepsFormData = state.stepsFormState;
        const unlockedToIndex = state.unlockedToIndex;
        const steps = getStepsHelper(state.template);

        const itemsMissingPriorEventAnswer = Array.isArray(allQuestions)
          ? allQuestions.filter(
              (x) =>
                !!x.mandatoryPriorEvent &&
                allStepsFormData[x.sectionId] &&
                !allStepsFormData[x.sectionId][x.id] &&
                optionallyFilterSpecialMealRequirements(
                  x,
                  state.isVirtualAttendance
                )
            )
          : [];
        const unlockedToEnd = unlockedToIndex >= steps.length - 1;
        isFullyComplete =
          !!allQuestions &&
          itemsMissingPriorEventAnswer.length == 0 &&
          unlockedToEnd;
      }

      return {
        ...state,
        fullyComplete: isFullyComplete,
      };
    }

    case ActionTypes.ResetFormDirtyState: {
      return {
        ...state,
        currentStepFormIsDirty: false,
      };
    }

    default:
      return state;
  }
};
