import React from "react";
import PropTypes from "prop-types";
import { ClaimCheck } from "@redriver/cinnamon";
import { claimExists } from "./utils";

const PermissionCheck = ({ target, action, any, renderFailure, children }) => (
  <ClaimCheck
    claim="permissions"
    value={(claimData) => claimExists(claimData, target, action, any)}
    renderFailure={renderFailure}
  >
    {children}
  </ClaimCheck>
);

PermissionCheck.propTypes = {
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  any: PropTypes.bool,
  renderFailure: PropTypes.func,
};

PermissionCheck.defaultProps = {
  any: false,
};

export default PermissionCheck;
