import React from "react";
import { Lookup, Form } from "@redriver/cinnamon";
import { timeRelativityLookup } from "modules/lookups";

const RelativeDatePicker = ({ dateFieldProps, relativityFieldProps }) => {
  return (
    <Form.Group className="relative-date-picker" widths="equal">
      <Form.Date {...dateFieldProps} />
      <Lookup
        lookup={timeRelativityLookup}
        render={({ loading, response }) =>
          !loading && response ? (
            <Form.RadioGroup options={response} {...relativityFieldProps} />
          ) : (
            <React.Fragment></React.Fragment>
          )
        }
      />
    </Form.Group>
  );
};

export default RelativeDatePicker;
