import React from "react";
import classNames from "classnames";
import { Button } from "semantic-ui-react";
import { Modal } from "@redriver/cinnamon";

const FormModal = ({ submitLabel, header, onCancel, className, ...props }) => (
  <Modal
    {...props}
    submitLabel={submitLabel ?? "Save"}
    header={
      <React.Fragment>
        {header}
        <Button
          basic
          icon="close"
          className="close-modal"
          onClick={(e) => {
            e.stopPropagation();
            if (onCancel) onCancel(e);
          }}
        />
      </React.Fragment>
    }
    className={classNames("form-modal", className)}
    onCancel={onCancel}
  />
);

export default FormModal;
