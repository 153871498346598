import React from "react";
import { Link, useParams } from "react-router-dom";
import { useLoggedIn } from "@redriver/cinnamon";
import { SystemPageContent } from "components/pages";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { ViewPublicResource } from "features/Public";

const PublicResourcePage = () => {
  const { token } = useParams();
  const loggedIn = useLoggedIn();
  return (
    <SystemPageContent>
      <ViewPublicResource token={token} />
      <p style={{ marginTop: "2em" }}>
        Return to{" "}
        <Link to={loggedIn ? AppRoutes.Root : SystemRoutes.Login}>
          {loggedIn ? "Portal" : "Log In"}
        </Link>
      </p>
    </SystemPageContent>
  );
};

export default PublicResourcePage;
