import React from "react";
import { Icon } from "semantic-ui-react";
import { ResourceType } from "constants/enums";
import {
  ResourceFileType,
  ResourceUrlType,
  getResourceFileType,
  getResourceUrlType,
} from "modules/helpers";

const ResourceIcon = ({ resource, ...props }) => {
  let name = "eye";

  if (resource?.type === ResourceType.File) {
    const fileType = getResourceFileType(resource.fileName);
    name = fileType === ResourceFileType.Image ? "eye" : "download";
  } else if (resource?.type === ResourceType.Url) {
    const urlType = getResourceUrlType(resource.url);
    name = urlType === ResourceUrlType.Video ? "play" : "external";
  }

  return <Icon {...props} name={name}></Icon>;
};

export default ResourceIcon;
