import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "LOOKUP_DATA";

const ActionTypes = {
  GetEventStatuses: `${NAMESPACE}/GET_EVENT_STATUSES`,
  GetEventFormats: `${NAMESPACE}/GET_EVENT_FORMATS`,
  GetEventLocationTypes: `${NAMESPACE}/GET_EVENT_LOCATION_TYPES`,
  GetEventPartnerTypes: `${NAMESPACE}/GET_EVENT_PARTNER_TYPES`,
  GetEventPartnerPrivacy: `${NAMESPACE}/GET_EVENT_PARTNER_PRIVACY`,
  GetEventDetails: `${NAMESPACE}/GET_EVENT_DETAILS`,
  GetNoteTypes: `${NAMESPACE}/GET_NOTE_TYPES`,
  GetProgrammeThemes: `${NAMESPACE}/GET_PROGRAMME_THEMES`,
  GetProgrammeContentTypes: `${NAMESPACE}/GET_PROGRAMME_CONTENT_TYPES`,
  GetParticipantTypes: `${NAMESPACE}/GET_PARTICIPANT_TYPES`,
  GetParticipationStatuses: `${NAMESPACE}/GET_PARTICIPATION_STATUSES`,
  GetResourceTypes: `${NAMESPACE}/GET_RESOURCE_TYPES`,
  GetResourceScopes: `${NAMESPACE}/GET_RESOURCE_SCOPES`,
  GetResourceParticipantAccess: `${NAMESPACE}/GET_RESOURCE_PARTICIPANT_ACCESS`,
  GetParticipantEventDetails: `${NAMESPACE}/GET_PARTICIPANT_EVENT_DETAILS`,
  GetSocialMediaTypes: `${NAMESPACE}/GET_SOCIAL_MEDIA_TYPES`,
  GetEventCategories: `${NAMESPACE}/GET_EVENT_CATEGORIES`,
  GetIdeaDetails: `${NAMESPACE}/GET_IDEA_DETAILS`,
  GetIdeaStatuses: `${NAMESPACE}/GET_IDEA_STATUSES`,
  GetConceptStatuses: `${NAMESPACE}/GET_CONCEPT_STATUSES`,
  GetIdeaTeamMembers: `${NAMESPACE}/GET_IDEA_TEAM_MEMBERS`,
  GetSeriesDetails: `${NAMESPACE}/GET_SERIES_DETAILS`,
  GetOrganisationDetails: `${NAMESPACE}/GET_ORGANISATION_DETAILS`,
  GetOrganisationTypes: `${NAMESPACE}/GET_ORGANISATION_TYPES`,
  GetMyProfileImages: `${NAMESPACE}/MY_PROFILE_IMAGES`,
  GetConceptDetails: `${NAMESPACE}/GET_CONCEPT_DETAILS`,
  GetRegions: `${NAMESPACE}/GET_REGIONS`,
  GetCountries: `${NAMESPACE}/GET_COUNTRIES`,
  GetContactDetails: `${NAMESPACE}/GET_CONTACT_DETAILS`,
  GetGenders: `${NAMESPACE}/GET_GENDERS`,
  GetContactPreferences: `${NAMESPACE}/GET_CONTACT_PREFERENCES`,
  GetEventTeamMembers: `${NAMESPACE}/GET_EVENT_TEAM_MEMBERS`,
  GetSiteTypes: `${NAMESPACE}/GET_SITE_TYPES`,
  GetPriorities: `${NAMESPACE}/GET_PRIORITIES`,
  GetOnlineOfflineStatuses: `${NAMESPACE}/GET_ONLINE_OFFLINE_STATUSES`,
  GetFundingStatuses: `${NAMESPACE}/GET_FUNDING_STATUSES`,
  GetParticipantFeeTypes: `${NAMESPACE}/GET_PARICIPANT_FEE_TYPES`,
  GetParticipantPaymentMethods: `${NAMESPACE}/GET_PARICIPANT_PAYMENT_METHODS`,
  GetQuarters: `${NAMESPACE}/GET_QUARTERS`,
  GetOdaEligibility: `${NAMESPACE}/GET_ODA_ELIGIBILITY`,
  GetSectors: `${NAMESPACE}/GET_SECTORS`,
  GetProgrammeSubThemes: `${NAMESPACE}/GET_PROGRAMME_SUB_THEMES`,
  GetParticipantUnableReasons: `${NAMESPACE}/GET_UNABLE_REASONS`,
  GetParticipantAttendanceCategories: `${NAMESPACE}/GET_ATTENDANCE_CATEGORIES`,
  GetParticipantRepresentations: `${NAMESPACE}/GET_REPRESENTATIONS`,
  GetFriendsOfWiltonPark: `${NAMESPACE}/GET_FRIENDS_OF_WILTON_PARK`,
  GetFundingPaymentScheduleStatuses: `${NAMESPACE}/GET_FUNDING_PAYMENT_SCHEDULE_STATUSES`,
  GetContactStatuses: `${NAMESPACE}/GET_CONTACT_STATUSES`,
  GetOrganisationStatuses: `${NAMESPACE}/GET_ORGANISATION_STATUSES`,
  GetAvailableOrganisations: `${NAMESPACE}/GET_AVAILABLE_ORGANISATIONS`,
  GetDepartmentDetails: `${NAMESPACE}/GET_DEPARTMENT_DETAILS`,
  GetRegistrationItemTypes: `${NAMESPACE}/GET_REGISTRATION_ITEM_TYPES`,
  GetRegistrationItemWidths: `${NAMESPACE}/GET_REGISTRATION_ITEM_WIDTHS`,
  GetAvailableOrganisationsAdvancedSearch: `${NAMESPACE}/GET_ORGANISATIONS_ADVANCED_SEARCH`,
  GetPurchaseOrderCostTypes: `${NAMESPACE}/GET_PURCHASE_ORDERS_COST_TYPES`,
  GetTimeRelativity: `${NAMESPACE}/GET_TIME_RELATIVITY`,
  GetEventSecurityLevels: `${NAMESPACE}/GET_EVENT_SECURITY_LEVELS`,
  GetAvailableOriginatingEvents: `${NAMESPACE}/GET_AVAILABLE_ORIGINATING_EVENTS`,
  GetPurchaseOrderDetails: `${NAMESPACE}/GET_PURCHASE_ORDER_DETAILS`,
};

export const getEventStatuses = () =>
  apiGet(ActionTypes.GetEventStatuses, "event-statuses");

export const getEventFormats = () =>
  apiGet(ActionTypes.GetEventFormats, "event-formats");

export const getEventLocationTypes = () =>
  apiGet(ActionTypes.GetEventLocationTypes, "event-location-types");

export const getEventPartnerTypes = () =>
  apiGet(ActionTypes.GetEventPartnerTypes, "event-partner-types");

export const getEventPartnerPrivacy = () =>
  apiGet(ActionTypes.GetEventPartnerPrivacy, "event-partner-privacy");

export const getEventDetails = (eventId) =>
  apiGet(ActionTypes.GetEventDetails, `events/${eventId}`);

export const getNoteTypes = () =>
  apiGet(ActionTypes.GetNoteTypes, "note-types");

export const getProgrammeThemes = () =>
  apiGet(ActionTypes.GetProgrammeThemes, "programme-themes");

export const getProgrammeContentTypes = () =>
  apiGet(ActionTypes.GetProgrammeContentTypes, "programme-content-types");

export const getParticipantTypes = () =>
  apiGet(ActionTypes.GetParticipantTypes, "participant-types");

export const getParticipationStatuses = () =>
  apiGet(ActionTypes.GetParticipationStatuses, "participation-statuses");

export const getResourceTypes = () =>
  apiGet(ActionTypes.GetResourceTypes, "resource-types");

export const getResourceScopes = () =>
  apiGet(ActionTypes.GetResourceScopes, "resource-scopes");

export const getResourceParticipantAccess = () =>
  apiGet(
    ActionTypes.GetResourceParticipantAccess,
    "resource-participant-access"
  );

export const getParticipantEventDetails = (eventId) =>
  apiGet(
    ActionTypes.GetParticipantEventDetails,
    `participant-events/${eventId}`
  );

export const getSocialMediaTypes = () =>
  apiGet(ActionTypes.GetSocialMediaTypes, "social-media-types");

export const getEventCategories = () =>
  apiGet(ActionTypes.GetEventCategories, "event-categories");

export const getIdeaDetails = (ideaId) =>
  apiGet(ActionTypes.GetIdeaDetails, `ideas/${ideaId}`);

export const getIdeaStatuses = () =>
  apiGet(ActionTypes.GetIdeaStatuses, "idea-statuses");

export const getConceptStatuses = () =>
  apiGet(ActionTypes.GetConceptStatuses, "concept-statuses");

export const getIdeaTeamMembers = ({ search }) =>
  apiGet(ActionTypes.GetIdeaTeamMembers, "ideas/available-team-members", {
    search,
  });

export const getEventTeamMembers = ({ search }) =>
  apiGet(ActionTypes.GetEventTeamMembers, "events/available-team-members", {
    search,
  });

export const getSeriesDetails = (seriesId) =>
  apiGet(ActionTypes.GetSeriesDetails, `series/${seriesId}`);

export const getOrganisationDetails = (organisationId) =>
  apiGet(ActionTypes.GetOrganisationDetails, `organisations/${organisationId}`);

export const getOrganisationTypes = () =>
  apiGet(ActionTypes.GetOrganisationTypes, "organisation-types");

export const getMyProfileImages = () =>
  apiGet(ActionTypes.GetMyProfileImages, "profile/images");

export const getConceptDetails = (conceptId) =>
  apiGet(ActionTypes.GetConceptDetails, `concepts/${conceptId}`);

export const getRegions = () => apiGet(ActionTypes.GetRegions, `regions`);

export const getCountries = (params) =>
  apiGet(ActionTypes.GetCountries, `countries`, {
    search: params?.search ?? null,
    regionId: params?.regionId ?? null,
    includePreferNotToSay: params?.includePreferNotToSay ?? false,
  });

export const getGenders = () => apiGet(ActionTypes.GetGenders, `genders`);

export const getContactDetails = (contactId) =>
  apiGet(ActionTypes.GetContactDetails, `contacts/${contactId}`);

export const getContactPreferences = () =>
  apiGet(ActionTypes.GetContactPreferences, `contact-preferences`);

export const getSiteTypes = () =>
  apiGet(ActionTypes.GetSiteTypes, "site-types");
export const getPriorities = () =>
  apiGet(ActionTypes.GetPriorities, `priorities`);

export const getOnlineOfflineStatuses = () =>
  apiGet(ActionTypes.GetOnlineOfflineStatuses, `online-offline-statuses`);

export const getFundingStatuses = () =>
  apiGet(ActionTypes.GetFundingStatuses, `funding-statuses`);

export const getParticipantFeeTypes = () =>
  apiGet(ActionTypes.GetParticipantFeeTypes, `participant-fee-types`);

export const getParticipantPaymentMethods = () =>
  apiGet(
    ActionTypes.GetParticipantPaymentMethods,
    `participant-payment-methods`
  );

export const getQuarters = () => apiGet(ActionTypes.GetQuarters, `quarters`);

export const getOdaEligibility = () =>
  apiGet(ActionTypes.GetOdaEligibility, `oda-eligibility`);

export const getSectors = () => {
  return apiGet(ActionTypes.GetSectors, `sectors`);
};

export const getProgrammeSubThemes = () =>
  apiGet(ActionTypes.GetProgrammeSubThemes, `programme-sub-themes`);

export const getParticipantUnableReasons = () =>
  apiGet(ActionTypes.GetParticipantUnableReasons, `participant-unable-reasons`);

export const getParticipantRepresentations = () =>
  apiGet(
    ActionTypes.GetParticipantRepresentations,
    `participant-representations`
  );

export const getParticipantAttendanceCategories = () =>
  apiGet(
    ActionTypes.GetParticipantAttendanceCategories,
    `participant-attendance-categories`
  );

export const getFriendsOfWiltonPark = () =>
  apiGet(ActionTypes.GetFriendsOfWiltonPark, `friends-of-wilton-park`);

export const getContactStatuses = () =>
  apiGet(ActionTypes.GetContactStatuses, "contact-statuses");

export const getOrganisationStatuses = () =>
  apiGet(ActionTypes.GetOrganisationStatuses, "organisation-statuses");

export const GetFundingPaymentScheduleStatuses = () =>
  apiGet(
    ActionTypes.GetFundingPaymentScheduleStatuses,
    `funding-payment-schedule-statuses`
  );

export const getDepartmentDetails = (departmentId) =>
  apiGet(ActionTypes.GetDepartmentDetails, `departments/${departmentId}`);

export const getAvailableOrganisations = ({
  search,
  excludeOrganisationIds,
}) => {
  return apiGet(
    ActionTypes.GetAvailableOrganisations,
    `organisations/available-organisations`,
    {
      pageSize: 10,
      pageNumber: 1,
      search,
      excludeOrganisationIds: excludeOrganisationIds,
    }
  );
};

export const getAvailableOrganisationsAdvancedSearch = (
  { pagination },
  { params, filters }
) => {
  const { pageSize, pageNumber } = pagination;
  const { search, countryIds, townOrCity } = filters;
  return apiGet(
    ActionTypes.GetAvailableOrganisationsAdvancedSearch,
    `organisations/available-organisations`,
    {
      pageSize,
      pageNumber,
      search,
      excludeOrganisationIds: params?.excludeOrganisationIds,
      countryIds,
      townOrCity,
    }
  );
};

export const getPurchaseOrderCostTypes = () =>
  apiGet(ActionTypes.GetPurchaseOrderCostTypes, "purchase-order-cost-types");

export const getTimeRelativity = () =>
  apiGet(ActionTypes.GetTimeRelativity, `time-relativity`);

export const getEventSecurityLevels = () =>
  apiGet(ActionTypes.GetEventSecurityLevels, `event-security-levels`);

export const getAvailableOriginatingEvents = ({ eventId, state, search }) =>
  apiGet(
    ActionTypes.GetAvailableOriginatingEvents,
    `events/available-originating-events`,
    {
      search,
      eventId,
      state,
    }
  );

export const getPurchaseOrderDetails = (id) =>
  apiGet(ActionTypes.GetPurchaseOrderDetails, `purchase-orders/${id}`);
