import React from "react";
import classNames from "classnames";
import UserIcon from "./UserIcon";
import { Popup } from "semantic-ui-react";

const ProfileInfoUserIcon = ({ image, className, jobTitle, userName }) => (
  <div className={classNames("user-icon-profile-details", className)}>
    <UserIcon image={image} userName={userName} />
    <Popup
      inverted
      content={
        <React.Fragment>
          <div>{userName}</div>
          <div>{jobTitle}</div>
        </React.Fragment>
      }
      trigger={
        <div className="user-info">
          <div>{userName}</div>
          <div>{jobTitle}</div>
        </div>
      }
    ></Popup>
  </div>
);

export default ProfileInfoUserIcon;
