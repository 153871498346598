import React, { useMemo } from "react";
import classNames from "classnames";
import { availableOrganisationsLookup } from "modules/lookups";
import { Form, useFormField } from "@redriver/cinnamon";
import OrganisationAdvancedSearch from "components/modals/OrganisationAdvancedSearch";
import { getAvailableOrganisationsAdvancedSearch } from "modules/lookups/actions";

const OrganisationTypeAhead = ({
  action = getAvailableOrganisationsAdvancedSearch,
  lookup = availableOrganisationsLookup,
  lookupParams = null,
  attachedButton = false,
  fluid,
  multiple,
  ...props
}) => {
  const [fields, setFields] = useFormField(props.field);
  const [textFields, setTextFields] = useFormField(props.textField);

  const selectedOrganisationIds = useMemo(() => {
    return fields
      ? multiple
        ? fields.filter((f) => isGuid(f))
        : isGuid(fields)
        ? [fields]
        : []
      : [];
  }, [fields, multiple]);

  return (
    <div
      className={classNames("organisation-typeahead field", {
        fluid,
        "attached-button": attachedButton,
      })}
    >
      <Form.TypeAhead
        lookup={lookup}
        lookupParams={lookupParams}
        fluid={fluid}
        multiple={multiple}
        {...props}
      />
      <OrganisationAdvancedSearch
        primary
        compact
        icon="search"
        size="big"
        action={action}
        params={{
          ...lookupParams,
          excludeOrganisationIds: [
            ...(lookupParams?.excludeOrganisationIds ?? []),
            ...selectedOrganisationIds,
          ],
        }}
        onSelect={(organisation) => {
          if (multiple) {
            setFields([...(fields ?? []), organisation.value]);
            setTextFields([...(textFields ?? []), organisation.text]);
          } else {
            setFields(organisation.value);
            setTextFields(organisation.text);
          }
        }}
      />
    </div>
  );
};

const isGuid = (value) => {
  var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
  var match = regex.exec(value);
  return match != null;
};

export default OrganisationTypeAhead;
