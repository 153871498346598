import React from "react";
import { Image } from "semantic-ui-react";
import DocIcon from "assets/files/doc.svg";
import GifIcon from "assets/files/gif.svg";
import JpgIcon from "assets/files/jpg.svg";
import PdfIcon from "assets/files/pdf.svg";
import PngIcon from "assets/files/png.svg";
import PptIcon from "assets/files/ppt.svg";
import TxtIcon from "assets/files/txt.svg";
import XlsIcon from "assets/files/xls.svg";
import ZipIcon from "assets/files/zip.svg";
import UnknownIcon from "assets/files/unknown.svg";

const getFileIcon = (fileExtension) => {
  switch (fileExtension) {
    case "doc":
    case "docx":
      return DocIcon;
    case "gif":
      return GifIcon;
    case "jpg":
    case "jpeg":
      return JpgIcon;
    case "pdf":
      return PdfIcon;
    case "png":
      return PngIcon;
    case "ppt":
    case "pptx":
      return PptIcon;
    case "txt":
      return TxtIcon;
    case "xls":
    case "xlsx":
      return XlsIcon;
    case "zip":
      return ZipIcon;
    default:
      return UnknownIcon;
  }
};

const FileIcon = ({ fileName }) => {
  const match = fileName.match(/(?:\.([^.]+))?$/);
  const ext = match && match.length > 1 ? match[1].toLowerCase() : undefined;
  return <Image src={getFileIcon(ext)} className="file-icon" />;
};

export default FileIcon;
