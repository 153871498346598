import React from "react";
import { Lookup } from "@redriver/cinnamon";
import { Popup } from "semantic-ui-react";
import { resourceScopeLookup } from "modules/lookups";
import { CascadingRadioGroup } from "./fields";
import { InfoIcon } from "components/icons";

const ResourceScopeRadioGroup = ({ label, showScopeInfo, ...props }) => (
  <Lookup
    lookup={resourceScopeLookup}
    render={({ response }) => (
      <CascadingRadioGroup
        {...props}
        disabled={response ? props.disabled : true}
        options={response || [{ value: "_" }]}
        label={
          label ? (
            <label>
              {label}
              {showScopeInfo && (
                <Popup
                  inverted
                  content={
                    <React.Fragment>
                      <p>
                        Resources will be available to Portal users meeting the
                        Participant Status/Type criteria specified.
                      </p>
                      <p>
                        In addition, Public resources can also be added to
                        participant emails for sending externally to non-Portal
                        users.
                      </p>
                    </React.Fragment>
                  }
                  trigger={<InfoIcon />}
                />
              )}
            </label>
          ) : undefined
        }
      />
    )}
  />
);

export default ResourceScopeRadioGroup;
