import { useAuthClaims } from "@redriver/cinnamon";

const useUserInitials = (userName = undefined) => {
  const authClaims = useAuthClaims();

  const names = (userName ?? authClaims.userName).split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export default useUserInitials;
