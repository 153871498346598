import React from "react";
import { Form } from "@redriver/cinnamon";
import { PortalStatus, enumOptionsArray } from "constants/enums";

const portalStatusOptions = enumOptionsArray(PortalStatus, {
  [PortalStatus.NotInvited]: "Not Invited",
});

const PortalStatusDropdown = (props) => (
  <Form.Dropdown {...props} options={portalStatusOptions} />
);

export default PortalStatusDropdown;
