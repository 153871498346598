import React, { useState, useEffect } from "react";
import { Form, useFormField } from "@redriver/cinnamon";

const HiddenOption = () => <div />;

const UserTitleDropdown = (props) => {
  const [fieldValue] = useFormField(props.field);

  const appendHiddenOption = (options, value) => {
    const addValue =
      value !== null &&
      value !== undefined &&
      options.every((o) => o.value !== value);
    return addValue
      ? options.concat([{ value, text: value, as: HiddenOption }])
      : options;
  };

  const [options, setOptions] = useState(
    appendHiddenOption(
      [
        { value: "Mr", text: "Mr" },
        { value: "Mrs", text: "Mrs" },
        { value: "Miss", text: "Miss" },
        { value: "Ms", text: "Ms" },
        { value: "Mx", text: "Mx" },
      ],
      fieldValue
    )
  );

  useEffect(() => {
    setOptions((prevOptions) => appendHiddenOption(prevOptions, fieldValue));
  }, [fieldValue]);

  return (
    <Form.Dropdown
      placeholder=" "
      {...props}
      search
      allowAdditions
      additionLabel=""
      options={options}
      selectOnBlur
      clearable
    />
  );
};

export default UserTitleDropdown;
