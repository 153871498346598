import React from "react";
import { Lookup } from "@redriver/cinnamon";
import { resourceParticipantAccessLookup } from "modules/lookups";
import { CascadingCheckboxGroup } from "./fields";

const ResourceParticipantAccessCheckboxGroup = (props) => (
  <Lookup
    lookup={resourceParticipantAccessLookup}
    render={({ response }) => (
      <CascadingCheckboxGroup
        {...props}
        disabled={response ? props.disabled : true}
        options={response || [{ value: "_" }]}
      />
    )}
  />
);

export default ResourceParticipantAccessCheckboxGroup;
