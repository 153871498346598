import React from "react";
import { Form } from "@redriver/cinnamon";

const YesNoDropdown = (props) => {
  const options = [
    { value: true, text: "Yes" },
    { value: false, text: "No" },
  ];
  return <Form.Dropdown {...props} options={options} />;
};

export default YesNoDropdown;
