import {
  setApiOptions,
  setLoggingOptions,
  enableJwtAuth,
  enableSentryLogger,
  enableSegmentLogger,
  enableHotjarLogger,
  setPreferenceDefaults,
  setResponsiveOptions,
  createDefaultReduxStore,
  enablePreferencesFromLocalStorage,
} from "@redriver/cinnamon";
import reducers from "./reducers";
import { onRefreshJwt, onDeauthenticated } from "features/System";
import { ScreenBreakpointNames } from "constants/screenBreakpoints";

export const setupApp = () => {
  configureApi();
  configureAuth();
  configureLogging();
  configurePreferences();
  configureResponsive();
  const store = configureStore();
  return { store };
};

// api / auth modules

const configureApi = () =>
  setApiOptions({
    apiUrl: process.env.API_URL,
  });

const configureAuth = () =>
  enableJwtAuth({
    tokenKey: `${process.env.COOKIE_PREFIX}-token-key`,
    jwtClaims: {
      emailVerified: {
        type: "boolean",
        key: "ev",
      },
      userId: {
        type: "string",
        key: "sub",
      },
      userTitle: {
        type: "string",
        key: "t",
      },
      userName: {
        type: "join",
        keys: ["fn", "ln"],
        separator: " ",
      },
      userEmail: {
        type: "string",
        key: "e",
        identity: "email",
      },
      userType: {
        type: "string",
        key: "ut",
      },
      systemTimeout: {
        type: "number",
        key: "st",
      },
      permissions: {
        type: "object",
        keys: "^p_(.+)",
      },
    },
    onRefreshJwt,
    onDeauthenticated,
  });

// logging module

const configureLogging = () => {
  setLoggingOptions({
    environment: process.env.LOGGING_ENVIRONMENT,
    release: process.env.LOGGING_RELEASE,
  });
  if (process.env.SENTRY_DSN) {
    enableSentryLogger(process.env.SENTRY_DSN, {
      tracingEnabled: true,
      sampleRate: 0.2,
    });
  }
  if (process.env.SEGMENT_APIKEY) {
    enableSegmentLogger(process.env.SEGMENT_APIKEY);
  }
  if (process.env.HOTJAR_SITEID) {
    enableHotjarLogger(process.env.HOTJAR_SITEID);
  }
};

// preferences module

const configurePreferences = () => {
  setPreferenceDefaults({
    timezone: "Europe/London",
  });
  enablePreferencesFromLocalStorage("wp-preferences");
};

// responsive module

const configureResponsive = () =>
  setResponsiveOptions({
    screenSizes: {
      [ScreenBreakpointNames.Mobile]: { max: 639, className: "mobile" },
      [ScreenBreakpointNames.Tablet]: {
        min: 640,
        max: 1067,
        className: "tablet",
      },
      [ScreenBreakpointNames.Desktop]: { min: 1068 },
    },
  });

// redux store module

const configureStore = () =>
  createDefaultReduxStore({
    reducers,
    reduxDevTools: process.env.REDUX_DEVTOOLS,
  });
