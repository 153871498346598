import React from "react";
import classNames from "classnames";

const ProgressNav = ({
  sections = [],
  horizontal,
  className,
  children,
  ...props
}) => {
  return (
    <nav
      className={classNames(
        "progress-nav",
        {
          horizontal: !!horizontal,
        },
        className
      )}
      {...props}
    >
      <ol className="progress-items">
        {React.Children.map(children, (child, i) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { num: i + 1 });
          }
          return null;
        })}
      </ol>
    </nav>
  );
};

ProgressNav.Item = ({
  completed,
  selected,
  locked,
  num,
  name,
  onClick,
  className,
  icon,
}) => (
  <li
    className={classNames(
      "progress-item",
      {
        completed: completed,
        selected: selected,
        locked: locked,
      },
      className
    )}
    onClick={locked ? undefined : onClick}
  >
    <span className="progress-num">{icon ? icon : num}</span>
    <span className="progress-title">{name}</span>
  </li>
);

export default ProgressNav;
