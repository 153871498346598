import PhoneIcon from "assets/icons/phone.svg";
import MobileIcon from "assets/icons/mobile.svg";
import EmailIcon from "assets/icons/email.svg";

export const buildContactPoints = (contact) => {
  const contactPoints = [];
  if (contact.emailAddress) {
    contactPoints.push({
      icon: EmailIcon,
      text: contact.emailAddress,
    });
  }

  if (contact.telephone) {
    contactPoints.push({
      icon: PhoneIcon,
      text: contact.telephone,
    });
  }

  if (contact.mobilePhone) {
    contactPoints.push({
      icon: MobileIcon,
      text: contact.mobilePhone,
    });
  }
  return contactPoints;
};
