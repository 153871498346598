import { mockSuccess, apiGet, apiPut, apiDelete } from "@redriver/cinnamon";
import { DateTime } from "luxon";

const NAMESPACE = "CALENDAR";

export const ActionTypes = {
  SetSelectedWeek: `${NAMESPACE}/SET_SELECTED_WEEK`,
  LoadCalendarItems: `${NAMESPACE}/LOAD_CALENDAR_ITEMS`,
  ClearCalendarItems: `${NAMESPACE}/CLEAR_CALENDAR_ITEMS`,
  UpdateFilters: `${NAMESPACE}/UPDATE_FILTERS`,
  GetEventItemDetails: `${NAMESPACE}/GET_EVENT_ITEM_DETAILS`,
  ResetCalendarItems: `${NAMESPACE}/RESET_CALENDAR_ITEMS`,
  GetConceptItemDetails: `${NAMESPACE}/GET_CONCEPT_ITEM_DETAILS`,
  EditAdhocDate: `${NAMESPACE}/EDIT_ADHOC_DATES`,
  DeleteAdhocDate: `${NAMESPACE}/DELETE_ADHOC_DATE`,
  RemoveItemFromCalendar: `${NAMESPACE}/REMOVE_ITEM_FROM_CALENDAR`,
};

export const setSelectedWeek = (selectedWeek, visibleWeekCount) => {
  return {
    type: ActionTypes.SetSelectedWeek,
    selectedWeek,
    visibleWeekCount,
  };
};

export const loadCalendarItems = ({ from, to }) => {
  return apiGet(ActionTypes.LoadCalendarItems, "calendar", { from, to });
};

export const clearCalendarItem = () => ({
  type: ActionTypes.ClearCalendarItems,
});

export const updateFilters = (filters) => ({
  type: ActionTypes.UpdateFilters,
  filters,
});

export const getEventItemDetails = (eventId) =>
  apiGet(ActionTypes.GetEventItemDetails, `events/${eventId}`);

export const getConceptItemDetails = (conceptId) =>
  apiGet(ActionTypes.GetConceptItemDetails, `concepts/${conceptId}`);

export const resetCalendarItems = () => ({
  type: ActionTypes.ResetCalendarItems,
});

export const editAdhocDates = (data, id) => {
  return apiPut(ActionTypes.EditAdhocDate, `calendar/fixture/${id}`, data);
};

export const deleteAdhocDate = (id) => {
  return apiDelete(ActionTypes.DeleteAdhocDate, `calendar/fixture/${id}`);
};

export const removeItemFromCalendar = (id) => ({
  type: ActionTypes.RemoveItemFromCalendar,
  id,
});
