import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { SystemRoutes } from "constants/routes";
import { requestPasswordForgotten } from "./actions";

const passwordErrors = {
  1: "Password cannot be reset at this time",
  913010: "Email address not recognised",
  913011: "There is no active account for this user. If you have been given an account, please check your email to complete your user verification by clicking on the verification link within, or contact enquiries@wiltonpark.org.uk for further assistance and provide the name of the event that you are invited to.",
};

const PasswordForgotten = () => {
  const [requestSent, setRequestSent] = useState(false);
  const [requestEmail, setRequestEmail] = useState("");

  if (requestSent) {
    return (
      <div>
        <p>
          A password reset email has been sent
          {requestEmail ? ` to ${requestEmail}` : ""}
        </p>
        <div style={{ marginTop: 20 }}>
          <Button primary fluid as={Link} to={SystemRoutes.Login} size="large">
            Continue
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      submitAction={requestPasswordForgotten}
      onSubmit={({ email }) => setRequestEmail(email)}
      onSubmitted={() => setRequestSent(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps}>
          <Form.Email
            field="email"
            label="Email"
            placeholder="Enter email address"
            disabled={submitting && slowSubmitting}
            autoFocus
            required
            fluid
          />
          <Button
            primary
            onClick={events.onSubmit}
            disabled={submitting}
            loading={submitting && slowSubmitting}
            fluid
            size="large"
          >
            Continue
          </Button>
          <ErrorMessage
            header="Forgotten password error"
            error={error}
            overrideMessages={passwordErrors}
            omitCodes
          />
          <p>
            Return to <Link to={SystemRoutes.Login}>Log In</Link>
          </p>
        </Form>
      )}
    />
  );
};

export default PasswordForgotten;
