import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { lookupRequest, getLookupData } from "@redriver/cinnamon";
import { EventRoutes } from "constants/routes";
import { useRouteMatch } from "react-router";
import { EventMenuItems } from "components/menus";
import { eventDetailsLookup } from "modules/lookups";

const EventSubMenu = () => {
  const ideasMatch = useRouteMatch(EventRoutes.Ideas);
  const seriesMatch = useRouteMatch(EventRoutes.Series);
  const conceptMatch = useRouteMatch(EventRoutes.Concepts);
  const eventsMatch = useRouteMatch(EventRoutes.Event());

  const eventId =
    ideasMatch || seriesMatch || conceptMatch
      ? null
      : eventsMatch?.params?.eventId;

  const eventData = useSelector((state) =>
    getLookupData(state, eventDetailsLookup, eventId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (eventId) dispatch(lookupRequest(eventDetailsLookup, eventId));
  }, []);

  if (!eventId || eventData.loading || !eventData.response) return null;

  return (
    <Menu.Menu className="submenu">
      <EventMenuItems eventId={eventId} header={eventData.response.code} />
    </Menu.Menu>
  );
};

export default EventSubMenu;
