import React from "react";

const DraftIcon = ({
  color = "#0b0c10",
  disabled = false,
  title,
  ...props
}) => {
  const selectedColour = disabled ? "#c9c9d2" : color;
  return (
    <svg
      height="512"
      viewBox="0 0 24 24"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="m6.39 14.95a.9959.9959 0 0 0 -.28.57l-.71 4.94c-.0738.5894.29 1.14 1.14 1.14l4.94-.71a.9959.9959 0 0 0 .57-.28l6.57-6.57-5.66-5.66z"
        stroke={selectedColour}
        fill={selectedColour}
      />
      <path
        d="m14.39 6.97 5.64 5.64a3.96 3.96 0 0 0 .97-2.61 4.018 4.018 0 0 0 -6.61-3.03z"
        stroke={selectedColour}
        fill={selectedColour}
      />
      <path
        d="m4 5h9a1 1 0 0 0 0-2h-9a1 1 0 0 0 0 2z"
        stroke={selectedColour}
        fill={selectedColour}
      />
      <path
        d="m4 9h6a1 1 0 0 0 0-2h-6a1 1 0 0 0 0 2z"
        stroke={selectedColour}
        fill={selectedColour}
      />
      <path
        d="m6 11h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"
        stroke={selectedColour}
        fill={selectedColour}
      />
    </svg>
  );
};

export default DraftIcon;
