import React from "react";
import classNames from "classnames";
import ReactPlayer from "react-player/lazy";
import { ModalBuilder, Modal } from "@redriver/cinnamon";

const VideoPlayer = ({ renderTrigger, className, ...props }) => (
  <ModalBuilder
    renderTrigger={renderTrigger}
    renderModal={(modalProps, _, state, events) => (
      <Modal
        open={modalProps.open}
        basic
        closeIcon
        onClose={() => events.onCancel()}
        className={classNames("video-player", className)}
      >
        <ReactPlayer
          {...props}
          width="854px"
          height="480px"
          className="react-player"
        />
      </Modal>
    )}
  />
);

export default VideoPlayer;
