import React from "react";
import classNames from "classnames";
import { Button } from "semantic-ui-react";
import { Table } from "@redriver/cinnamon";

const SelectionTable = ({ footer, className, ...props }) => (
  <div className={classNames("selection-table", className)}>
    <Table selectable {...props} />
    {footer}
  </div>
);

SelectionTable.Column = Table.Column;

SelectionTable.SelectColumn = ({ disabled, width, onSelect }) => (
  <Table.Column
    align="right"
    render={(item) => (
      <Button
        primary
        compact
        onClick={() => onSelect && onSelect(item)}
        disabled={disabled}
      >
        Select
      </Button>
    )}
    width={width}
  />
);

export default SelectionTable;
