import React from "react";
import { Form } from "@redriver/cinnamon";
import { countriesLookup } from "modules/lookups";
import { Icon, Popup } from "semantic-ui-react";
import { InfoIcon } from "components/icons";

const CountryDropdown = ({
  label = "Country",
  showPolicyInfo,
  lookupParams,
  ...props
}) => {
  return (
    <Form.TypeAhead
      label={
        label ? (
          <label>
            {label}
            {showPolicyInfo && (
              <Popup
                inverted
                content="This list does not represent official UK policy with regards to recognition of any particular state or entity."
                trigger={<InfoIcon />}
              />
            )}
          </label>
        ) : undefined
      }
      lookup={countriesLookup}
      lookupParams={lookupParams}
      placeholder="Select country"
      search
      searchSuggestions
      {...props}
    />
  );
};

export default CountryDropdown;
