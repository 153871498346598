import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { SeriesRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";

const SeriesMenuItems = ({ seriesId, header }) => {
  const canViewNotes = usePermission(Targets.SeriesNote, Actions.View);
  const canViewFunding = usePermission(Targets.SeriesFunding, Actions.View);
  return (
    <React.Fragment>
      {header && <NavMenu.Header>{header}</NavMenu.Header>}
      <NavMenu.Item link={SeriesRoutes.Series(seriesId)} exact>
        <span>Overview</span>
      </NavMenu.Item>
      {canViewNotes && (
        <NavMenu.Item link={SeriesRoutes.Notes(seriesId)}>
          <span>Notes</span>
        </NavMenu.Item>
      )}
      {canViewFunding && (
        <NavMenu.Item link={SeriesRoutes.Funding(seriesId)}>
          <span>Funding</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default SeriesMenuItems;
