import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";

const DuplicateEmailAddressModal = ({ onSubmit }) => {
  return (
    <ModalBuilder
      renderModal={(modalProps) => (
        <Modal.Information
          {...modalProps}
          header={`Duplicate email address found`}
          submitLabel="Close"
          onSubmit={onSubmit}
        >
          The entered email address is already in use. Please enter a different
          email address.
        </Modal.Information>
      )}
    />
  );
};

export default DuplicateEmailAddressModal;
