import React from "react";
import { Form } from "@redriver/cinnamon";
import { prioritiesLookup } from "modules/lookups";

const PriorityDropdown = (props) => (
  <Form.Dropdown
    label="Priority"
    lookup={prioritiesLookup}
    placeholder=" "
    {...props}
  />
);

export default PriorityDropdown;
