import React from "react";
import PropTypes from "prop-types";
import { FormUtils, Field, FormContext } from "@redriver/cinnamon";

class CustomErrors extends React.Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    triggerFields: PropTypes.arrayOf(PropTypes.string),
    validator: PropTypes.func.isRequired,
    showErrors: PropTypes.bool,
    allErrors: PropTypes.bool,
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  };

  static defaultProps = {
    triggerFields: [],
  };

  static contextType = FormContext;

  _prevContext = null;

  componentDidMount() {
    this.validate();
    this._prevContext = this.context;
  }

  componentDidUpdate() {
    const { triggerFields } = this.props;

    const triggered = triggerFields.some((f) => {
      const currentValue = this.context.value[f];
      const prevValue = this._prevContext.value[f];
      return currentValue !== prevValue;
    });

    if (triggered) this.validate();

    this._prevContext = this.context;
  }

  validate = () => {
    const { field, validator } = this.props;

    const formState = FormUtils.fieldFormState(this.context);
    const errors = validator(formState) || [];

    const applyErrors = (prevErrors) => ({
      ...prevErrors,
      [field]: errors,
    });

    this.context.onError(field, errors, applyErrors);
  };

  render() {
    const { field, showErrors, allErrors, animateErrors } = this.props;
    const contextErrors = this.context.errors || {};

    const fieldErrors = FormUtils.fieldErrors(
      contextErrors[field],
      showErrors ?? this.context.showErrors,
      allErrors ?? this.context.allErrors
    );

    return (
      <Field
        errors={fieldErrors}
        animateErrors={animateErrors ?? this.context.animateErrors}
        className="custom-errors"
      />
    );
  }
}

export default CustomErrors;
