import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { getUserAccountDetails, updateUserAccountDetails } from "./actions";
import { Button, Popup, Icon } from "semantic-ui-react";
import { FormModal } from "components/modals";
import { ContactPreferencesDropdown, YesNoDropdown } from "components/forms";
import { ExternalLink } from "components/navigation";
import { ExternalUrls } from "constants/urls";
import InfoIcon from "components/icons/InfoIcon";
import { WiltonPark } from "constants/contacts";

const EditAccountPreferences = ({ onUpdated, as: As = Button, ...props }) => {
  return (
    <ModalBuilder
      withForm
      loadAction={getUserAccountDetails}
      loadTransform={(data) => ({
        preferences: {
          salutation: data.salutation,
          preferredContactMethod: data.preferredContactMethod,
          sendUpdatesAndInvites: data.sendUpdatesAndInvites,
        },
      })}
      submitAction={updateUserAccountDetails}
      onSubmitted={onUpdated}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <FormModal {...modalProps} header="Edit My Preferences" size="tiny">
          <Form {...formProps}>
            <Form.Object field="preferences">
              <Form.Input
                field="salutation"
                label={
                  <label>
                    How would you like us to address you in correspondence?
                    <Popup
                      content="Please enter the full name that you would like to be addressed as in any correspondence, for example, Mr Smith, John Smith, John, Mr John Smith, Ambassador"
                      position="top center"
                      inverted
                      trigger={<InfoIcon />}
                    />
                  </label>
                }
                fluid
              />

              <ContactPreferencesDropdown
                field="preferredContactMethod"
                label="Preferred Contact Method"
                fluid
                placeholder=" "
                clearable
              />

              <YesNoDropdown
                field="sendUpdatesAndInvites"
                label={
                  <label>
                    I consent to be contacted by Wilton Park for updates and
                    invites
                    <Popup
                      content={
                        <div>
                          We will only use your personal information as set out
                          in our{" "}
                          <ExternalLink
                            href={ExternalUrls.WiltonPark.PrivacyPolicy}
                          >
                            {" "}
                            Privacy Notice
                          </ExternalLink>
                          , or you can request a copy of it by contacting us
                          directly. If you wish to make any requests relating to
                          the manner in which Wilton Park uses your personal
                          information, please contact our{" "}
                          <a
                            href={`mailto:${WiltonPark.DataProtectionOfficer}`}
                          >
                            {WiltonPark.DataProtectionOfficer}
                          </a>{" "}
                          or the contact details set out in our Privacy Notice.
                          If you would like to continue to receive details from
                          Wilton Park relating to future events or themes that
                          you specialise in, please provide your consent by
                          selecting 'Yes':
                        </div>
                      }
                      position="top center"
                      inverted
                      wide="very"
                      trigger={
                        <InfoIcon
                          style={{
                            display: "inline",
                            marginLeft: "0.5em",
                          }}
                        />
                      }
                      hoverable
                    />
                  </label>
                }
                fluid
                clearable
                placeholder=" "
              />
            </Form.Object>
          </Form>
        </FormModal>
      )}
    />
  );
};

export default EditAccountPreferences;
