import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes, PublicRoutes } from "constants/routes";
import PublicResourcePage from "./PublicResourcePage";
import DataRetentionPage from "./DataRetentionPage";
import PublicFallbackPage from "./PublicFallbackPage";

const PublicRouter = () => (
  <Switch>
    <Route
      exact
      path={PublicRoutes.Resource()}
      component={PublicResourcePage}
    />
    <Route
      exact
      path={PublicRoutes.DataRetention()}
      component={DataRetentionPage}
    />
    <Route
      exact
      path={AppRoutes.Public}
      render={() => <Redirect to={AppRoutes.Root} />}
    />
    <Route component={PublicFallbackPage} />
  </Switch>
);

export default PublicRouter;
