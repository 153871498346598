import React, { useState } from "react";
import classNames from "classnames";
import { Image, Icon } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import ImageCaption from "./ImageCaption";

const ImageLightbox = ({
  images,
  imageData,
  initialIndex = 0,
  renderTrigger,
  as: As,
  className,
  ...props
}) => {
  const [index, setIndex] = useState(initialIndex);
  if (!images || images[index] === undefined) return null;
  return (
    <ModalBuilder
      renderTrigger={
        renderTrigger ?? ((showModal) => <As {...props} onClick={showModal} />)
      }
      renderModal={(modalProps, _, state, events) => (
        <Modal
          open={modalProps.open}
          basic
          closeIcon
          onClose={() => {
            events.onCancel();
            setIndex(initialIndex);
          }}
          className={classNames(
            "image-lightbox",
            { multiple: images.length > 1 },
            className
          )}
        >
          <div className="navigation">
            {images.length > 1 && (
              <Icon
                name="caret left"
                size="huge"
                className="previous"
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setIndex(index <= 0 ? images.length - 1 : index - 1);
                }}
              />
            )}
            <div className="current">
              <div className="image-container">
                <Image src={images[index]} />
                {imageData?.[index] && (
                  <ImageCaption image={imageData[index]} />
                )}
              </div>
            </div>
            {images.length > 1 && (
              <Icon
                name="caret right"
                size="huge"
                className="next"
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setIndex(index >= images.length - 1 ? 0 : index + 1);
                }}
              />
            )}
          </div>
        </Modal>
      )}
    />
  );
};

export default ImageLightbox;
