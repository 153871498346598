import React from "react";
import classNames from "classnames";
import { Segment } from "semantic-ui-react";

const TablePanel = ({ className, children, noBorder = false }) => (
  <Segment
    className={classNames(
      "table-panel",
      {
        "no-border": noBorder,
      },
      className
    )}
  >
    {children}
  </Segment>
);

export default TablePanel;
