import React from "react";
import { ProfileInfoUserIcon } from "components/icons";
import classNames from "classnames";

const TeamMembersDisplay = ({ teamMembers, className, ...props }) => {
  return (
    <div className={classNames("team-members-display", className)} {...props}>
      {(teamMembers || []).map((teamMember) => (
        <ProfileInfoUserIcon
          className="team-member"
          key={teamMember.id}
          userName={teamMember.name}
          jobTitle={teamMember.jobTitle}
          image={teamMember.profileThumbnailUrl}
        />
      ))}
    </div>
  );
};

export default TeamMembersDisplay;
