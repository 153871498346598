import React, { useEffect } from "react";
import classNames from "classnames";
import { useApiGet } from "@redriver/cinnamon";
import { ResourceType } from "constants/enums";
import {
  ResourceFileType,
  ResourceUrlType,
  getResourceFileType,
  getResourceUrlType,
  saveFile,
} from "modules/helpers";
import { useUserType } from "../auth";
import { ImageLightbox } from "../images";
import { VideoPlayer } from "../videos";

const ResourceLink = ({ resource, token, className, children, ...props }) => {
  const { isExternal } = useUserType();

  const downloadUrl = token
    ? `public/resources/${token}/download`
    : `${isExternal ? "participant-events" : "events"}/${
        resource?.eventId
      }/resources/${resource?.id}/download`;

  const [download, beginDownload] = useApiGet(downloadUrl, null, {
    lazy: true,
    auth: !token,
    preventErrorNotification: !!token,
  });

  useEffect(() => {
    if (download.success && download.response) {
      saveFile(download.response, resource.fileName);
    }
  }, [download.response]);

  const renderLink = (linkProps = {}) => (
    <a
      {...props}
      {...linkProps}
      className={classNames(
        "resource-link",
        { busy: download.busy },
        className
      )}
    >
      {typeof children === "function"
        ? children({
            busy: download.busy,
            error: download.error,
          })
        : children}
    </a>
  );

  if (resource?.type === ResourceType.File) {
    const fileType = getResourceFileType(resource.fileName);
    if (fileType === ResourceFileType.PDF) {
      return renderLink({
        target: "_blank",
        rel: "noopener",
        href: resource.url,
      });
    } else if (fileType === ResourceFileType.Image) {
      return (
        <ImageLightbox
          renderTrigger={(show) => renderLink({ onClick: () => show() })}
          images={[resource.url]}
        />
      );
    } else {
      return renderLink({
        onClick: () => {
          if (download.busy) return;
          beginDownload();
        },
      });
    }
  }

  if (resource?.type === ResourceType.Url) {
    const urlType = getResourceUrlType(resource.url);

    if (urlType === ResourceUrlType.Video) {
      return (
        <VideoPlayer
          renderTrigger={(show) => renderLink({ onClick: () => show() })}
          url={resource.url}
          playing
          controls
        />
      );
    } else {
      return renderLink({
        target: "_blank",
        rel: "noopener",
        href: resource.url,
      });
    }
  }

  return null;
};

export default ResourceLink;
