import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { EventRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";

const EventMenuItems = ({ eventId, header }) => {
  const canViewProgramme = usePermission(Targets.Programme, Actions.View);
  const canViewResources = usePermission(Targets.Resource, Actions.View);
  const canViewParticipants = usePermission(Targets.Participant, Actions.View);
  const canViewNotes = usePermission(Targets.EventNote, Actions.View);
  const canViewBudget = usePermission(Targets.EventBudget, Actions.View);
  const canViewPartners = usePermission(Targets.EventPartner, Actions.View);
  const canViewSurveys = usePermission(Targets.EventSurvey, Actions.View);
  const canViewEventObjectives = usePermission(
    Targets.EventObjective,
    Actions.View
  );
  const canViewVenueInformation = usePermission(
    Targets.EventVenueInformation,
    Actions.View
  );
  const canEditRegistration = usePermission(
    Targets.EventRegistrationTemplate,
    Actions.Edit
  );
  const canViewContactSearch = usePermission(
    Targets.EventContactSearch,
    Actions.View
  );
  const canViewMeals = usePermission(Targets.EventMeal, Actions.View);
  const canViewEmailTemplates = usePermission(
    Targets.EventEmailTemplate,
    Actions.View
  );

  return (
    <React.Fragment>
      {header && <NavMenu.Header>{header}</NavMenu.Header>}
      <NavMenu.Item link={EventRoutes.Event(eventId)} exact>
        <span>Dashboard</span>
      </NavMenu.Item>
      <NavMenu.Item link={EventRoutes.Overview(eventId)} exact>
        <span>Overview</span>
      </NavMenu.Item>
      {canViewVenueInformation && (
        <NavMenu.Item link={EventRoutes.VenueInformation(eventId)} exact>
          <span>Venue Information</span>
        </NavMenu.Item>
      )}
      {canViewProgramme && (
        <NavMenu.Item link={EventRoutes.Programme(eventId)}>
          <span>Programme</span>
        </NavMenu.Item>
      )}
      {canViewResources && (
        <NavMenu.Item link={EventRoutes.Resources(eventId)}>
          <span>Resources</span>
        </NavMenu.Item>
      )}
      {canViewParticipants && (
        <NavMenu.Item link={EventRoutes.Participants(eventId)}>
          <span>Participants</span>
        </NavMenu.Item>
      )}
      {canViewProgramme && (
        <NavMenu.Item link={EventRoutes.LiveSessions(eventId)}>
          <span>Live Sessions</span>
        </NavMenu.Item>
      )}
      {canViewMeals && (
        <NavMenu.Item link={EventRoutes.Meals(eventId)}>
          <span>Meals</span>
        </NavMenu.Item>
      )}
      {canViewNotes && (
        <NavMenu.Item link={EventRoutes.Notes(eventId)}>
          <span>Notes</span>
        </NavMenu.Item>
      )}
      {canViewBudget && (
        <NavMenu.Item link={EventRoutes.Budget(eventId)}>
          <span>Budget</span>
        </NavMenu.Item>
      )}
      {canViewPartners && (
        <NavMenu.Item link={EventRoutes.Partners(eventId)}>
          <span>Partners & Funding</span>
        </NavMenu.Item>
      )}
      {(canViewSurveys || canViewEventObjectives) && (
        <NavMenu.Item link={EventRoutes.Impact(eventId)}>
          <span>Impact & Evidence</span>
        </NavMenu.Item>
      )}
      {canEditRegistration && (
        <NavMenu.Item link={EventRoutes.Registration(eventId)}>
          <span>Registration</span>
        </NavMenu.Item>
      )}
      {canViewContactSearch && (
        <NavMenu.Item link={EventRoutes.ContactSearch(eventId)}>
          <span>Advanced Contact Search</span>
        </NavMenu.Item>
      )}
      {canViewEmailTemplates && (
        <NavMenu.Item link={EventRoutes.EmailTemplates(eventId)}>
          <span>Email Templates</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default EventMenuItems;
