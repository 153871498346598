import React from "react";
import { Header } from "semantic-ui-react";
import { Register } from "features/System";
import { SystemPageContent } from "components/pages";

const RegisterPage = () => (
  <SystemPageContent compact title={<Header as="h2">Sign up</Header>}>
    <Register />
  </SystemPageContent>
);

export default RegisterPage;
