import React from "react";
import DeleteIcon from "assets/icons/trash-can.svg";
import FloatingButton from "./FloatingButton";

const FloatingDeleteButton = ({
  onClick,
  large = false,
  className,
  ...props
}) => (
  <FloatingButton
    icon={DeleteIcon}
    className={className}
    onClick={onClick}
    large={large}
    {...props}
  />
);

export default FloatingDeleteButton;
