// function to call on cinnamon SidePanel onClosing which prevents interacting with modals automatically closing the slideout
export const canCloseSidePanel = (e, d) => {
  const nativeEvent = e?.nativeEvent ?? e;
  if (!nativeEvent) return false;
  const path = nativeEvent.composedPath
    ? nativeEvent.composedPath()
    : nativeEvent.path;
  const result = !(path || []).some((x) => {
    return (
      x.className &&
      // detects modals nested on slideout
      (x.className.startsWith("ui page modals") ||
        // detects nested side panel on slideout (via custom class)
        (x.className.includes("cin side-panel") &&
          x.className.includes("nested-side-panel")))
    );
  });
  return result;
};
