import React from "react";
import classNames from "classnames";

/**
 * A container for vertical scrolling content
 */
const ScrollableContent = ({ className, outside, ...props }) => (
  <div
    {...props}
    className={classNames("scrollable-content", { outside }, className)}
  />
);

export default ScrollableContent;
