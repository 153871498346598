import moment from "moment";
import {
  EventFormat,
  EventLocationType,
  EventStatus,
  ParticipationStatus,
} from "constants/enums";
import { ExternalUrls } from "constants/urls";

export const formatEventDates = (startDate, endDate, options = {}) => {
  const dayFormat = options.dayFormat || "D";
  const monthFormat = options.monthFormat || "MMMM";
  const yearFormat = options.yearFormat || "YYYY";
  const dayMonthFormat = `${dayFormat} ${monthFormat}`;
  const dayMonthYearFormat = `${dayMonthFormat} ${yearFormat}`;
  const startSuffix = options.startSuffix || "";
  const endSuffix = options.endSuffix || "";

  if (!startDate) return "";
  const start = moment(startDate);

  if (!endDate) {
    return start.format(dayMonthYearFormat) + startSuffix;
  }

  const end = moment(endDate);

  if (end.isSame(start)) {
    if (!startSuffix && endSuffix) {
      return start.format(dayMonthYearFormat) + " until " + endSuffix;
    }

    return (
      start.format(dayMonthYearFormat) +
      startSuffix +
      (endSuffix ? " - " + endSuffix : "")
    );
  }

  if (start.year() !== end.year()) {
    return (
      start.format(dayMonthYearFormat) +
      startSuffix +
      " - " +
      end.format(dayMonthYearFormat) +
      endSuffix
    );
  } else if (start.month() !== end.month()) {
    return (
      start.format(startSuffix ? dayMonthYearFormat : dayMonthFormat) +
      startSuffix +
      " - " +
      end.format(dayMonthYearFormat) +
      endSuffix
    );
  } else {
    return (
      start.format(startSuffix ? dayMonthYearFormat : dayFormat) +
      startSuffix +
      " - " +
      end.format(dayMonthYearFormat) +
      endSuffix
    );
  }
};

export const formatEventLocation = (
  eventFormat,
  locationType,
  location,
  options = {}
) => {
  const getDescription = () =>
    !options.fullAddress && location?.description
      ? location?.description
      : [
          //No site name for Wiston House
          locationType === EventLocationType.External ? location?.name : null,
          location?.line1,
          location?.line2,
          location?.line3,
          location?.townOrCity,
          location?.countyOrState,
          location?.postcodeOrZip,
          location?.country,
        ]
          .filter((x) => x)
          .join(", ");
  const hideHybrid = !!options?.hideHybrid;

  switch (eventFormat) {
    case EventFormat.InPerson:
      return (
        getDescription() ||
        (locationType === EventLocationType.Internal
          ? "Wiston House"
          : "Global")
      );
    case EventFormat.Virtual:
      return "Virtual";
    case EventFormat.Hybrid:
      return (
        (hideHybrid ? "" : "(Hybrid) ") +
        formatEventLocation(
          EventFormat.InPerson,
          locationType,
          location,
          options
        )
      );
    default:
      return "";
  }
};

export const formatEventThemes = (themes) => {
  if (typeof themes === "string") return themes;
  if (Array.isArray(themes)) return themes.map((t) => t.text ?? t).join(", ");
  return "";
};

export const getEventLocationUrl = (location) => {
  const description = [
    location?.line1,
    location?.line2,
    location?.line3,
    location?.townOrCity,
    location?.countyOrState,
    location?.postcodeOrZip,
    location?.country,
  ]
    .filter((x) => x)
    .join(", ");

  return description ? ExternalUrls.GoogleMapsSearch(description) : null;
};

export const canParticipantViewResources = (
  eventStatus,
  showResourcesUntilUtc
) => {
  return (
    (eventStatus === EventStatus.Active ||
      eventStatus === EventStatus.ClosedDown) &&
    (showResourcesUntilUtc
      ? moment.utc(showResourcesUntilUtc).add(1, "day") >= moment.utc()
      : true)
  );
};

export const canParticipantViewProgramme = (
  canViewResources,
  participationStatus
) => {
  return (
    canViewResources &&
    (participationStatus === ParticipationStatus.Expected ||
      participationStatus === ParticipationStatus.Confirmed)
  );
};

export const formatPotentialYear = (potentialYear) =>
  potentialYear != null ? `${potentialYear}-${(potentialYear % 100) + 1}` : "";

export const canParticipantViewFriendsOfWiltonParkLinkedin = (
  participationStatus
) => {
  return (
    participationStatus === ParticipationStatus.Expected ||
    participationStatus === ParticipationStatus.Confirmed
  );
};
