import moment from "moment";
import { ProgrammeContentType } from "constants/enums";

export const formatProgrammeDate = (date) => {
  const activityDate = moment(date);
  return {
    dayOfWeek: activityDate.format("ddd"),
    dayOfMonth: activityDate.format("DD"),
    month: activityDate.format("MMM"),
  };
};

export const formatProgrammeTime = (time) => {
  if (typeof time !== "string") return "";
  return time.substr(0, 5);
};

export const findUpcomingProgrammeActivity = (activities) => {
  // this may be based on the browser timezone in future
  let found = null;
  let foundDue = null;
  const now = moment();

  activities.forEach((a) => {
    const { date, startTime } = a;
    const hours = startTime.substr(0, 2);
    const minutes = startTime.substr(3, 2);
    const due = moment(date)
      .startOf("day")
      .add(hours, "hours")
      .add(minutes, "minutes");

    if (due > now && (found === null || due < foundDue)) {
      found = a;
      foundDue = due;
    }
  });

  return found;
};

export const flagUpcomingProgrammeLiveSessions = (programme) => {
  const activities = programme.dates?.reduce((acc, d) => {
    return [...acc, ...(d.activity || [])];
  }, []);
  if (!activities || activities.length === 0) return programme;

  const liveSessions = activities.filter(
    (a) => a.contentType === ProgrammeContentType.LiveSession && a.publishedUtc
  );
  const upcoming = findUpcomingProgrammeActivity(liveSessions);
  if (!upcoming) return programme;

  return {
    ...programme,
    dates: programme.dates.map((d) => ({
      ...d,
      activity: (d.activity || []).map((a) => ({
        ...a,
        liveSession: {
          ...a.liveSession,
          upcoming: upcoming.id === a.id,
        },
      })),
    })),
  };
};
