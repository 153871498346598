import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { useRecoveryCode } from "./actions";

const recoveryErrors = {
  925002: "Recovery code not found or already used.",
};

const UseRecoveryCode = ({ token, onDismiss, onRecovered }) => (
  <FormBuilder
    submitAction={useRecoveryCode}
    submitParams={token}
    onSubmitted={onRecovered}
    renderSubmitting={false}
    renderError={false}
    renderForm={(formProps, { submitting, slowSubmitting, error }, events) => (
      <Form {...formProps}>
        <p>
          If you are unable to receive two-factor authentication codes, please
          enter a recovery code to restore access to your account.
        </p>
        <p>
          Each recovery code can only be used once. After entering a successful
          code you will be prompted to setup two-factor authentication again.
        </p>
        <Form.Input
          field="code"
          label="Recovery Code"
          placeholder="Enter recovery code"
          disabled={submitting}
          autoFocus
          required
          fluid
        />
        <p>
          Use <a onClick={onDismiss}>authenticator app code</a>
        </p>
        <Button
          primary
          onClick={events.onSubmit}
          disabled={submitting}
          loading={submitting && slowSubmitting}
          fluid
          size="large"
        >
          Recover Account
        </Button>
        <ErrorMessage
          header="Recovery Code Error"
          error={error}
          overrideMessages={recoveryErrors}
          omitCodes
        />
        <p>
          Return to <Link to={SystemRoutes.Login}>Log In</Link>
        </p>
      </Form>
    )}
  />
);

export default UseRecoveryCode;
