import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { lookupRequest, getLookupData } from "@redriver/cinnamon";
import { IdeaRoutes } from "constants/routes";
import { useRouteMatch } from "react-router";
import { IdeaMenuItems } from "components/menus";
import { ideaDetailsLookup } from "modules/lookups";

const IdeaSubMenu = () => {
  const ideasMatch = useRouteMatch(IdeaRoutes.Idea());
  const ideaId = ideasMatch?.params?.ideaId;

  const ideaData = useSelector((state) =>
    getLookupData(state, ideaDetailsLookup, ideaId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (ideaId) dispatch(lookupRequest(ideaDetailsLookup, ideaId));
  }, []);

  if (!ideaId || ideaData.loading || !ideaData.response) return null;

  return (
    <Menu.Menu className="submenu">
      <IdeaMenuItems ideaId={ideaId} header={ideaData.response.ideaRef} />
    </Menu.Menu>
  );
};

export default IdeaSubMenu;
