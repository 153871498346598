import React from "react";
import { Form, useFormField } from "@redriver/cinnamon";
import moment from "moment";

const YearOfBirthDropdown = ({ minAge = 16, minYear = 1900, ...props }) => {
  const [fieldValue] = useFormField(props.field);

  const maxYear = moment().year() - minAge;
  const yearValues = [];
  for (let i = maxYear; i >= minYear; i--) {
    yearValues.push(i);
  }

  if (fieldValue) {
    const fieldValues = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

    for (let i = 0; i < fieldValues.length; i++) {
      if (!yearValues.includes(fieldValues[i])) {
        yearValues.push(fieldValues[i]);
      }
    }
  }

  return (
    <Form.Dropdown
      placeholder=""
      {...props}
      options={[
        { text: "Prefer not to say", value: 0 },
        ...yearValues
          .sort((a, b) => a >= b)
          .map((x) => ({
            text: x,
            value: x,
          })),
      ]}
    />
  );
};

export default YearOfBirthDropdown;
