import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { ContactRoutes } from "constants/routes";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";

const ContactMenuItems = ({ contactId }) => {
  const canViewContact = usePermission(Targets.Contact, Actions.View);
  return (
    <React.Fragment>
      {canViewContact && (
        <NavMenu.Item link={ContactRoutes.Contact(contactId)} exact>
          <span>Overview</span>
        </NavMenu.Item>
      )}
      <PermissionCheck target={Targets.ContactEvent} action={Actions.View}>
        <NavMenu.Item link={ContactRoutes.Events(contactId)} exact>
          <span>Events</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck target={Targets.ContactNote} action={Actions.View}>
        <NavMenu.Item link={ContactRoutes.Notes(contactId)} exact>
          <span>Notes</span>
        </NavMenu.Item>
      </PermissionCheck>
    </React.Fragment>
  );
};

export default ContactMenuItems;
