import React from "react";

const PostponedIcon = ({
  color = "#0b0c10",
  disabled = false,
  title,
  ...props
}) => {
  const selectedColour = disabled ? "#c9c9d2" : color;
  return (
    <svg
      enable-background="new 0 0 32 32"
      height="512"
      viewBox="0 0 32 32"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <g>
        <path
          d="m1.66998 27.20001h7.38c.04005 2.64997 2.20002 4.79999 4.85004 4.79999 2.65997 0 4.81995-2.15002 4.85999-4.79999h7.38c.58997 0 1.07001-.48004 1.07001-1.06v-4.76001c0-.59003-.48004-1.06-1.07001-1.06-.40002 0-.79999-.17004-1.08002-.44-.71002-.59003-.40002-2.25-.46002-3.04004 3.83002-.51996 6.80005-3.79999 6.80005-7.76001.13-5.66998-6.29004-9.68994-11.30005-7.03998-3.98998-3.03997-9.91998-2.61999-13.50994.84003-2.15002 2.01996-3.38 4.85999-3.38 7.81v8.07996c0 .85004-.69 1.55005-1.54004 1.55005-.58997 0-1.07001.46997-1.07001 1.06v4.76001c0 .57995.48004 1.05999 1.07 1.05999zm12.23004 2.66999c-1.48004 0-2.69-1.19-2.72003-2.66998h5.45001c-.03002 1.47997-1.23999 2.66998-2.72998 2.66998zm15.35999-20.79004c-.23999 7.54004-11.20001 7.55005-11.44 0 .21997-7.54999 11.21002-7.56995 11.44 0z"
          fill={selectedColour}
          stroke={selectedColour}
        />
        <path
          d="m24.42877 11.46729c.99005.98181 2.49188-.51788 1.50873-1.50885.00006.00006-1.32849-1.328-1.32849-1.328v-2.8103c0-.58923-.47723-1.06702-1.06702-1.06702s-1.06702.47778-1.06702 1.06702v3.25214c-.09857.65056 1.34327 1.69096 1.9538 2.39501z"
          fill={selectedColour}
          stroke={selectedColour}
        />
      </g>
    </svg>
  );
};

export default PostponedIcon;
