import FileSaver from "file-saver";

const base64Pattern =
  "^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$";
const isValidBase64String = (data) =>
  typeof data === "string" ? data.match(base64Pattern) : false;

const convertToBlob = async (data) => {
  if (data instanceof Blob) {
    return data;
  } else if (data instanceof Response) {
    return await data.blob();
  } else if (typeof data === "string") {
    return isValidBase64String(data)
      ? new Blob([Buffer.from(data, "base64")])
      : new Blob([data]);
  }
  return null;
};

export const saveFile = async (data, filename) => {
  const blob = await convertToBlob(data);
  if (!blob) {
    console.warn(`Unable to download ${filename} (file type not supported)`);
    return null;
  }
  FileSaver.saveAs(blob, filename);
};

export const openFile = async (data, filename) => {
  const blob = await convertToBlob(data);
  if (!blob) {
    console.warn(`Unable to open ${filename} (file type not supported)`);
    return null;
  }
  const fileURL = URL.createObjectURL(blob);
  window.open(fileURL);
};

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getFileExtension = (fileName) => {
  const match = fileName.match(/(?:\.([^.]+))?$/);
  return match && match.length > 1 ? match[1].toLowerCase() : undefined;
};

export const stripFileExtension = (fileName) => {
  return fileName.replace(/(?:\.([^.]+))?$/, "");
};
