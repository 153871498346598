import React, { useEffect } from "react";
import { updateUserLastAccessed } from "./actions";
import { useDispatch } from "react-redux";

const UpdateUserLastAccessed = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateUserLastAccessed());
  }, []);
  return null;
};

export default UpdateUserLastAccessed;
