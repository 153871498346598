import React from "react";
import { Form } from "@redriver/cinnamon";
import { regionsLookup } from "modules/lookups";

const RegionDropdown = (props) => {
  return (
    <Form.Dropdown
      label="Region"
      lookup={regionsLookup}
      placeholder="Select region"
      search
      {...props}
    />
  );
};

export default RegionDropdown;
