import { ErrorMessage } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  Dimmer,
  Icon,
  Loader,
  Modal as SModal,
} from "semantic-ui-react";

const ThreeButtonModal = ({
  header,
  loading,
  submitting,
  slowLoading,
  slowSubmitting,
  error,
  submitLabel,
  submitIcon = "check",
  cancelLabel,
  cancelIcon = "close",
  submitDisabled,
  cancelDisabled,
  onSubmit,
  otherSubmitIcon,
  otherSubmitLabel,
  onOtherSubmit,
  mainSubmitDisabled,
  otherSubmitDisabled,
  onCancel,
  children,
  otherAs: OtherAs = Button,
  ...otherProps
}) => (
  <SModal {...otherProps} className="three-button-modal">
    <SModal.Header content={<React.Fragment>{header}</React.Fragment>} />
    <SModal.Content>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
          {(slowLoading || slowLoading === undefined) && (
            <Loader size="large" />
          )}
        </Dimmer>
        {children}
        <ErrorMessage error={error} />
      </Dimmer.Dimmable>
    </SModal.Content>
    <SModal.Actions
      style={{ justifyContent: onOtherSubmit ? null : "flex-end" }}
    >
      {onOtherSubmit && (
        <OtherAs
          onClick={(e) => {
            e.stopPropagation();
            const preventAutoClose = onOtherSubmit();

            if (!preventAutoClose) {
              onCancel && onCancel();
            }
          }}
          disabled={
            loading || submitting || submitDisabled || otherSubmitDisabled
          }
          color="red"
        >
          {typeof otherSubmitIcon === "string" ? (
            <Icon name={otherSubmitIcon} />
          ) : (
            otherSubmitIcon
          )}
          {otherSubmitLabel}
        </OtherAs>
      )}

      <div className="main-actions">
        {onCancel && (
          <Button
            negative
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
            disabled={cancelDisabled}
          >
            {typeof cancelIcon === "string" ? (
              <Icon name={cancelIcon} />
            ) : (
              cancelIcon
            )}
            {cancelLabel}
          </Button>
        )}
        {onSubmit && (
          <Button
            positive
            onClick={(e) => {
              e.stopPropagation();
              onSubmit();
            }}
            loading={
              submitting && (slowSubmitting || slowSubmitting === undefined)
            }
            disabled={
              loading || submitting || submitDisabled || mainSubmitDisabled
            }
          >
            {typeof submitIcon === "string" ? (
              <Icon name={submitIcon} />
            ) : (
              submitIcon
            )}
            {submitLabel}
          </Button>
        )}
      </div>
    </SModal.Actions>
  </SModal>
);

ThreeButtonModal.propTypes = {
  header: PropTypes.node,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  slowLoading: PropTypes.bool,
  slowSubmitting: PropTypes.bool,
  error: PropTypes.any,
  submitLabel: PropTypes.string,
  submitIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  otherSubmitLabel: PropTypes.string,
  otherSubmitIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelLabel: PropTypes.string,
  cancelIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  submitDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onOtherSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  otherAs: PropTypes.any,
};

export default ThreeButtonModal;
