import React from "react";
import classNames from "classnames";
import { Grid, Image, Header } from "semantic-ui-react";
import Logo from "assets/logos/logo-blue.svg";

const SystemPageContent = ({
  headerAction,
  footer,
  title,
  compact,
  className,
  children,
}) => (
  <div className={classNames("system-page-content", { compact }, className)}>
    <Grid>
      <Grid.Column widescreen={7} largeScreen={7} tablet={16} mobile={16}>
        <div className="header">
          <Image src={Logo} alt="Wilton Park" className="logo" />
          {headerAction}
        </div>
        <div className="content">
          {typeof title === "string" ? <Header as="h2">{title}</Header> : title}
          {children}
        </div>
        {footer && <div className="footer">{footer}</div>}
      </Grid.Column>
      <Grid.Column
        widescreen={9}
        largeScreen={9}
        className="splash"
        only="computer"
      >
        <div className="upper tagline">
          <div className="image" />
          <div className="caption">World-changing dialogue</div>
        </div>
        <div className="lower tagline">
          <div className="caption">
            We bring together experts and policy-makers to address the most
            complex global challenges.
          </div>
          <div className="image" />
        </div>
      </Grid.Column>
    </Grid>
  </div>
);

export default SystemPageContent;
