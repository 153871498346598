import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { setupApp } from "./setup";
import Shell from "./pages/Shell";

const { store } = setupApp();

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Shell />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));
