import React from "react";
import classNames from "classnames";
import ImageGalleryAdd from "./ImageGalleryAdd";
import ImageGalleryThumb from "./ImageGalleryThumb";
import ImageGalleryPlaceholder from "./ImageGalleryPlaceholder";

const ImageGallery = ({ className, children }) => (
  <div className={classNames("image-gallery", className)}>{children}</div>
);

ImageGallery.Add = ImageGalleryAdd;
ImageGallery.Thumb = ImageGalleryThumb;
ImageGallery.Placeholder = ImageGalleryPlaceholder;

export default ImageGallery;
