import React from "react";
import PropTypes from "prop-types";
import { Transition } from "semantic-ui-react";
import LinkButton from "./LinkButton";
import classNames from "classnames";

const LoadMoreButton = ({
  hasMore,
  loading,
  loadingMore,
  onLoadMore,
  content,
  children,
  className,
  ...props
}) => {
  if (!hasMore || loading) return null;
  return (
    <Transition
      transitionOnMount
      visible={hasMore}
      animation="fade"
      duration={500}
    >
      <LinkButton
        {...props}
        className={classNames("load-more", className)}
        loading={loadingMore}
        disabled={loadingMore}
        onClick={onLoadMore}
      >
        {children || content || "Load More"}
      </LinkButton>
    </Transition>
  );
};

LoadMoreButton.propTypes = {
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
};

export default LoadMoreButton;
