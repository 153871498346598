import React from "react";
import { DataList, DataListItem } from "@redriver/cinnamon";
import classNames from "classnames";

const FieldDataList = ({ className, children, ...props }) => (
  <DataList
    className={classNames("text-field-display-list", className)}
    {...props}
  >
    {children}
  </DataList>
);

FieldDataList.Item = DataListItem;

export default FieldDataList;
