import React from "react";
import classNames from "classnames";
import IconText from "./IconText";
import { Header } from "semantic-ui-react";

const IconStack = ({ className, title, items = [], horizontal, ...props }) => (
  <section
    className={classNames("icon-stack", { horizontal }, className)}
    {...props}
  >
    {title && <Header as="h4">{title}</Header>}
    {items.length == 0 && <p>-</p>}
    {items.map((x, i) => (
      <IconText
        key={i}
        icon={x.icon}
        iconName={x.iconName}
        text={x.text}
        disabled={x.disabled}
        tooltip={x.tooltip}
        children={x.children}
        link={x.link}
        className={x.className}
      />
    ))}
  </section>
);

export default IconStack;
