import React, { useState, useEffect } from "react";
import { Header, Segment, Message, Icon, Popup } from "semantic-ui-react";
import { copyToClipboard } from "modules/helpers";

const RecoveryCodes = ({ codes }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    const timeout = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(timeout);
  }, [copied]);

  const codeString = codes.join("\n");
  return (
    <React.Fragment>
      <Header as="h4" attached="top">
        Recovery codes are used to access your account if you are unable to
        receive two-factor authentication codes.
      </Header>
      <Segment attached className="auth-recovery-codes">
        <ul>
          {codes.map((c) => (
            <li key={c}>{c}</li>
          ))}
        </ul>
      </Segment>
      <Message warning icon attached="bottom">
        <Icon name="exclamation triangle" />
        <Message.Content>
          <Message.Header>Save your codes</Message.Header>
          <p>
            Please{" "}
            <a
              href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                codeString
              )}`}
              download="wilton-park-recovery-codes.txt"
            >
              download
            </a>{" "}
            or{" "}
            <Popup
              trigger={<a onClick={() => copyToClipboard(codeString)}>copy</a>}
              content={
                <span>
                  Copied <Icon name="check" color="green" />
                </span>
              }
              on="click"
              open={copied}
              onOpen={() => setCopied(true)}
              onClose={() => setCopied(false)}
            />{" "}
            your recovery codes before continuing. You will not be able to view
            them again.
          </p>
        </Message.Content>
      </Message>
    </React.Fragment>
  );
};

export default RecoveryCodes;
