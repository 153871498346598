import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { FinanceRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";
import DepartmentsIcon from "assets/icons/departments.svg";
import PoApprovalLevelsIcon from "assets/icons/clipboard.svg";
import PurchaseOrderIcon from "assets/icons/purchase-order.svg";
import EventPoApproversIcon from "assets/icons/eventpoapprovers.svg";

const FinanceTabMenuItems = ({ showIcons }) => {
  const canViewOwnDepartments = usePermission(Targets.Department, Actions.View);
  const canViewAllDepartments = usePermission(
    Targets.AllDepartment,
    Actions.View
  );

  const canViewPurchaseOrderApprovalLevels = usePermission(
    Targets.PurchaseOrderApprovalLevel,
    Actions.View
  );
  const canViewPurchaseOrders = usePermission(
    [
      Targets.PurchaseOrder,
      Targets.DepartmentPurchaseOrder,
      Targets.EventPurchaseOrder,
      Targets.OtherUsersPurchaseOrder,
    ],
    Actions.View,
    true
  );

  const canViewEventPurchaseOrderApprovers = usePermission(
    Targets.EventPurchaseOrderApprover,
    Actions.View
  );

  return (
    <React.Fragment>
      {(canViewOwnDepartments || canViewAllDepartments) && (
        <React.Fragment>
          <NavMenu.Item link={FinanceRoutes.Departments} exact>
            {showIcons && <img src={DepartmentsIcon} />}
            <span>Departments</span>
          </NavMenu.Item>
        </React.Fragment>
      )}
      {canViewPurchaseOrderApprovalLevels && (
        <React.Fragment>
          <NavMenu.Item link={FinanceRoutes.PurchaseOrderApprovalLevels} exact>
            {showIcons && <img src={PoApprovalLevelsIcon} />}
            <span>PO Approval Levels</span>
          </NavMenu.Item>
        </React.Fragment>
      )}
      {canViewPurchaseOrders && (
        <React.Fragment>
          <NavMenu.Item link={FinanceRoutes.PurchaseOrders} exact>
            {showIcons && <img src={PurchaseOrderIcon} />}
            <span>Purchase Orders</span>
          </NavMenu.Item>
        </React.Fragment>
      )}
      {canViewEventPurchaseOrderApprovers && (
        <React.Fragment>
          <NavMenu.Item link={FinanceRoutes.EventPurchaseOrderApprovers} exact>
            {showIcons && <img src={EventPoApproversIcon} />}
            <span>Event PO Approvers</span>
          </NavMenu.Item>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FinanceTabMenuItems;
