import React from "react";
import { Message } from "semantic-ui-react";
import { Form, Content } from "@redriver/cinnamon";

const RECOMMENDED_SENDER_NAME_LENGTH = 25;

const EmailSenderNameWarning = ({ senderName }) => {
  const length = senderName?.length ?? 0;
  if (length <= RECOMMENDED_SENDER_NAME_LENGTH) return null;
  return (
    <Form.Field>
      <Content visible>
        <Message warning visible style={{ display: "block" }}>
          <Message.Header>Email Sender Name</Message.Header>
          <p>
            It is recommended that sender names for email addresses contain no
            more than {RECOMMENDED_SENDER_NAME_LENGTH} characters, to improve
            readability in email clients
          </p>
        </Message>
      </Content>
    </Form.Field>
  );
};

export default EmailSenderNameWarning;
