import React from "react";
import { Segment } from "semantic-ui-react";
import classNames from "classnames";

const FloatingButton = ({
  icon,
  onClick,
  large = false,
  className,
  ...props
}) => {
  const css = classNames("floating-button", large ? "large" : null, className);
  return (
    <Segment className={css} {...props} onClick={onClick}>
      <img src={icon} />
    </Segment>
  );
};

export default FloatingButton;
