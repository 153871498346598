import React from "react";
import classNames from "classnames";
import { Segment } from "semantic-ui-react";

const FilterPanel = ({ padded, className, children }) => (
  <Segment className={classNames("filter-panel", { padded }, className)}>
    {children}
  </Segment>
);

export default FilterPanel;
