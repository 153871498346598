import {
  connectForm,
  Field,
  FormUtils,
  FormValidators,
} from "@redriver/cinnamon";
import React from "react";
import PropTypes from "prop-types";
import { Input as SInput } from "semantic-ui-react";

const FormRegexValidator = () => ({
  propTypes: {
    regexPattern: PropTypes.string,
    regexFlags: PropTypes.string,
    regexError: PropTypes.string,
    expectedFormat: PropTypes.string,
  },
  shouldValidate: (props) => {
    return (
      typeof props.regexPattern === "string" && props.regexPattern.length > 0
    );
  },
  getErrors: (field, value, props) => {
    if (typeof value === "string" && !!value) {
      const fieldRegex = new RegExp(props.regexPattern, props.regexFlags);
      if (!fieldRegex.test(value.toString())) {
        return [
          props.regexError ||
            `${field} must match the format ${
              props.expectedFormat ? props.expectedFormat : ""
            }`,
        ];
      }
    }
    return [];
  },
});

class RegexInput extends React.Component {
  static propTypes = {
    // -------------------
    // field props
    // -------------------

    /**
     * Label text to display alongside the field
     */
    label: PropTypes.node,
    /**
     * Width of the field in approximate number of characters, or a valid CSS width
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether the width of the field should automatically fill all available space
     */
    fluid: PropTypes.bool,
    /**
     * Prefills an input field with a custom string on focus
     */
    focusPrefill: PropTypes.string,

    /**
     * Additional classes for styling
     */
    className: PropTypes.string,

    // -------------------
    // validator props
    // -------------------

    /**
     * Whether this field should be mandatory to completing the form
     */
    required: PropTypes.bool,
    /**
     * Override the default error message for required fields
     */
    requiredError: PropTypes.string,

    /*
     * The regex pattern to use for validation
     */
    regexPattern: PropTypes.string,
    /*
     * The flags to use with the regex for validation
     */
    regexFlags: PropTypes.string,
    /*
     * Override the error displayed on regex validation
     */
    regexError: PropTypes.string,
    /*
     * TAn example of the expected format. used within the regex validation message
     */
    expectedFormat: PropTypes.string,

    // -------------------
    // connectForm props
    // -------------------

    /**
     * Name of this field, and the form data key against which the value will be stored
     */
    field: PropTypes.string.isRequired,
    /**
     * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
     */
    showErrors: PropTypes.bool,
    /**
     * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
     */
    allErrors: PropTypes.bool,
    /**
     * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
     */
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether to disable the field, if not specified then inherits from the parent form or fields
     */
    disabled: PropTypes.bool,
    /**
     * Whether the field should be read-only, if not specified then inherits from the parent form or fields
     * @ignore
     */
    readOnly: PropTypes.bool,
    /**
     * Additional error messages that should be displayed before validator error messages
     */
    customErrors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that will be run everytime the field changes to perform additional validation
     * Resulting errors can be passed to customErrors
     */
    customValidator: PropTypes.func,
    /**
     * List of other field names that should be re-validated when this field changes
     */
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    /**
     * Current value of the field (supplied by the form connection)
     * @ignore
     */
    value: PropTypes.string,
    /**
     * Callback when the field has changed (supplied by the form connection)
     * @ignore
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Array of error messages to display on the field (supplied by the form connection)
     * @ignore
     */
    errors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Props excluding those from the form connection (supplied by the form connection)
     * @ignore
     */
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @ignore
     */
    formState: PropTypes.object,
  };

  static defaultProps = {
    onChange: () => {},
    label: "",
  };

  render() {
    const {
      value,
      onChange,
      focusPrefill,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      label,
      width,
      fluid,
      required,
      passThruProps,
    } = this.props;

    const otherProps = FormUtils.omitProps(
      passThruProps,
      Object.keys(RegexInput.propTypes)
    );

    return (
      <Field
        required={required}
        disabled={disabled}
        width={width}
        fluid={fluid}
        label={label}
        errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
      >
        <SInput
          {...otherProps}
          value={!!value ? value : ""}
          onChange={(ev, { value }) => onChange(value)}
          disabled={disabled}
          onFocus={(e) => {
            if (!value && focusPrefill) {
              onChange(focusPrefill);
              setTimeout(() => {
                e.target?.setSelectionRange(
                  focusPrefill.length,
                  focusPrefill.length
                );
              }, 150);
            }
          }}
          onBlur={() => {
            if (value == focusPrefill) {
              onChange("");
            }
          }}
        />
      </Field>
    );
  }
}

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string"
      ? props.label
      : FormUtils.prettifyField(props.field),
  validators: [FormValidators.requiredField(false), FormRegexValidator()],
})(RegexInput);
