import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { EventRoutes } from "constants/routes";

const ParticipantEventMenuItems = ({
  eventId,
  showProgramme,
  showResources,
}) => (
  <React.Fragment>
    <NavMenu.Item link={EventRoutes.Event(eventId)} exact>
      <span>Event Details</span>
    </NavMenu.Item>
    {showProgramme && (
      <NavMenu.Item link={EventRoutes.Programme(eventId)}>
        <span>Programme</span>
      </NavMenu.Item>
    )}
    {showResources && (
      <NavMenu.Item link={EventRoutes.Resources(eventId)}>
        <span>Resources</span>
      </NavMenu.Item>
    )}
    {showProgramme && (
      <NavMenu.Item link={EventRoutes.LiveSessions(eventId)}>
        <span>Live Sessions</span>
      </NavMenu.Item>
    )}
  </React.Fragment>
);

export default ParticipantEventMenuItems;
