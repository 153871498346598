import React from "react";
import ChevronIcon from "assets/icons/chevron.svg";
import classNames from "classnames";

const Chevron = ({ flipped = false, className, ...props }) => (
  <img
    src={ChevronIcon}
    alt={`Chevron ${flipped ? "up" : "down"}`}
    className={classNames("chevron-icon", { flipped }, className)}
    {...props}
  />
);

export default Chevron;
