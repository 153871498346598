import { apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/REGISTER";

const ActionTypes = {
  RegisterUser: `${NAMESPACE}/REGISTER_USER`,
};

export const registerUser = (data, { recaptcha }) => {
  const request = { ...data, recaptcha };
  return apiPost(ActionTypes.RegisterUser, "users/register", request, {
    auth: false,
    preventErrorNotification: true,
  });
};
