import React, { useEffect, useCallback, useState, useRef } from "react";
import { Form, useFormField } from "@redriver/cinnamon";
import { ColumnGrid } from "components/containers";
import { Button, FormField, Icon, Segment } from "semantic-ui-react";
import { DateTime } from "luxon";
import classNames from "classnames";
import moment from "moment";

const AccommodationAttendanceFields = ({
  eventStartDate,
  eventEndDate,
  asRange,
  asPicker,
  allowToggleAll,
  disabled,
}) => {
  const [selectedDates, setSelectedDates] = useFormField("selectedDates");
  const [arrivalDate, setArrivalDate] = useFormField("arrivalDate");
  const [departureDate, setDepartureDate] = useFormField("departureDate");

  const el = useRef();
  const [availableWidth, setAvailableWidth] = useState(null);
  const changeSize = useCallback(() => {
    setAvailableWidth(el.current?.clientWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", changeSize);
    return () => window.removeEventListener("resize", changeSize);
  }, []);
  useEffect(() => {
    changeSize();
  }, [eventStartDate, eventEndDate]);

  useEffect(() => {
    const eventStartValue = DateTime.fromISO(eventStartDate);
    const eventEndValue = DateTime.fromISO(eventEndDate);

    if (asRange) {
      // get the first and last existing selected dates and make them the range
      if (
        Array.isArray(selectedDates) &&
        selectedDates.length > 0 &&
        !arrivalDate &&
        !departureDate
      ) {
        const orderedDates = [...selectedDates];
        orderedDates.sort((a, b) => {
          const aDate = DateTime.fromISO(a);
          const bDate = DateTime.fromISO(b);
          if (aDate > bDate) {
            return 1;
          } else if (aDate < bDate) {
            return -1;
          }
          return 0;
        });
        const from = orderedDates[0];
        const to =
          orderedDates.length > 1
            ? orderedDates[orderedDates.length - 1]
            : from;
        if (arrivalDate !== from) setArrivalDate(from);
        if (departureDate !== to) setDepartureDate(to);
      }
      if (selectedDates !== null) setSelectedDates(null);
    } else if (asPicker) {
      // use the existing range to set the selected dates (restricted to dates within the event's start/end date)
      if (arrivalDate) {
        let nextDates = [];

        const arriveValue = DateTime.fromISO(arrivalDate);

        const min =
          arriveValue > eventStartValue && arriveValue <= eventEndValue
            ? arriveValue
            : arriveValue <= eventEndValue
            ? eventStartValue
            : null;

        if (min) {
          nextDates.push(min);

          let date = min;
          if (departureDate) {
            const departureValue = DateTime.fromISO(departureDate);
            const max =
              departureValue < eventEndValue ? departureValue : eventEndValue;
            while (date < max) {
              date = date.plus({ days: 1 });
              nextDates.push(date);
            }
          }
        }

        setSelectedDates(nextDates.map((x) => x.toISODate()));
        changeSize();
      }

      if (arrivalDate !== null) setArrivalDate(null);
      if (departureDate !== null) setDepartureDate(null);
    } else {
      if (arrivalDate !== null) setArrivalDate(null);
      if (departureDate !== null) setDepartureDate(null);
      if (selectedDates !== null) setSelectedDates(null);
    }
  }, [asRange, asPicker]);

  const from = eventStartDate ? DateTime.fromISO(eventStartDate) : null;
  const to = from && eventEndDate ? DateTime.fromISO(eventEndDate) : from;
  const all = [];
  if (from) {
    all.push(from);
    while (all[all.length - 1] < to) {
      all.push(all[all.length - 1].plus({ days: 1 }));
    }
  }

  const maxCols = Math.floor((availableWidth || 0) / 150);

  const arrivalMoment = arrivalDate ? moment(arrivalDate) : null;
  const departureMoment = departureDate ? moment(departureDate) : null;

  return (
    <React.Fragment>
      {asRange && (
        <Form.Group widths={2} className="date-range">
          <Form.Date
            local
            field="arrivalDate"
            label="Arrival Date"
            fluid
            startDate={arrivalMoment}
            endDate={departureMoment}
            disabled={disabled}
          />
          <Form.Date
            local
            field="departureDate"
            label="Departure Date"
            fluid
            minDate={arrivalMoment}
            startDate={arrivalMoment}
            endDate={departureMoment}
            disabled={disabled}
          />
          <Form.Trigger
            field="arrivalDate"
            target="departureDate"
            event="onMatch"
            match={({ fields }) =>
              fields.arrivalDate &&
              fields.departureDate &&
              moment(fields.departureDate) < moment(fields.arrivalDate)
            }
            action="change"
            value={({ fields }) =>
              moment(fields.arrivalDate).format("YYYY-MM-DD")
            }
          />
        </Form.Group>
      )}

      {asPicker && (
        <React.Fragment>
          <FormField
            label="Dates Participating"
            style={{ marginBottom: "0.28571429rem" }}
          />
          <ColumnGrid
            cols={maxCols}
            className="attending-available-dates"
            ref={el}
            key={"date-selection" + maxCols}
          >
            {all.map((x) => {
              const date = x.toISODate();
              const isSelected =
                Array.isArray(selectedDates) &&
                selectedDates.some((d) => d == date);

              return (
                <ColumnGrid.Item key={date}>
                  <Segment
                    className={classNames("available-date", {
                      selected: isSelected,
                      disabled,
                    })}
                    onClick={() => {
                      if (disabled) {
                        return;
                      } else if (isSelected) {
                        // remove existing selection
                        setSelectedDates(
                          selectedDates.filter((d) => d != date)
                        );
                      } else {
                        // add new selection
                        setSelectedDates([...(selectedDates || []), date]);
                      }
                    }}
                  >
                    {isSelected ? (
                      <Icon name="check" size="large" />
                    ) : (
                      <Icon name="square outline" size="large" />
                    )}

                    <span style={{ marginLeft: "0.5em" }}>
                      {x.toFormat("dd/MM/yyyy")}
                    </span>
                  </Segment>
                </ColumnGrid.Item>
              );
            })}
            {allowToggleAll && all.length && !disabled && (
              <Button
                onClick={() => {
                  if (
                    selectedDates != null &&
                    selectedDates.length == all.length
                  ) {
                    setSelectedDates([]);
                  } else {
                    setSelectedDates(all.map((d) => d.toISODate()));
                  }
                }}
              >
                {`${
                  selectedDates?.length == all.length ? "Remove" : "Select"
                } All`}
              </Button>
            )}
          </ColumnGrid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AccommodationAttendanceFields;
