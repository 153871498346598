import { useAuthClaims } from "@redriver/cinnamon";
import { UserType } from "constants/enums";

const useUserType = () => {
  const { userType } = useAuthClaims();
  return {
    isInternal: userType == UserType.Internal,
    isExternal: userType == UserType.External,
  };
};

export default useUserType;
