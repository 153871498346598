import { ErrorMessage, useApiAction } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import React from "react";
import { Link } from "react-router-dom";
import { requestLogin } from "./actions";

const AzureAdSignIn = ({ code }) => {
  const [{ success, error, busy }] = useApiAction(requestLogin, {
    lazy: false,
    actionParams: { code },
  });

  // If login successful then user will be redirected momentarily
  // so can use same message as busy
  if (busy || success) {
    return <div>Please wait</div>;
  }

  return (
    <React.Fragment>
      <ErrorMessage error={error ?? "Unknown Error"} />
      <p>
        Return to <Link to={SystemRoutes.Login}>Log In</Link>
      </p>
    </React.Fragment>
  );
};

export default AzureAdSignIn;
