import React from "react";
import { Feed } from "semantic-ui-react";
import AlertListContent from "./AlertListContent";
import AlertListItem from "./AlertListItem";

const AlertList = ({ children }) => (
  <Feed className="alert-list">{children}</Feed>
);

AlertList.Content = AlertListContent;
AlertList.Item = AlertListItem;

export default AlertList;
