import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import {
  Button,
  Input as SInput,
  Label as SLabel,
  Popup,
  Table,
} from "semantic-ui-react";
import { NavMenu } from "@redriver/cinnamon";
import { AppRoutes } from "constants/routes";
import { useUserType } from "components/auth";
import { parse } from "query-string";
import classNames from "classnames";

const GlobalSearchNavItem = () => {
  const { isInternal } = useUserType();
  const history = useHistory();
  const location = useLocation();

  const isOnSearchPage = useRef(
    matchPath(location.pathname, {
      path: AppRoutes.GlobalSearch,
    })
  );

  const params = parse(location.search); // also uri decodes the params
  const [search, setSearch] = useState(params?.q ?? "");

  const onSearch = (override = null) => {
    const term = override != null ? override : search;
    history.push({
      pathname: AppRoutes.GlobalSearch,
      search: "?q=" + encodeURIComponent(term),
    });
  };

  useEffect(() => {
    const next = matchPath(location.pathname, {
      path: AppRoutes.GlobalSearch,
    });
    if (!next && !!isOnSearchPage.current) {
      setSearch("");
    }
    isOnSearchPage.current = next;
  }, [location.pathname]);

  if (isInternal) {
    return (
      <NavMenu.Item className="global-search-nav-item">
        <Popup
          wide
          inverted
          position="bottom center"
          mouseEnterDelay={500}
          trigger={
            <div>
              <SInput
                labelPosition="right"
                placeholder="Search"
                onChange={(ev, data) => setSearch(data.value)}
                value={search}
                onKeyPress={(ev) => {
                  if (ev.key == "Enter") {
                    onSearch();
                  }
                }}
              >
                <input />
                <SLabel
                  basic
                  icon="close"
                  className={classNames({ hide: !search })}
                  onClick={() => {
                    if (search.trim()) {
                      setSearch("");
                      if (isOnSearchPage.current) onSearch("");
                    }
                  }}
                />
                <Button
                  icon="search"
                  onClick={() => {
                    if (search.trim()) {
                      onSearch();
                    }
                  }}
                />
              </SInput>
            </div>
          }
          content={<GlobalSearchAdvancedHelp />}
        />
      </NavMenu.Item>
    );
  }
  return null;
};

const GlobalSearchAdvancedHelp = () => (
  <section className="global-search-advanced-help">
    <p>Advanced Search:</p>
    <Table collapsing compact="very" inverted>
      <Table.Body>
        <Table.Row>
          <Table.Cell>*</Table.Cell>
          <Table.Cell>
            Matches one or more characters
            <br />
            e.g. "J* Smith" to find any Smiths beginning with J
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>?</Table.Cell>
          <Table.Cell>
            Matches any single character
            <br />
            e.g. "J?n Smith" to find Jan or Jon Smith for example, but not John
            Smith
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>|</Table.Cell>
          <Table.Cell>
            OR two or more searches together
            <br />
            e.g. "Climate Change|Technology" to find results containing either
            phrase
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>+</Table.Cell>
          <Table.Cell>
            AND two or more searches together
            <br />
            e.g. "Climate Change+Technology" to find results containing both
            phrases
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>
            Exclude a particular word
            <br />
            e.g. "Climate Change -Technology" to find results containing Climate
            Change but not Technology
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </section>
);

export default GlobalSearchNavItem;
