import React from "react";
import CascadingRadioGroup from "./CascadingRadioGroup";

const YesNoTbcRadioGroup = (props) => {
  const options = [
    { value: true, text: "Yes" },
    { value: false, text: "No" },
    { value: "TBC", text: "TBC" },
  ];
  return <CascadingRadioGroup {...props} options={options} />;
};

export default YesNoTbcRadioGroup;
