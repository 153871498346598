import React from "react";
import { Form } from "@redriver/cinnamon";

const likelihoodOfBecomingAnEventOptions = [25, 50, 75, 85, 100].map((x) => {
  return { value: x, text: x + "%" };
});

const LikelihoodOfBecomingAnEventDropdown = (props) => (
  <Form.Dropdown {...props} options={likelihoodOfBecomingAnEventOptions} />
);

export default LikelihoodOfBecomingAnEventDropdown;
