import React from "react";
import { Route } from "react-router-dom";
import {
  AuthenticatedApp,
  AuthenticatedSwitch,
  PrivateRoute,
  PublicRoute,
  NavigationLogger,
  ResponsiveScreen,
  NotifyAppUpdated,
} from "@redriver/cinnamon";
import { EnvironmentBanner } from "components/banners";
import { AppRoutes, SystemRoutes } from "constants/routes";
import LoginPage from "./system/LoginPage";
import AzureSignInPage from "./system/AzureSignInPage";
import LogoutPage from "./system/LogoutPage";
import RegisterPage from "./system/RegisterPage";
import UserSetupPage from "./system/UserSetupPage";
import EmailVerificationPage from "./system/EmailVerificationPage";
import PasswordForgottenPage from "./system/PasswordForgottenPage";
import PasswordResetPage from "./system/PasswordResetPage";
import AuthenticationPage from "./system/AuthenticationPage";
import PublicRouter from "./public/PublicRouter";
import WiltonPark from "./WiltonPark";
import "styles/index.scss";

/**
 * Top-level app routes only
 * Define sub-path routes inside top-level page components
 */
const Shell = () => (
  <AuthenticatedApp>
    <NotifyAppUpdated checkUrl={process.env.BASE_URL} />
    <NavigationLogger />
    <ResponsiveScreen />
    <EnvironmentBanner />
    <AuthenticatedSwitch>
      {/* system routes */}
      <Route exact path={SystemRoutes.Login} component={LoginPage} />
      <Route exact path={SystemRoutes.Logout} component={LogoutPage} />
      <Route
        exact
        path={SystemRoutes.AzureAdSignIn}
        component={AzureSignInPage}
      />
      <Route exact path={SystemRoutes.Register} component={RegisterPage} />
      <Route exact path={SystemRoutes.SetupUser} component={UserSetupPage} />
      <Route
        exact
        path={SystemRoutes.VerifyEmail}
        component={EmailVerificationPage}
      />
      <PublicRoute
        exact
        path={SystemRoutes.ForgottenPassword}
        component={PasswordForgottenPage}
      />
      <PublicRoute
        exact
        path={SystemRoutes.ResetPassword}
        component={PasswordResetPage}
      />
      <Route path={SystemRoutes.Authenticate} component={AuthenticationPage} />
      <Route path={AppRoutes.Public} component={PublicRouter} />

      {/* app routes */}
      <PrivateRoute component={WiltonPark} redirect={SystemRoutes.Login} />
    </AuthenticatedSwitch>
  </AuthenticatedApp>
);

export default Shell;
