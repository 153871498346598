import React from "react";
import { NotificationStack, PageLoader } from "@redriver/cinnamon";
import { useUserType } from "components/auth";
import WiltonParkMenu from "./WiltonParkMenu";
import WiltonParkFooter from "./WiltonParkFooter";
import { UpdateUserLastAccessed } from "features/System/UpdateUserLastAccessed";
import { UserInactivityCheck } from "features/System/UserInactivityCheck";

const InternalRouter = React.lazy(() =>
  import(/* webpackChunkName: "internal" */ "./internal/InternalRouter")
);
const ExternalRouter = React.lazy(() =>
  import(/* webpackChunkName: "external" */ "./external/ExternalRouter")
);

const RouterLoader = () => (
  <PageLoader animate={2000} style={{ marginTop: "1em" }} />
);

const WiltonPark = () => {
  const { isInternal, isExternal } = useUserType();
  return (
    <React.Fragment>
      <UpdateUserLastAccessed />
      <UserInactivityCheck />
      <WiltonParkMenu />
      {isInternal && (
        <React.Suspense fallback={<RouterLoader />}>
          <InternalRouter />
        </React.Suspense>
      )}
      {isExternal && (
        <React.Suspense fallback={<RouterLoader />}>
          <ExternalRouter />
        </React.Suspense>
      )}
      <NotificationStack />
      <WiltonParkFooter />
    </React.Fragment>
  );
};

export default WiltonPark;
