import React from "react";
import { Image } from "semantic-ui-react";
import { ActionBar } from "@redriver/cinnamon";
import { ExternalUrls } from "constants/urls";
import { ExternalLink } from "components/navigation";
import Facebook from "assets/social/facebook.svg";
import LinkedIn from "assets/social/linkedin.svg";
import Instagram from "assets/social/instagram.svg";
import XTwitter from "assets/social/x.svg";

const WiltonParkFooter = () => (
  <div className="app-footer">
    <ActionBar>
      <ActionBar.Item>
        <ExternalLink href={ExternalUrls.WiltonPark.PrivacyPolicy}>
          Privacy Policy
        </ExternalLink>
      </ActionBar.Item>
      <ActionBar.Item>
        <ExternalLink href={ExternalUrls.WiltonPark.InformationCharter}>
          Information Charter
        </ExternalLink>
      </ActionBar.Item>
      <ActionBar.Item>
        <ExternalLink href={ExternalUrls.WiltonPark.TermsAndConditions}>
          Terms & Conditions
        </ExternalLink>
      </ActionBar.Item>
      <ActionBar.Item>
        <ExternalLink href={ExternalUrls.WiltonPark.Accessibility}>
          Accessibility
        </ExternalLink>
      </ActionBar.Item>
      <ActionBar.Item fluid>
        <ExternalLink href={ExternalUrls.WiltonPark.ContactUs}>
          Contact Us
        </ExternalLink>
      </ActionBar.Item>
      <ActionBar.Item>
        <ExternalLink
          href={ExternalUrls.SocialMedia.Twitter}
          className="social-icon"
        >
          <Image src={XTwitter} alt="X (Twitter)" />
        </ExternalLink>
        <ExternalLink
          href={ExternalUrls.SocialMedia.Facebook}
          className="social-icon"
        >
          <Image src={Facebook} alt="Facebook" />
        </ExternalLink>
        <ExternalLink
          href={ExternalUrls.SocialMedia.LinkedIn}
          className="social-icon"
        >
          <Image src={LinkedIn} alt="Linked In" />
        </ExternalLink>
        <ExternalLink
          href={ExternalUrls.SocialMedia.Instagram}
          className="social-icon"
        >
          <Image src={Instagram} alt="Instagram" />
        </ExternalLink>
      </ActionBar.Item>
    </ActionBar>
  </div>
);

export default WiltonParkFooter;
