import React from "react";

const CharacterLimitLabel = ({ value, limit, children }) => {
  const remaining = Math.max(limit - (value ?? "").length, 0);
  return (
    <React.Fragment>
      <span style={{ float: "right" }}>
        <small>{`${remaining} character${
          remaining === 1 ? "" : "s"
        } remaining`}</small>
      </span>
      <label>{children}</label>
    </React.Fragment>
  );
};

export default CharacterLimitLabel;
