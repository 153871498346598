import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { lookupRequest, getLookupData } from "@redriver/cinnamon";
import { OrganisationRoutes } from "constants/routes";
import { useRouteMatch } from "react-router";
import { OrganisationMenuItems } from "components/menus";
import { organisationDetailsLookup } from "modules/lookups";

const OrganisationSubMenu = () => {
  const match = useRouteMatch(OrganisationRoutes.Organisation());
  const organisationId = match?.params?.organisationId;

  const data = useSelector((state) =>
    getLookupData(state, organisationDetailsLookup, organisationId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (organisationId)
      dispatch(lookupRequest(organisationDetailsLookup, organisationId));
  }, []);

  if (!organisationId || data.loading || !data.response) return null;

  return (
    <Menu.Menu className="submenu">
      <OrganisationMenuItems
        organisationId={organisationId}
        header={data.response.name}
      />
    </Menu.Menu>
  );
};

export default OrganisationSubMenu;
