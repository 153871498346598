import React from "react";
import { PageHeader, useLoggedIn } from "@redriver/cinnamon";
import { AzureAdSignIn } from "features/System";
import { SystemPageContent } from "components/pages";
import { AppRoutes } from "constants/routes";
import { Redirect } from "react-router-dom";
import { useQuery } from "modules/helpers";

const AzureAdSignInPage = () => {
  const loggedIn = useLoggedIn();
  const query = useQuery();
  const code = query.get("code");
  const state = query.get("state");

  if (loggedIn) {
    const nextRoute = state ?? AppRoutes.Root;
    return <Redirect to={nextRoute} />;
  }
  return (
    <SystemPageContent>
      <PageHeader>Logging you in...</PageHeader>
      <AzureAdSignIn code={code} />
    </SystemPageContent>
  );
};

export default AzureAdSignInPage;
