import classNames from "classnames";
import React from "react";
import CascadingRadioGroup from "./CascadingRadioGroup";

const YesNoAnyRadioGroup = ({ anyValue = "", className, ...props }) => {
  const options = [
    { value: true, text: "Yes" },
    { value: false, text: "No" },
    { value: anyValue, text: "Any" },
  ];
  return (
    <CascadingRadioGroup
      className={classNames("yes-no-any-radio", className)}
      {...props}
      options={options}
    />
  );
};

export default YesNoAnyRadioGroup;
