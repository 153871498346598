import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { getUserAccountDetails, updateUserAccountDetails } from "./actions";
import { Button, Popup } from "semantic-ui-react";
import { FormModal } from "components/modals";
import {
  UserTitleDropdown,
  GenderDropdown,
  YearOfBirthDropdown,
} from "components/forms";
import { GenderTypes } from "constants/enums";
import { InfoIcon } from "components/icons";

const EditAccountDetails = ({ onUpdated, as: As = Button, ...props }) => {
  return (
    <ModalBuilder
      withForm
      loadAction={getUserAccountDetails}
      loadTransform={(data) => ({
        details: {
          title: data.title,
          firstName: data.firstName,
          lastName: data.lastName,
          gong: data.gong,
          yearOfBirth: data.yearOfBirth,
          gender: data.gender,
          genderOther: data.genderOther,
        },
      })}
      submitAction={updateUserAccountDetails}
      onSubmitted={onUpdated}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <FormModal {...modalProps} header="Edit My Details" size="small">
          <Form {...formProps}>
            <Form.Object field="details">
              <Form.Group widths="4">
                <UserTitleDropdown label="Title" field="title" fluid />
                <Form.Input
                  field="firstName"
                  label="First Name (Given Name)"
                  fluid
                  required
                />
                <Form.Input
                  field="lastName"
                  label="Last Name (Family Name)"
                  fluid
                  required
                />
                <Form.Input
                  field="gong"
                  label={
                    <label>
                      Letters after your name
                      <Popup
                        inverted
                        trigger={<InfoIcon />}
                        content="Any letters, initials or titles that appear after your name"
                        position="right center"
                      />
                    </label>
                  }
                  fluid
                />
              </Form.Group>
              <Form.Group widths="4">
                <YearOfBirthDropdown
                  field="yearOfBirth"
                  label="Year of Birth"
                  fluid
                />
                <GenderDropdown
                  field="gender"
                  label="Gender"
                  fluid
                  placeholder=" "
                  clearable
                />
                <Form.If
                  condition={({ fields }) => fields.gender == GenderTypes.Other}
                >
                  <Form.Input
                    field="genderOther"
                    label="Please specify gender"
                    fluid
                    required
                  />
                </Form.If>
              </Form.Group>
            </Form.Object>
          </Form>
        </FormModal>
      )}
    />
  );
};

export default EditAccountDetails;
