import React from "react";
import { Message } from "semantic-ui-react";
import { Form, Content } from "@redriver/cinnamon";
import { UserStatus } from "constants/enums";

const EmailChangeWarning = ({ emailChanged, userStatus }) => {
  if (!emailChanged && userStatus !== UserStatus.Inactive) return null;
  return (
    <Form.Field>
      <Content visible>
        <Message warning visible style={{ display: "block" }}>
          <Message.Header>Email address change</Message.Header>
          <p>
            {userStatus === UserStatus.Inactive
              ? "This user is currently inactive, please activate their account to change their email address"
              : "Changing this user's email address will automatically send a verification email to the new address"}
          </p>
        </Message>
      </Content>
    </Form.Field>
  );
};

export default EmailChangeWarning;
