import React from "react";
import classNames from "classnames";
import { HtmlContent } from "components/containers";

const ImageCaption = ({ className, image }) => (
  <React.Fragment>
    {image.accreditationConfirmed && image.accreditation && (
      <div className={classNames("accreditation-overlay", className)}>
        <HtmlContent className="accreditation-content">
          {image.accreditation}
        </HtmlContent>
      </div>
    )}
  </React.Fragment>
);

export default ImageCaption;
