import React from "react";
import { SidePanel } from "@redriver/cinnamon";
import classNames from "classnames";
import { canCloseSidePanel } from "modules/helpers";

const LargeSlideOut = ({
  className,
  children,
  size = "30em",
  onClosing,
  ...props
}) => {
  return (
    <SidePanel
      className={classNames("large-slideout", className)}
      size={size}
      onClosing={(e) => {
        if (onClosing) {
          return onClosing(e);
        }

        return canCloseSidePanel(e);
      }}
      {...props}
    >
      {children}
    </SidePanel>
  );
};

export default LargeSlideOut;
