import React, { useMemo } from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";
import parse, { domToReact } from "html-react-parser";
import { ResourceLink, ExternalLink } from "../navigation";
import { Link } from "react-router-dom";
import { EventProgrammeParticipantsRoutes } from "constants/routes";

const HtmlContent = ({ className, resources, children }) => {
  const content = useMemo(() => {
    const html = DOMPurify.sanitize(children);

    const options = {
      replace: (node) => {
        if (node.name === "a" && node.attribs?.class === "resource-link") {
          if (!Array.isArray(resources)) return;
          const resourceId = node.attribs["data-resource-id"];
          const resource = resources.find(
            (r) => r.id.toLowerCase() === resourceId.toLowerCase()
          );
          if (!resource) return;

          return (
            <span onClick={(e) => e.stopPropagation()}>
              <ResourceLink resource={resource}>
                {domToReact(node.children, options)}
              </ResourceLink>
            </span>
          );
        } else if (
          node.name === "a" &&
          node.attribs?.class === "participant-link"
        ) {
          const participantId = node.attribs["data-participant-id"];
          const eventId = node.attribs["data-event-id"];
          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Link
                to={EventProgrammeParticipantsRoutes.Participant(
                  eventId,
                  participantId
                )}
              >
                {domToReact(node.children, options)}
              </Link>
            </span>
          );
        } else if (node.name === "a") {
          const href = node.attribs["href"];
          if (!href) return;

          return (
            <ExternalLink href={href} onClick={(e) => e.stopPropagation()}>
              {domToReact(node.children, options)}
            </ExternalLink>
          );
        }
      },
    };

    return parse(html, options);
  }, [children]);

  return <div className={classNames("html-content", className)}>{content}</div>;
};

export default HtmlContent;
