import React, { useState, useContext, useEffect } from "react";
import { NavMenu, PageLoader } from "@redriver/cinnamon";
import classNames from "classnames";

const TabsPanelContext = React.createContext([null, () => {}]);

const TabsPanel = ({
  className,
  children,
  defaultTabKey,
  onTabChange,
  loading,
  ...props
}) => {
  const [selectedTabKey, setSelectedTabKey] = useState(defaultTabKey);
  const [tabContent, setTabContent] = useState(null);
  const selectTab = (tabKey, content) => {
    setSelectedTabKey(tabKey);
    setTabContent(content);
    if (onTabChange) onTabChange(tabKey);
  };

  useEffect(() => {
    if (Array.isArray(children)) {
      const tab = children.find((x) => x.props?.tabKey == selectedTabKey);
      if (tab) setTabContent(tab.props.children);
    }
  }, [children]);

  return (
    <TabsPanelContext.Provider
      value={{
        selectedTab: selectedTabKey,
        selectTab,
      }}
    >
      <div className={classNames("tabs-panel", className)}>
        <NavMenu vertical={false} className="tab-nav-menu" {...props}>
          {children}
        </NavMenu>
        <div className="tab-content">
          {!loading ? tabContent : <PageLoader />}
        </div>
      </div>
    </TabsPanelContext.Provider>
  );
};

const TabPanelItem = ({ title, children, tabKey, ...props }) => {
  const { selectedTab, selectTab } = useContext(TabsPanelContext);
  return (
    <NavMenu.Item
      active={selectedTab == tabKey}
      onClick={() => selectTab(tabKey, children)}
      {...props}
    >
      {title}
    </NavMenu.Item>
  );
};
TabsPanel.Item = TabPanelItem;

export default TabsPanel;
