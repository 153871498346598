import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";

const ExistingPendingUserConfirmationModal = ({
  existingEmail,
  onSubmit,
  onCancel,
}) => {
  return (
    <ModalBuilder
      onSubmitted={onSubmit}
      onCancelled={onCancel}
      renderModal={(modalProps, data, state, events) => (
        <Modal.Confirmation
          {...modalProps}
          header={`An Existing Pending User Exists`}
          submitLabel="Confirm Update"
          cancelLabel="Cancel"
          onSubmit={events.onSubmit}
        >
          An existing pending user with email: {existingEmail} has been found.
          Continuing will cause the pending user to be rejected.
        </Modal.Confirmation>
      )}
    />
  );
};

export default ExistingPendingUserConfirmationModal;
