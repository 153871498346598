import React from "react";
import classNames from "classnames";

const ContentLine = ({ className, children, forceBreak, ...props }) => (
  <div
    className={classNames("content-line", { break: forceBreak }, className)}
    {...props}
  >
    {children}
  </div>
);

ContentLine.Item = ({ className, children, ...props }) => (
  <div className={classNames("content-item", className)} {...props}>
    {children}
  </div>
);

ContentLine.FixedItem = ({ className, children, ...props }) => (
  <ContentLine.Item className={classNames("fixed-item", className)} {...props}>
    {children}
  </ContentLine.Item>
);

ContentLine.SingleTextLineItem = ({ className, children, ...props }) => (
  <ContentLine.Item
    className={classNames("single-text-line-item", className)}
    {...props}
  >
    {children}
  </ContentLine.Item>
);

ContentLine.TwoTextLinesItem = ({ className, children, ...props }) => (
  <ContentLine.Item
    className={classNames("two-text-lines-item", className)}
    {...props}
  >
    {children}
  </ContentLine.Item>
);

export default ContentLine;
