import React from "react";
import classNames from "classnames";
import { Form } from "@redriver/cinnamon";

const ClearableInput = ({
  as: As = Form.Input,
  field,
  className,
  ...props
}) => (
  <As
    {...props}
    className={classNames("clearable-input", className)}
    field={field}
    icon={<Form.Clearer field={field} />}
  />
);

export default ClearableInput;
