import React from "react";
import classNames from "classnames";
import { Header } from "semantic-ui-react";

const ContentHeader = ({ title, className, children, as, ...props }) => (
  <div className={classNames("content-header", className)} {...props}>
    {title && <Header as={as ?? "h4"}>{title}</Header>}
    {children}
  </div>
);

export default ContentHeader;
