import React from "react";
import { Image } from "semantic-ui-react";
import AzureSignInImage from "assets/auth/ms_signin_light.svg";
import { SystemRoutes } from "constants/routes";
import queryString from "query-string";

const clientId = process.env.AZURE_AD_CLIENT_ID;
const codeChallenge = process.env.AZURE_AD_CODE_CHALLENGE;
const baseUrl = process.env.BASE_URL;

export const AzureAdSignInButton = ({ nextPath }) => {
  const state = nextPath ? encodeURIComponent(nextPath) : null;

  const query = {
    client_id: clientId,
    response_type: "code",
    redirect_uri: baseUrl.slice(0, -1) + SystemRoutes.AzureAdSignIn,
    response_mode: "query",
    scope: "openid email offline_access",
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    state,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <p>If you are a Wilton Park employee</p>
      <a
        href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${queryString.stringify(
          query
        )}`}
      >
        <Image src={AzureSignInImage} />
      </a>
    </div>
  );
};
