import {
  apiDelete,
  deauthenticate,
  getAuthRefreshToken,
  removeAllNotifications,
  lookupReset,
} from "@redriver/cinnamon";
import { setSeenRegistrationPrompt } from "modules/helpers/registration";

const NAMESPACE = "SYSTEM/LOGOUT";
export const REQUEST_LOGOUT = `${NAMESPACE}/REQUEST`;

export const requestLogout = () => async (dispatch, getState) => {
  // make the logout api call
  const token = getAuthRefreshToken(getState());
  const logoutAction = apiDelete(
    REQUEST_LOGOUT,
    "auth/token/refresh",
    { token },
    { auth: false, preventErrorNotification: true }
  );
  const { success } = await dispatch(logoutAction);

  if (success) {
    // deauthenticate the user session
    dispatch(deauthenticate());
  }

  return success;
};

export const onDeauthenticated = () => (dispatch) => {
  // clear toast notifications
  dispatch(removeAllNotifications());
  // reset all cached lookup data
  dispatch(lookupReset());

  // clear down session items
  setSeenRegistrationPrompt(null);
};
