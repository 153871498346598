import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { validateSetupToken, requestUserSetup } from "./actions";

const UserSetup = ({ token }) => {
  const [tokenValid, setTokenValid] = useState(null);
  const [setupSuccess, setSetupSuccess] = useState(false);

  if (!token) return <p>No setup token supplied</p>;

  if (tokenValid === false) {
    return (
      <div>
        <p>The account verification link is invalid or has expired.</p>
        <p>If you have already verified your account please log in.</p>
        <div style={{ marginTop: 20 }}>
          <Button primary fluid as={Link} to={SystemRoutes.Login} size="large">
            Log in
          </Button>
        </div>
      </div>
    );
  }

  if (setupSuccess) {
    return (
      <div>
        <p>Your account has been setup successfully</p>
        <div style={{ marginTop: 20 }}>
          <Button primary fluid as={Link} to={SystemRoutes.Login} size="large">
            Log in
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      loadAction={validateSetupToken}
      loadParams={token}
      onLoaded={(response) => setTokenValid(!!response && response.ok)}
      submitAction={requestUserSetup}
      submitParams={token}
      onSubmitted={() => setSetupSuccess(true)}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        if (loading && slowLoading) return <p>Validating setup token...</p>;
        if (!tokenValid) return null;
        return (
          <Form {...formProps}>
            <Form.Password
              field="newPassword"
              label="New Password"
              placeholder="Enter new password"
              disabled={submitting && slowSubmitting}
              autoFocus
              required
              minLength={10}
              fluid
            />
            <Form.Password
              field="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm new password"
              disabled={submitting && slowSubmitting}
              required
              confirmField="newPassword"
              fluid
            />
            <Button
              primary
              onClick={events.onSubmit}
              disabled={submitting}
              loading={submitting && slowSubmitting}
              fluid
              size="large"
            >
              Submit
            </Button>
            <ErrorMessage
              error={error}
              overrideMessages={{
                914005: ({ message, code }) => (
                  <div>
                    {message} [{code}]
                  </div>
                ),
              }}
            />
          </Form>
        );
      }}
    />
  );
};

UserSetup.propTypes = {
  token: PropTypes.string.isRequired,
};

export default UserSetup;
