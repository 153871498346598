import React from "react";
import { PurchaseOrderPaymentStatus } from "constants/enums";
import { Popup, Icon } from "semantic-ui-react";

const getIconProps = (paymentStatus) => {
  switch (paymentStatus) {
    case PurchaseOrderPaymentStatus.Invoiced:
      return { name: "file", color: "green" };
    case PurchaseOrderPaymentStatus.PartInvoiced:
      return { name: "file", color: "orange" };
    case PurchaseOrderPaymentStatus.Paid:
      return { name: "gbp", color: "green" };
    case PurchaseOrderPaymentStatus.PartPaid:
      return { name: "gbp", color: "orange" };
    case PurchaseOrderPaymentStatus.InQuery:
      return { name: "warning", color: "orange" };
    default:
      return null;
  }
};

const PurchaseOrderPaymentStatusIcon = ({ purchaseOrder }) => {
  const { paymentStatus, paymentStatusDescription } = purchaseOrder;
  const iconProps = getIconProps(paymentStatus);
  if (!iconProps) return null;
  return (
    <Popup
      inverted
      content={
        <div>
          <strong>Payment Status</strong>
          <br />
          {paymentStatusDescription ?? paymentStatus}
        </div>
      }
      trigger={<Icon {...iconProps} style={{ marginLeft: "0.25em" }} />}
    />
  );
};

export default PurchaseOrderPaymentStatusIcon;
