import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { OrganisationRoutes } from "constants/routes";
import { organisationTypeLookup } from "modules/lookups";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/permissions";
import { usePermission } from "components/auth";

const OrganisationMenuItems = ({ organisationId, header }) => {
  return (
    <React.Fragment>
      {header && <NavMenu.Header>{header}</NavMenu.Header>}
      <NavMenu.Item
        link={OrganisationRoutes.Organisation(organisationId)}
        exact
      >
        <span>Overview</span>
      </NavMenu.Item>
      <PermissionCheck
        target={Targets.OrganisationContact}
        action={Actions.View}
      >
        <NavMenu.Item link={OrganisationRoutes.Contacts(organisationId)} exact>
          <span>Contacts</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck
        target={Targets.OrganisationFunding}
        action={Actions.View}
      >
        <NavMenu.Item
          link={OrganisationRoutes.OrganisationFunding(organisationId)}
          exact
        >
          <span>Funding</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck
        action={Actions.View}
        target={Targets.OrganisationFundingPayment}
      >
        <NavMenu.Item
          link={OrganisationRoutes.OrganisationPayments(organisationId)}
          exact
        >
          <span>Payments</span>
        </NavMenu.Item>
      </PermissionCheck>

      <PermissionCheck target={Targets.OrganisationNote} action={Actions.View}>
        <NavMenu.Item link={OrganisationRoutes.Notes(organisationId)} exact>
          <span>Notes</span>
        </NavMenu.Item>
      </PermissionCheck>
    </React.Fragment>
  );
};

export default OrganisationMenuItems;
