import React from "react";
import classNames from "classnames";
import { Icon } from "semantic-ui-react";
import { UserAlertType } from "constants/enums";

const getIconClasses = (alertType) => ({
  warning: alertType === UserAlertType.PrivacySettingsConfirmation,
  info: alertType === UserAlertType.NewOrganisation,
});

const getIconContent = (alertType) => {
  switch (alertType) {
    case UserAlertType.PrivacySettingsConfirmation:
      return <Icon name="warning sign" />;
    default:
      return <Icon name="info circle" />;
  }
};

const AlertIcon = ({ alertType }) => (
  <div className={classNames("alert-icon", getIconClasses(alertType))}>
    {getIconContent(alertType)}
  </div>
);

export default AlertIcon;
