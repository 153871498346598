import React from "react";
import { Popup } from "semantic-ui-react";

const HtmlToolbar = ({ id, children }) => <div id={id}>{children}</div>;

HtmlToolbar.Group = ({ children }) => (
  <span className="ql-formats">{children}</span>
);

HtmlToolbar.HeadingIcon = () => (
  <Popup
    inverted
    content="Heading"
    trigger={
      <span style={{ display: "inline-block", verticalAlign: "middle" }}>
        <select className="ql-header" defaultValue="">
          <option value="2">Heading 1</option>
          <option value="3">Heading 2</option>
          <option value="4">Heading 3</option>
          <option value="" />
        </select>
      </span>
    }
  />
);

HtmlToolbar.BoldIcon = () => (
  <Popup inverted content="Bold" trigger={<button className="ql-bold" />} />
);

HtmlToolbar.ItalicIcon = () => (
  <Popup inverted content="Italic" trigger={<button className="ql-italic" />} />
);

HtmlToolbar.UnderlineIcon = () => (
  <Popup
    inverted
    content="Underline"
    trigger={<button className="ql-underline" />}
  />
);

HtmlToolbar.StrikethroughIcon = () => (
  <Popup
    inverted
    content="Strikethrough"
    trigger={<button className="ql-strike" />}
  />
);

HtmlToolbar.OrderedListIcon = () => (
  <Popup
    inverted
    content="Numbered List"
    trigger={<button className="ql-list" value="ordered" />}
  />
);

HtmlToolbar.BulletListIcon = () => (
  <Popup
    inverted
    content="Bullet List"
    trigger={<button className="ql-list" value="bullet" />}
  />
);

HtmlToolbar.LinkIcon = () => (
  <Popup
    inverted
    content="Insert Link"
    trigger={<button className="ql-link" />}
  />
);

HtmlToolbar.ResourceIcon = () => (
  <Popup
    inverted
    content="Insert Resource"
    trigger={<button className="ql-resource" />}
  />
);

HtmlToolbar.ResourceLinkIcon = () => (
  <Popup
    inverted
    content="Insert Resource Link"
    trigger={<button className="ql-resource-link" />}
  />
);

HtmlToolbar.ParticipantIcon = () => (
  <Popup
    inverted
    content="Insert Participant"
    trigger={<button className="ql-participant" />}
  />
);

HtmlToolbar.TextColourIcon = () => (
  <Popup
    inverted
    content="Text Colour"
    trigger={
      <span style={{ display: "inline-block", verticalAlign: "middle" }}>
        <select className="ql-color" />
      </span>
    }
  />
);

HtmlToolbar.BackgroundColourIcon = () => (
  <Popup
    inverted
    content="Background Colour"
    trigger={
      <span style={{ display: "inline-block", verticalAlign: "middle" }}>
        <select className="ql-background" />
      </span>
    }
  />
);

HtmlToolbar.AlignLeftIcon = () => (
  <Popup
    inverted
    content="Align Left"
    trigger={<button className="ql-align" value="" />}
  />
);

HtmlToolbar.AlignMiddleIcon = () => (
  <Popup
    inverted
    content="Align Centre"
    trigger={<button className="ql-align" value="center" />}
  />
);

HtmlToolbar.AlignRightIcon = () => (
  <Popup
    inverted
    content="Align Right"
    trigger={<button className="ql-align" value="right" />}
  />
);

HtmlToolbar.ClearFormattingIcon = () => (
  <Popup
    inverted
    content="Clear Formatting"
    trigger={<button className="ql-clean" />}
  />
);

HtmlToolbar.UndoIcon = () => (
  <Popup inverted content="Undo" trigger={<button className="ql-undo" />} />
);

HtmlToolbar.RedoIcon = () => (
  <Popup inverted content="Redo" trigger={<button className="ql-redo" />} />
);

HtmlToolbar.FontSizeIcon = () => (
  <Popup
    inverted
    content="Font Size"
    trigger={
      <span style={{ display: "inline-block", verticalAlign: "middle" }}>
        <select className="ql-size" defaultValue="1em">
          <option value="0.75em">Small</option>
          <option value="1em">Normal</option>
          <option value="1.5em">Large</option>
          <option value="2.5em">Huge</option>
        </select>
      </span>
    }
  />
);

HtmlToolbar.ImageIcon = () => (
  <Popup
    inverted
    content="Image"
    trigger={<button className="ql-image"></button>}
  />
);

export default HtmlToolbar;
