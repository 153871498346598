import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getAuthState } from "@redriver/cinnamon";
import { PasswordReset } from "features/System";
import { SystemRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";

const PasswordResetPage = ({ loggedIn, match }) => {
  if (loggedIn) {
    return <Redirect to={SystemRoutes.ChangePassword} />;
  }

  return (
    <SystemPageContent title="Reset Password">
      <PasswordReset token={match.params.token || ""} />
    </SystemPageContent>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps)(PasswordResetPage);
