import React, { useState } from "react";
import { connect } from "react-redux";
import {
  FormBuilder,
  Form,
  Content,
  ErrorMessage,
  getAuthClaims,
  ModalBuilder,
  Modal,
  useAuthClaims,
} from "@redriver/cinnamon";
import { Button, Message, Grid } from "semantic-ui-react";
import { requestPasswordChange } from "./actions";

const passwordErrors = {
  911003: "Incorrect old password",
};

const PasswordChange = ({ as: As = Button, ...props }) => {
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const { userEmail } = useAuthClaims();

  return (
    <ModalBuilder
      withForm
      submitAction={requestPasswordChange}
      submitParams={userEmail}
      renderTrigger={(openModal) => <As onClick={openModal} {...props} />}
      renderError={false}
      renderModal={(modalProps, formProps, state) => (
        <Modal.Edit {...modalProps} header="Change Password" error={null}>
          <Form {...formProps}>
            <Grid>
              <Grid.Column width={5}>
                <Form.Password
                  field="oldPassword"
                  label="Old Password"
                  disabled={state.submitting}
                  fluid
                  required
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Form.Password
                  field="newPassword"
                  label="New Password"
                  disabled={state.submitting}
                  fluid
                  required
                  minLength={10}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Password
                  field="confirmPassword"
                  label="Confirm New Password"
                  disabled={state.submitting}
                  fluid
                  required
                  confirmField="newPassword"
                />
              </Grid.Column>
            </Grid>
            {state.error && (
              <ErrorMessage
                className="block"
                error={state.error}
                overrideMessages={{
                  911003: "Incorrect existing password",
                  911006: ({ code, data }) => (
                    <div>
                      {data[0]?.message} [{code}]
                    </div>
                  ),
                }}
              />
            )}
          </Form>
        </Modal.Edit>
      )}
    />
  );
};
export default PasswordChange;
