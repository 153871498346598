import React from "react";
import { Form } from "@redriver/cinnamon";
import { programmeSubThemesLookup } from "modules/lookups";
import { Icon, Popup } from "semantic-ui-react";
import { InfoIcon } from "components/icons";

const ProgrammeSubThemeDropdown = ({
  label = "Interests",
  showSelectionInfo,
  ...props
}) => (
  <Form.Dropdown
    label={
      label ? (
        <label>
          {label}
          {showSelectionInfo && (
            <Popup
              inverted
              content="You can select one or more interests by clicking to add to the list."
              trigger={<InfoIcon />}
            />
          )}
        </label>
      ) : undefined
    }
    placeholder=" "
    lookup={programmeSubThemesLookup}
    {...props}
  />
);

export default ProgrammeSubThemeDropdown;
