import React from "react";

const ClosedDownIcon = ({
  color = "#0b0c10",
  disabled = false,
  title,
  ...props
}) => {
  const selectedColour = disabled ? "#c9c9d2" : color;
  return (
    <svg
      enable-background="new 0 0 511.995 511.995"
      height="512"
      viewBox="0 0 511.995 511.995"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <g>
        <path
          d="m255.998 215.618c-52.775 0-95.71 42.936-95.71 95.71s42.935 95.71 95.71 95.71 95.71-42.936 95.71-95.71-42.936-95.71-95.71-95.71zm-15.46 149.578-51.816-51.233 21.093-21.332 31.518 31.163 61.264-56.094 20.259 22.127z"
          fill={selectedColour}
          stroke={selectedColour}
        />
        <path
          d="m365.082 42.153h-61.762l-3.385-10.327c-6.241-19.036-23.898-31.826-43.937-31.826s-37.696 12.79-43.937 31.826l-3.385 10.327h-61.762v49.931h218.168z"
          fill={selectedColour}
          stroke={selectedColour}
        />
        <path
          d="m395.082 66.495v55.589h-278.168v-55.589h-56.916v445.5h392v-445.5zm-139.084 370.543c-69.317 0-125.71-56.394-125.71-125.71s56.393-125.71 125.71-125.71 125.71 56.394 125.71 125.71-56.394 125.71-125.71 125.71z"
          fill={selectedColour}
          stroke={selectedColour}
        />
      </g>
    </svg>
  );
};

export default ClosedDownIcon;
