import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  apiRequests,
  mockSuccess,
} from "@redriver/cinnamon";
import produce from "immer";

const NAMESPACE = "REGISTRATION/REGISTRATION_CONTROL";

export const ActionTypes = {
  LoadRegistration: `${NAMESPACE}/LOAD_REGISTRATION`,
  LoadAnswers: `${NAMESPACE}/LOAD_ANSWERS`,
  SetAnswers: `${NAMESPACE}/SET_ANSWERS`,
  SetRegistrationTemplate: `${NAMESPACE}/SET_REGISTRATION_TEMPLATE`,
  SetSaving: `${NAMESPACE}/SET_SAVING`,
  UpdateStepFormState: `${NAMESPACE}/UPDATE_STEP_FORM_STATE`,
  SaveSection: `${NAMESPACE}/SAVE_SECTION`,
  ClearRegistration: `${NAMESPACE}/CLEAR`,
  SetUnlockedToIndex: `${NAMESPACE}/SET_UNLOCKED_TO_INDEX`,
  SetEditMode: `${NAMESPACE}/SET_EDIT_MODE`,
  AddItem: `${NAMESPACE}/ADD_ITEM`,
  EditItem: `${NAMESPACE}/EDIT_ITEM`,
  DeleteItem: `${NAMESPACE}/DELETE_ITEM`,
  CreateGroup: `${NAMESPACE}/CREATE_GROUP`,
  EditGroup: `${NAMESPACE}/EDIT_GROUP`,
  DeleteGroup: `${NAMESPACE}/DELETE_GROUP`,
  GetRegistrationItemTypes: `${NAMESPACE}/GET_REGISTRATION_ITEM_TYPES`,
  GetRegistrationItemWidths: `${NAMESPACE}/GET_REGISTRATION_ITEM_WIDTHS`,
  RegistrationEventDetails: `${NAMESPACE}/REGISTRATION_EVENT_DETAILS`,
  TravelProviderInfo: `${NAMESPACE}/TRAVEL_PROVIDER_INFO`,
  SetStepValidationState: `${NAMESPACE}/SET_STEP_VALIDATION`,
  SetShowValidationErrors: `${NAMESPACE}/SET_VALIDATION_ERRORS`,
  CalcFullyCompleted: `${NAMESPACE}/CALC_FULLY_COMPLETED`,
  ResetFormDirtyState: `${NAMESPACE}/RESET_FORM_DIRTY_STATE`,
};

export const setAnswers = (answerSet, preserveProgressIndex = false) => ({
  type: ActionTypes.SetAnswers,
  preserveProgressIndex,
  ...answerSet,
});
export const setRegistrationTemplate = (template, unlockAll) => ({
  type: ActionTypes.SetRegistrationTemplate,
  template,
  unlockAll: !!unlockAll,
});
export const setSaving = (saving) => ({ type: ActionTypes.SetSaving, saving });

export const updateStepFormState = ({ stepKey, applyChanges }) => ({
  type: ActionTypes.UpdateStepFormState,
  stepKey,
  applyChanges,
});

export const saveSection =
  (
    data,
    {
      eventId,
      sectionId,
      isFinalSection,
      participantId,
      canProgress,
      saveSectionAction,
    }
  ) =>
  async (dispatch) => {
    const keys = Object.keys(data);
    // all fields in the format "[id]"": value.
    // but some also have extra fields in format "[id]#SomeName" : value and need to be collapsed into the answer for the item so a transform is required
    const transformedData = produce(data, (draft) => {
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        // work out which fields have extra fields (i.e. start with the same id)
        const linkedKeys = keys
          .filter((x) => x.includes(k))
          .sort((a, b) => {
            if (a.length > b.length) {
              return 1;
            } else if (a.length < b.length) {
              return -1;
            }
            return 0;
          });
        if (linkedKeys.length > 1) {
          // found multiple fields for an answer so need to combine them into a single answer
          const [mainKey, ...otherKeys] = linkedKeys;
          const value = otherKeys.reduce(
            (acc, k) => {
              const field = k.replace(mainKey, "").replace("#", "");
              return {
                ...acc,
                [field]: draft[k],
              };
            },
            { value: draft[mainKey] }
          );
          otherKeys.forEach((k) => delete draft[k]); // remove combined fields
          draft[mainKey] = value;
        }
      }
      // fallback to ensure these extra fields are removed
      keys.forEach((k) => {
        if (k.includes("#")) {
          delete draft[k];
        }
      });
    });

    const request = {
      sectionId,
      itemAnswers: transformedData,
      isFinalSection,
      canProgress,
      eventId,
      participantId,
    };

    dispatch(setSaving(true));

    const result = await dispatch(saveSectionAction(request));
    dispatch(calcRegistrationFullyCompleted());
    dispatch(setSaving(false));
    dispatch(resetFormDirtyState());
    return result;
  };

export const clearRegistrationState = {
  type: ActionTypes.ClearRegistration,
};

export const setUnlockedToIndex = (targetIndex) => ({
  type: ActionTypes.SetUnlockedToIndex,
  targetIndex,
});

export const setEditMode = (isInEditMode, hasPublishedRegistration) => ({
  type: ActionTypes.SetEditMode,
  isInEditMode,
  hasPublishedRegistration,
});

export const addItem = (data, { eventId, groupId }) => {
  const request = {
    ...data,
    groupId,
  };
  return apiPost(
    ActionTypes.AddItem,
    `events/${eventId}/registration/item`,
    request
  );
};

export const editItem = (data, { eventId, groupId, itemId }) => {
  const request = {
    ...data,
    groupId,
  };
  return apiPut(
    ActionTypes.EditItem,
    `events/${eventId}/registration/item/${itemId}`,
    request
  );
};

export const deleteItem = ({ eventId, groupId, itemId }) => {
  const request = {
    groupId,
  };
  return apiDelete(
    ActionTypes.DeleteItem,
    `events/${eventId}/registration/item/${itemId}`,
    request
  );
};

export const createGroup = (data, { eventId, sectionId }) => {
  const request = {
    ...data,
    sectionId,
  };
  return apiPost(
    ActionTypes.CreateGroup,
    `events/${eventId}/registration/group`,
    request
  );
};

export const editGroup = (data, { eventId, groupId }) => {
  const request = {
    ...data,
  };
  return apiPut(
    ActionTypes.EditGroup,
    `events/${eventId}/registration/group/${groupId}`,
    request
  );
};

export const deleteGroup = ({ eventId, groupId }) =>
  apiDelete(
    ActionTypes.DeleteGroup,
    `events/${eventId}/registration/group/${groupId}`
  );

export const getRegistrationItemTypes = () =>
  apiGet(ActionTypes.GetRegistrationItemTypes, "registration-item-types", {
    onlyCustomTypes: true,
  });

export const getRegistrationItemWidths = () =>
  apiGet(ActionTypes.GetRegistrationItemWidths, "registration-item-widths");

export const getRegistrationEventDetails = ({ eventId, isExternal }) =>
  apiGet(
    ActionTypes.RegistrationEventDetails,
    isExternal
      ? `participant-events/${eventId}/registration/event-details`
      : `events/${eventId}/registration/event-details`
  );

export const getTravelProviderInfo = () =>
  apiGet(ActionTypes.TravelProviderInfo, `travel-provider-info`);

export const setStepValidation = ({ stepKey, isValid }) => ({
  type: ActionTypes.SetStepValidationState,
  stepKey,
  isValid,
});

export const setShowValidationErrors = (showErrors) => ({
  type: ActionTypes.SetShowValidationErrors,
  showErrors,
});

export const calcRegistrationFullyCompleted = () => ({
  type: ActionTypes.CalcFullyCompleted,
});

export const resetFormDirtyState = () => ({
  type: ActionTypes.ResetFormDirtyState,
});
