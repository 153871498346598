import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { IdeaRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";

const IdeaMenuItems = ({ ideaId, header }) => {
  //const canViewParticipants = usePermission(Targets.Participant, Actions.View);
  const canViewNotes = usePermission(Targets.IdeaNote, Actions.View);

  return (
    <React.Fragment>
      {header && <NavMenu.Header>{header}</NavMenu.Header>}
      <NavMenu.Item link={IdeaRoutes.Idea(ideaId)} exact>
        <span>Overview</span>
      </NavMenu.Item>
      {/* {canViewParticipants && (
        <NavMenu.Item>
          <span>Participants</span>
        </NavMenu.Item>
      )} */}
      {canViewNotes && (
        <NavMenu.Item link={IdeaRoutes.Notes(ideaId)}>
          <span>Notes</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default IdeaMenuItems;
