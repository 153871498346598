import React, { useState, useEffect } from "react";
import { Form, useFormField } from "@redriver/cinnamon";

const HiddenOption = () => <div />;

const CustomValuesField = ({ isValidValue, ...props }) => {
  const [fieldValue, setFieldValue] = useFormField(props.field);

  const appendHiddenOptions = (options, values) => {
    if (!values) return options;
    const newValues = values.filter((v) => options.every((o) => o.value !== v));
    if (newValues.length === 0) return options;
    return options.concat(
      newValues.map((v) => ({ value: v, text: v, as: HiddenOption }))
    );
  };

  const [options, setOptions] = useState(appendHiddenOptions([], fieldValue));

  useEffect(() => {
    let values = fieldValue;
    if (Array.isArray(values) && typeof isValidValue === "function") {
      const filteredValues = values.filter(isValidValue);
      if (filteredValues.length !== values.length) {
        values = filteredValues;
        setFieldValue(values);
      }
    }
    setOptions((prevOptions) => appendHiddenOptions(prevOptions, values));
  }, [fieldValue]);

  return (
    <Form.Dropdown
      {...props}
      search
      allowAdditions
      additionLabel=""
      options={options}
      selectOnBlur
      multiple
      clearable
    />
  );
};

export default CustomValuesField;
