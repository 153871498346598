import React, { useState, useCallback, useRef, useEffect } from "react";
import classNames from "classnames";

/**
 * A container for horizontal table scrolling
 */
const ScrollableTable = ({
  className,
  children,
  showTopScrollbar,
  ...props
}) => {
  const [width, setWidth] = useState("auto");
  const tableRef = useRef(null);
  const outerRef = useRef(null);

  const updateWidth = useCallback(() => {
    const newWidth = tableRef.current?.scrollWidth
      ? tableRef.current.scrollWidth + "px"
      : "auto";
    setWidth(newWidth);
  }, [tableRef]);

  useEffect(() => {
    const onWindowResize = () => updateWidth();
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, [updateWidth]);

  useEffect(() => {
    updateWidth();
  });

  return (
    <div {...props} className={classNames("scrollable-table", className)}>
      {showTopScrollbar && (
        <div
          className="outer-scroll"
          ref={outerRef}
          onScroll={() => {
            tableRef.current.scrollLeft = outerRef.current.scrollLeft;
          }}
        >
          <div className="inner-scroll" style={{ width }}></div>
        </div>
      )}
      <div
        className="table-scroll"
        ref={tableRef}
        onScroll={() => {
          if (!outerRef.current) return;
          outerRef.current.scrollLeft = tableRef.current.scrollLeft;
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollableTable;
