import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { AppRoutes, AccountRoutes } from "constants/routes";
import { Popup, Icon } from "semantic-ui-react";
import { InfoIcon } from "components/icons";

const ProfileMenuItems = () => (
  <React.Fragment>
    <NavMenu.Item
      link={AccountRoutes.Profile}
      icon={
        <Popup
          inverted
          trigger={<InfoIcon />}
          content="Your profile is the information that will be available to other participants at the events that you attend. You can update this information using the pencil edit buttons.
          The visibility of the information within your profile can be set from the Privacy Settings option on the left."
          position="left center"
        />
      }
    >
      <span>My Public Profile</span>
    </NavMenu.Item>
    <NavMenu.Item
      link={AccountRoutes.Privacy}
      icon={
        <Popup
          inverted
          trigger={<InfoIcon />}
          content="Switch on the data you would like other participants to see. Any changes to your details can be made on your My Public Profile option."
          position="left center"
        />
      }
    >
      <span>Privacy Settings</span>
    </NavMenu.Item>
    <NavMenu.Item
      link={AppRoutes.Account}
      exact
      icon={
        <Popup
          inverted
          trigger={<InfoIcon />}
          content="Your account holds information that Wilton Park need in order to manage events, but this information will not appear on your profile to other event participants."
          position="left center"
        />
      }
    >
      <span>My Account</span>
    </NavMenu.Item>
  </React.Fragment>
);

export default ProfileMenuItems;
