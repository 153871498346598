import { Format } from "@redriver/cinnamon";
import { PurchaseOrderStatus } from "constants/enums";
import React from "react";
import { Popup } from "semantic-ui-react";
import InfoIcon from "./InfoIcon";

const PurchaseOrderStatusInfoIcon = ({ purchaseOrder }) => {
  const hasFirstApproval =
    purchaseOrder.approvals?.length > 0 &&
    purchaseOrder.status === PurchaseOrderStatus.Requested;
  const hasSupplyOfGoodsDate =
    purchaseOrder.supplyOfGoodsDate &&
    purchaseOrder.status === PurchaseOrderStatus.GoodsReceived;
  const hasReason =
    purchaseOrder.reason &&
    (purchaseOrder.status === PurchaseOrderStatus.OnHold ||
      purchaseOrder.status === PurchaseOrderStatus.Cancelled ||
      purchaseOrder.status === PurchaseOrderStatus.InQuery);

  return hasFirstApproval || hasSupplyOfGoodsDate || hasReason ? (
    <Popup
      inverted
      content={
        hasFirstApproval ? (
          <div>
            First approval by {purchaseOrder.approvals[0].approverName}.
            Awaiting second approval.
          </div>
        ) : hasSupplyOfGoodsDate ? (
          <div>
            <strong>Date of Supply of Goods</strong>
            <br />
            <Format.Date
              value={purchaseOrder.supplyOfGoodsDate}
              format="DD MMM YYYY"
            />
          </div>
        ) : (
          <div>
            <strong>Reason</strong>
            <br />
            {purchaseOrder.reason}
          </div>
        )
      }
      trigger={<InfoIcon />}
    />
  ) : null;
};

export default PurchaseOrderStatusInfoIcon;
