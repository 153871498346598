import React from "react";
import { Form, useLookup } from "@redriver/cinnamon";
import { programmeThemeLookup } from "modules/lookups";

const ThemeCheckboxGroup = ({ ...props }) => {
  const [{ loading, response }] = useLookup(programmeThemeLookup);

  const items = (response || []).map((x) => ({
    value: x.value,
    text: (
      <label style={{ display: "inline-flex" }}>
        <span
          style={{
            display: "inline-block",
            width: 2,
            height: 18,
            marginRight: 4,
            backgroundColor: x.colour,
          }}
        ></span>
        {x.text}
      </label>
    ),
  }));

  return <Form.CheckboxGroup {...props} options={items} />;
};

export default ThemeCheckboxGroup;
