import { Quill } from "react-quill";
import Undo from "quill/assets/icons/undo.svg?raw";
import Redo from "quill/assets/icons/redo.svg?raw";

const Inline = Quill.import("blots/inline");
const icons = Quill.import("ui/icons");
const Block = Quill.import("blots/block");
const Size = Quill.import("attributors/style/size");
Block.tagName = "DIV";
Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];

class ResourceBlot extends Inline {
  static tagName = "a";
  static blotName = "resource";
  static className = "resource-link";

  static create(value) {
    const node = super.create(value);
    const { id, name } = value;
    node.setAttribute("data-resource-id", id);
    node.setAttribute("data-resource-name", name);
    return node;
  }

  static formats(node) {
    const id = node.getAttribute("data-resource-id");
    const name = node.getAttribute("data-resource-name");
    return { id, name };
  }
}

class ParticipantBlot extends Inline {
  static tagName = "a";
  static blotName = "participant";
  static className = "participant-link";

  static create(value) {
    const node = super.create(value);
    const { id, name, eventId } = value;
    node.setAttribute("data-event-id", eventId);
    node.setAttribute("data-participant-id", id);
    node.setAttribute("data-participant-name", name);
    return node;
  }

  static formats(node) {
    const id = node.getAttribute("data-participant-id");
    const name = node.getAttribute("data-participant-name");
    const eventId = node.getAttribute("data-event-id");
    return { id, name, eventId };
  }
}

// fix for spell checking deleting coloured span tags https://github.com/quilljs/quill/issues/2096
class CustomColor extends Inline {
  static blotName = "customColor";
  static tagName = "FONT";

  constructor(domNode, value) {
    super(domNode, value);

    // Map <font> properties
    domNode.style.color = domNode.color;

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach((child) => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    this.remove();

    return span;
  }
}

export const registerCustomFormats = () => {
  Quill.register(ResourceBlot);
  Quill.register(ParticipantBlot);
  Quill.register(CustomColor, true);
  Quill.register(Block, true);
  Quill.register(Size, true);
  icons["resource"] = icons["video"];
  icons["resource-link"] = icons["video"];
  icons["undo"] = Undo;
  icons["redo"] = Redo;
  icons["participant"] =
    '<i class="user icon" style="color: #444444" aria-hidden="true"></i>';
};
