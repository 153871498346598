import React from "react";

const OrganisationLookupItem = ({ name, townOrCity, country }) => {
  const address = [townOrCity, country].filter((x) => !!x).join(", ");

  return (
    <div className="organisation-lookup-item">
      <div className="organisation-name">{name}</div>
      {(townOrCity || country) && (
        <div className="organisation-location">{address}</div>
      )}
    </div>
  );
};

export default OrganisationLookupItem;
