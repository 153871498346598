import React from "react";
import { EmailVerification } from "features/System";
import { SystemPageContent } from "components/pages";

const EmailVerificationPage = ({ match }) => (
  <SystemPageContent title="Verify Email Address">
    <EmailVerification token={match.params.token || ""} />
  </SystemPageContent>
);

export default EmailVerificationPage;
