import React from "react";
import classNames from "classnames";
import { Icon, Popup } from "semantic-ui-react";
import { ExternalLink } from "components/navigation";

const IconText = ({
  icon,
  text,
  className,
  disabled = false,
  tooltip,
  children,
  link,
  iconName,
  ...props
}) => {
  const css = classNames("icon-text", { disabled }, className);
  const contents = (
    <React.Fragment>
      {icon ? <img src={icon} /> : iconName ? <Icon name={iconName} /> : null}
      <span className="icon-text-value">{text}</span>
      {children}
    </React.Fragment>
  );

  const iconText = link ? (
    <ExternalLink className={css} href={link} target="_blank" {...props}>
      {contents}
    </ExternalLink>
  ) : (
    <div className={css} {...props}>
      {contents}
    </div>
  );

  if (!tooltip) return iconText;

  return <Popup inverted content={tooltip} trigger={iconText} />;
};

export default IconText;
