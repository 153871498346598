import React, { useContext, useEffect } from "react";
import { Form, FormContext, useLookup } from "@redriver/cinnamon";
import { participantTypeLookup } from "modules/lookups";

const ParticipantTypeDropdown = ({ defaultOptionsFilter, ...props }) => {
  const formContext = useContext(FormContext);
  const [{ response }] = useLookup(participantTypeLookup);

  useEffect(() => {
    if (typeof defaultOptionsFilter !== "function") return;
    const currentValue = formContext.value[props.field];
    if (currentValue) return;

    const defaultOptions = response?.filter(defaultOptionsFilter);
    if (!defaultOptions || defaultOptions.length === 0) return;

    const defaultValues = defaultOptions.map((x) => x.value);

    const applyChanges = (prev) => ({
      ...prev,
      [props.field]: props.multiple
        ? [...(prev[props.field] ?? []), ...defaultValues]
        : defaultValues[0],
    });

    formContext.onChange(props.field, defaultValues, applyChanges);
  }, [response]);

  return <Form.Dropdown {...props} lookup={participantTypeLookup} />;
};

export default ParticipantTypeDropdown;
