import React from "react";
import { Form } from "@redriver/cinnamon";

const CascadingCheckboxGroup = (props) => (
  <div className="cascading-radio-group field">
    <Form.CheckboxGroup {...props} />
  </div>
);

export default CascadingCheckboxGroup;
