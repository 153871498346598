import React from "react";
import { Form } from "@redriver/cinnamon";

const ClearableFieldLabel = ({ content, children, ...props }) => (
  <label
    onClick={(e) => {
      e.preventDefault();
    }}
  >
    {content || children} <Form.Clearer {...props} />
  </label>
);

export default ClearableFieldLabel;
