import { authenticate, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/AUTHENTICATOR_CHECK";

const ActionTypes = {
  RequestAuthentication: `${NAMESPACE}/REQUEST_AUTHENTICATION`,
  UseRecoveryCode: `${NAMESPACE}/USE_RECOVERY_CODE`,
};

export const requestAuthentication = ({ totp }, token) => async (dispatch) => {
  const authAction = apiPost(
    ActionTypes.RequestAuthentication,
    `auth/mfa/totp/auth`,
    { token, totp },
    { auth: false, preventErrorNotification: true }
  );

  const result = await dispatch(authAction);

  const { success, response } = result;
  if (success) {
    // authenticate the user session
    const { access, refresh } = response.tokens;
    dispatch(authenticate(access, refresh));
  }

  return result;
};

export const useRecoveryCode = ({ code }, token) =>
  apiPost(
    ActionTypes.UseRecoveryCode,
    `auth/mfa/recovery/code`,
    { token, code },
    { auth: false, preventErrorNotification: true }
  );
