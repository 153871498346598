export const getSteps = (state) => getStepsHelper(state.registration.template);
export const getSectionInfo = (state, key) =>
  state.registration.template?.sections.find((s) => s.id == key);
export const getSectionFormData = (state, key) =>
  state.registration.stepsFormState[key] || {};
export const getAllSectionsFormData = (state) =>
  state.registration.stepsFormState;
export const getUnlockedToIndex = (state) => state.registration.unlockedToIndex;
export const getSaving = (state) => state.registration.saving;
export const getIsEditMode = (state) => state.registration.isInEditMode;
export const getHasPublishedRegistration = (state) =>
  state.registration.hasPublishedRegistration;
export const getAllQuestions = (state) =>
  getTemplateQuestionsHelper(state.registration.template);
export const getTemplateVenueInfo = (state) =>
  state.registration.template?.venueInfo || {};
export const getSectionFormValidationState = (state, key) =>
  !!state.registration.stepsValidationState[key];
export const getShowValidationErrors = (state) =>
  state.registration.showValidationErrors;
export const registrationFullyComplete = (state) =>
  !!state.registration.fullyComplete;
export const currentRegistrationStepFormIsDirty = (state) =>
  state.registration.currentStepFormIsDirty;
export const getParticipantIsVirtualAttendance = (state) =>
  !!state.registration.isVirtualAttendance;

// helpers
export const getTemplateQuestionsHelper = (template) => {
  return template?.sections.reduce((acc, section) => {
    const next = [...acc];
    section.groups.forEach((group) => {
      group.items.forEach((item) => {
        next.push({
          id: item.id,
          type: item.type,
          label: item.label,
          sectionId: section.id,
          groupId: group.id,
          mandatoryPriorEvent: item.mandatoryPriorEvent,
          hidden: item.hidden,
        });
      });
    });
    return next;
  }, []);
};
export const getStepsHelper = (template) => {
  return (
    template?.sections.map((s) => ({
      key: s.id,
      title: s.name,
    })) || []
  );
};
