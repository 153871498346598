import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "PUBLIC/VIEW_PUBLIC_RESOURCE";

const ActionTypes = {
  GetResource: `${NAMESPACE}/GET_RESOURCE`,
};

export const getResource = (token) =>
  apiGet(
    ActionTypes.GetResource,
    `public/resources/${token}`,
    {},
    {
      auth: false,
      preventErrorNotification: true,
    }
  );
