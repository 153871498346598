import React from "react";
import { Form, useFormField } from "@redriver/cinnamon";
import CountryDropdown from "./CountryDropdown";
import RegionDropdown from "./RegionDropdown";

const CountryRegionSelectionGroup = ({
  countryField = "countryId",
  regionField = "regionId",
  onChange,
}) => {
  const [selectedRegionId] = useFormField(regionField);

  return (
    <React.Fragment>
      <CountryDropdown
        field={countryField}
        textField="countryName"
        optionField="countryOption"
        lookupParams={{
          [regionField]: selectedRegionId,
        }}
        fluid
        clearable
      />

      <RegionDropdown
        field={regionField}
        textField="regionName"
        fluid
        clearable
      />

      <Form.Trigger
        field={countryField}
        action={(formState) => {
          if (
            formState.fields.countryOption &&
            formState.fields[countryField]
          ) {
            const targetValue =
              formState.fields.countryOption[regionField] || null;
            const targetName =
              formState.fields.countryOption.regionName || null;
            onChange(regionField, targetValue, (v) => ({
              location: {
                ...v.location,
                [regionField]: targetValue,
                regionName: targetName,
              },
            }));
          }
        }}
      />

      <Form.Trigger
        field={countryField}
        event="onClear"
        action={() => {
          onChange([regionField], null, (v) => ({
            location: {
              ...v.location,
              [regionField]: null,
              regionName: null,
            },
          }));
        }}
      />

      <Form.Trigger
        field={regionField}
        action={(formState) => {
          if (
            formState.fields.countryOption &&
            formState.fields[countryField] &&
            formState.fields.countryOption[regionField] !=
              formState.fields[regionField]
          ) {
            onChange([countryField], null, (v) => ({
              location: {
                ...v.location,
                [countryField]: null,
                countryName: null,
                countryOption: null,
              },
            }));
          }
        }}
      />
    </React.Fragment>
  );
};

export default CountryRegionSelectionGroup;
