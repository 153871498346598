import React from "react";
import classNames from "classnames";
import { Segment } from "semantic-ui-react";

const ContentPanel = ({
  leftContent,
  rightContent,
  className,
  children,
  collapseEarly = false,
  noCollapse = false,
  as: As = Segment,
  ...props
}) => (
  <As
    className={classNames(
      "content-panel",
      {
        split: leftContent || rightContent,
        "collapse-early": collapseEarly,
        "no-collapse": noCollapse,
      },
      className
    )}
    {...props}
  >
    {leftContent || rightContent ? (
      <React.Fragment>
        <div className="left-content">{leftContent}</div>
        <div className="right-content">{rightContent}</div>
      </React.Fragment>
    ) : (
      children
    )}
  </As>
);

export default ContentPanel;
