import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, ListBuilder, Form } from "@redriver/cinnamon";
import { LoadMoreButton } from "components/buttons";
import { FormModal } from "components/modals";
import { SelectionTable } from "components/tables";
import { CountryDropdown } from "components/forms";

const OrganisationAdvancedSearch = ({
  as: As = Button,
  action,
  params = null,
  onSelect,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      renderTrigger={(showModal) => (
        <As className="advanced-search-button" onClick={showModal} {...props} />
      )}
      renderModal={(modalProps, formProps, state, events) => (
        <FormModal
          {...modalProps}
          header="Organisation Advanced Search"
          size="small"
          cancelIcon={null}
          onSubmit={undefined}
        >
          <ListBuilder
            withTable
            loadAction={action}
            loadParams={{ params, filters: formProps.value }}
            dataTransform={(data) => data.results}
            totalTransform={(data) => data.totalResults}
            renderList={(tableProps, listState, listEvents) => (
              <Form {...formProps}>
                <Form.Input
                  label="Name or Alternative Name"
                  field="search"
                  placeholder=" "
                  icon="search"
                  fluid
                  debounce={250}
                />
                <Form.Group widths="2">
                  <CountryDropdown
                    field="countryIds"
                    textField="countryName"
                    optionField="countryOption"
                    fluid
                    clearable
                    multiple
                    debounce={250}
                  />
                  <Form.Input
                    label="Town/City"
                    field="townOrCity"
                    placeholder=" "
                    fluid
                    debounce={250}
                  />
                </Form.Group>
                <SelectionTable
                  {...tableProps}
                  dataKey="value"
                  emptyMessage="No organisations found"
                  footer={
                    <LoadMoreButton
                      hasMore={listState.hasMore}
                      loading={listState.loading && listState.slowLoading}
                      loadingMore={listState.loadingMore}
                      onLoadMore={listEvents.onLoadMore}
                      content="View More"
                    />
                  }
                >
                  <SelectionTable.Column
                    title="Name"
                    field="name"
                    width="25%"
                  />
                  <SelectionTable.Column
                    title="Alternative Names"
                    field="alternativeNames"
                    render={(item) => item.alternativeNames.join(", ")}
                    width="25%"
                  />
                  <SelectionTable.Column
                    title="Town/City"
                    field="townOrCity"
                    width="20%"
                  />
                  <SelectionTable.Column
                    title="Country"
                    field="country"
                    width="20%"
                  />
                  <SelectionTable.SelectColumn
                    width="10%"
                    onSelect={(item) => {
                      onSelect(item);
                      events.onSubmit();
                    }}
                  />
                </SelectionTable>
              </Form>
            )}
          />
        </FormModal>
      )}
    />
  );
};

export default OrganisationAdvancedSearch;
