import { default as CalendarReducer } from "features/Calendar/reducers";
import { default as PipelineReducer } from "features/Pipeline/reducers";
import { default as RegistrationReducer } from "features/Registration/RegistrationControl/reducers";

export default {
  // add reducers that should be initially loaded by the store here
  calendar: CalendarReducer,
  pipeline: PipelineReducer,
  registration: RegistrationReducer,
};
