import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { AdminRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { PermissionCheck } from "components/auth";
import UsersIcon from "assets/icons/users.svg";
import RolesIcon from "assets/icons/roles.svg";
import Lookups from "assets/icons/lookups.svg";
import SitesIcon from "assets/icons/sites.svg";
import EmailTemplatesIcon from "assets/icons/email-templates.svg";
import IntegrationsIcon from "assets/icons/integrations.svg";

const AdminTabsMenuItems = ({ showIcons }) => {
  return (
    <React.Fragment>
      <PermissionCheck
        action={Actions.View}
        target={[Targets.User, Targets.PendingUser]}
        any
      >
        <NavMenu.Item link={AdminRoutes.Users} exact>
          {showIcons && <img src={UsersIcon} />}
          <span>Users</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.Role}>
        <NavMenu.Item link={AdminRoutes.Roles}>
          {showIcons && <img src={RolesIcon} />}
          <span>Roles</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck
        action={Actions.View}
        target={[Targets.ParticipantType, Targets.ProgrammeTheme]}
        any
      >
        <NavMenu.Item link={AdminRoutes.Lookups}>
          {showIcons && <img src={Lookups} />}
          <span>Lookups</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.Site}>
        <NavMenu.Item link={AdminRoutes.Sites}>
          {showIcons && <img src={SitesIcon} />}
          <span>Sites</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.EmailTemplate}>
        <NavMenu.Item link={AdminRoutes.EmailTemplates}>
          {showIcons && <img src={EmailTemplatesIcon} />}
          <span>Email Templates</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.Edit} target={Targets.SageIntegration}>
        <NavMenu.Item link={AdminRoutes.Integrations}>
          {showIcons && <img src={IntegrationsIcon} />}
          <span>Integrations</span>
        </NavMenu.Item>
      </PermissionCheck>
    </React.Fragment>
  );
};

export default AdminTabsMenuItems;
