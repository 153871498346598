import React from "react";
import { Form, useFormField } from "@redriver/cinnamon";
import { formatPotentialYear, getCurrentFinancialYear } from "modules/helpers";

const PotentialYearDropdown = ({ years, ...props }) => {
  const [fieldValue] = useFormField(props.field);

  const currentYear = getCurrentFinancialYear();

  const yearValues = Array.isArray(years) ? [...years] : [];
  if (yearValues.length === 0) {
    for (let i = currentYear; i <= currentYear + 3; i++) {
      yearValues.push(i);
    }
  }

  if (fieldValue) {
    const fieldValues = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

    for (let i = 0; i < fieldValues.length; i++) {
      if (!yearValues.includes(fieldValues[i])) {
        yearValues.push(fieldValues[i]);
      }
    }
  }

  return (
    <Form.Dropdown
      {...props}
      options={yearValues.sort().map((x) => {
        return { text: formatPotentialYear(x), value: x };
      })}
    />
  );
};

export default PotentialYearDropdown;
