import React from "react";
import { UserSetup } from "features/System";
import { SystemPageContent } from "components/pages";

const UserSetupPage = ({ match }) => (
  <SystemPageContent
    title="User Setup"
    footer={
      <p>
        For help and support please contact{" "}
        <a href="mailto:enquiries@wiltonpark.org.uk">
          enquiries@wiltonpark.org.uk
        </a>{" "}
        providing the name of the event that you are invited to.
      </p>
    }
  >
    <UserSetup token={match.params.token || ""} />
  </SystemPageContent>
);

export default UserSetupPage;
