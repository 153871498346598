import React from "react";
import { Button } from "semantic-ui-react";
import { TriggerBuilder } from "@redriver/cinnamon";
import { saveFile } from "modules/helpers";

const DownloadTrigger = ({
  action,
  params,
  filename,
  as: As = Button,
  ...props
}) => (
  <TriggerBuilder
    submitAction={action}
    submitParams={params}
    onSubmitted={(response) => saveFile(response, filename)}
    renderTrigger={(fireTrigger, state) => (
      <As
        {...props}
        onClick={fireTrigger}
        loading={state.submitting}
        disabled={state.submitting}
      />
    )}
  />
);

export default DownloadTrigger;
