import React from "react";
import { Form } from "@redriver/cinnamon";
import { UserStatus, enumOptionsArray } from "constants/enums";

const userStatusOptions = enumOptionsArray(UserStatus);

const UserStatusDropdown = (props) => (
  <Form.Dropdown {...props} options={userStatusOptions} />
);

export default UserStatusDropdown;
