import React from "react";
import { ContentBuilder, refreshJwt } from "@redriver/cinnamon";
import { getUserAccountDetails } from "./actions";
import { Header, Icon, Popup } from "semantic-ui-react";
import { IconStack } from "components/icons";
import { PasswordChange } from "features/System";
import { FloatingEditButton } from "components/buttons";
import { FieldDataList, ContentPanel } from "components/containers";
import { GenderTypes } from "constants/enums";
import TickIcon from "assets/icons/tick.svg";
import { useDispatch } from "react-redux";
import EditAccountPreferences from "./EditAccountPreferences";
import EditAccountDetails from "./EditAccountDetails";
import { MessageBox } from "components/containers";
import InformationIcon from "assets/icons/information.svg";
import { ExternalLink } from "components/navigation";
import { ExternalUrls } from "constants/urls";
import InfoIcon from "components/icons/InfoIcon";
import { WiltonPark } from "constants/contacts";

const UserDetails = () => {
  const dispatch = useDispatch();

  return (
    <ContentBuilder
      loadAction={getUserAccountDetails}
      renderContent={(data, state, events) => {
        const preferenceItems = [
          {
            className: "preference-text",
            text: data.salutation
              ? `I prefer to be addressed as '${data.salutation}' in correspondence`
              : "I have no preferred way to be addressed in correspondence",
          },
          {
            className: "preference-text",
            text:
              data.preferredContactMethod &&
              data.preferredContactMethod != "Any"
                ? `${data.preferredContactMethod} is my preferred contact method`
                : data.preferredContactMethod === null
                ? "I have no preferred contact method"
                : "I am happy to be contacted by any method",
          },
          {
            text: (
              <React.Fragment>
                {data.sendUpdatesAndInvites == null
                  ? "I have not specified if I can be contacted by Wilton Park for updates and invites"
                  : `I ${
                      data.sendUpdatesAndInvites ? "consent" : "do not consent"
                    } to be contacted by Wilton Park for updates and invites `}
                <Popup
                  wide
                  content={
                    <React.Fragment>
                      We will only use your personal information as set out in
                      our{" "}
                      <ExternalLink
                        href={ExternalUrls.WiltonPark.PrivacyPolicy}
                      >
                        Privacy Notice
                      </ExternalLink>
                      , or you can request a copy of it by contacting us
                      directly. If you wish to make any requests relating to the
                      manner in which Wilton Park uses your personal
                      information, please contact our{" "}
                      <a href={`mailto:${WiltonPark.DataProtectionOfficer}`}>
                        {WiltonPark.DataProtectionOfficer}
                      </a>{" "}
                      or the contact details set out in our Privacy Notice.
                    </React.Fragment>
                  }
                  position="top center"
                  inverted
                  hoverable
                  trigger={
                    <Icon
                      name="info circle"
                      style={{
                        display: "inline",
                        marginLeft: "0.5em",
                      }}
                    />
                  }
                />
              </React.Fragment>
            ),
            icon: data.sendUpdatesAndInvites == true ? TickIcon : null,
            iconName:
              data.sendUpdatesAndInvites == false
                ? "close"
                : data.sendUpdatesAndInvites == null
                ? "question"
                : null,
            className:
              data.sendUpdatesAndInvites != true
                ? "preference-not-selected"
                : null,
          },
        ];

        return (
          <React.Fragment>
            <MessageBox title="Information" icon={InformationIcon}>
              <p>
                Your account holds information that Wilton Park need in order to
                manage events, but this information will not appear on your
                profile to other event participants.
              </p>
            </MessageBox>
            <ContentPanel
              as="div"
              className="user-account-details"
              collapseEarly
              leftContent={
                <section>
                  <Header as="h4">
                    My Account Details{" "}
                    <EditAccountDetails
                      as={FloatingEditButton}
                      onUpdated={() => {
                        events.onRefresh();
                        // claims may have changed so update jwt
                        dispatch(refreshJwt(true));
                      }}
                    />
                  </Header>

                  <FieldDataList>
                    <FieldDataList.Item title="Name">
                      {`${data.title || ""} ${data.firstName} ${
                        data.lastName
                      } ${data.gong || ""}`.trim()}
                    </FieldDataList.Item>
                    <FieldDataList.Item
                      title={
                        <span>
                          Login Email Address
                          <Popup
                            content={
                              'This is your primary email address used for correspondence and to log in to the portal. If you would like to add any additional email addresses, these can be added on Your Public Profile, under "Contact Information". Please contact us if you need to change your primary/login email address.'
                            }
                            position="top center"
                            inverted
                            trigger={<InfoIcon />}
                          />
                        </span>
                      }
                    >
                      {data.email}
                    </FieldDataList.Item>
                    <FieldDataList.Item
                      title={
                        <span>
                          Year of Birth
                          <Popup
                            content="This information is used for statistical reporting and will not be shown to other event participants."
                            position="top center"
                            inverted
                            trigger={<InfoIcon />}
                          />
                        </span>
                      }
                    >
                      {data.yearOfBirth === 0
                        ? "Prefer not to say"
                        : !!data.yearOfBirth
                        ? data.yearOfBirth
                        : "-"}
                    </FieldDataList.Item>
                    <FieldDataList.Item title="Gender">
                      {data.gender
                        ? `${data.genderDescription} ${
                            data.gender == GenderTypes.Other && data.genderOther
                              ? "(" + data.genderOther + ")"
                              : ""
                          }`
                        : "-"}
                    </FieldDataList.Item>
                  </FieldDataList>
                </section>
              }
              rightContent={
                <section className="preferences-info">
                  <Header as="h4">
                    My Preferences
                    <EditAccountPreferences
                      as={FloatingEditButton}
                      onUpdated={events.onRefresh}
                    />
                  </Header>
                  <IconStack items={preferenceItems} style={{ flexGrow: 1 }} />
                  <div style={{ marginTop: "2em" }}>
                    <PasswordChange primary>Change Password</PasswordChange>
                  </div>
                </section>
              }
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export default UserDetails;
