import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { AppRoutes, EventRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";
import EventsIcon from "assets/icons/events.svg";
import ConceptsIcon from "assets/icons/concepts.svg";
import IdeasIcon from "assets/icons/ideas.svg";
import SeriesIcon from "assets/icons/series.svg";
import PipelineIcon from "assets/icons/strategy.svg";

const EventsTabMenuItems = ({
  showIcons,
  eventSubMenu,
  ideaSubMenu,
  seriesSubMenu,
  conceptSubMenu,
}) => {
  const canViewEvents = usePermission(Targets.Event, Actions.View);
  const canViewIdeas = usePermission(Targets.Idea, Actions.View);
  const canViewConcepts = usePermission(Targets.Concept, Actions.View);
  const canViewSeries = usePermission(Targets.Series, Actions.View);
  const canViewPipeline = usePermission(Targets.Pipeline, Actions.View);

  return (
    <React.Fragment>
      {canViewEvents && (
        <React.Fragment>
          <NavMenu.Item link={AppRoutes.Events} exact>
            {showIcons && <img src={EventsIcon} />}
            <span>Events</span>
          </NavMenu.Item>
          {eventSubMenu}
        </React.Fragment>
      )}
      {canViewConcepts && (
        <React.Fragment>
          <NavMenu.Item link={EventRoutes.Concepts} exact>
            {showIcons && <img src={ConceptsIcon} />}
            <span>Concepts</span>
          </NavMenu.Item>
          {conceptSubMenu}
        </React.Fragment>
      )}
      {canViewIdeas && (
        <React.Fragment>
          <NavMenu.Item link={EventRoutes.Ideas} exact>
            {showIcons && <img src={IdeasIcon} />}
            <span>Ideas</span>
          </NavMenu.Item>
          {ideaSubMenu}
        </React.Fragment>
      )}
      {canViewSeries && (
        <React.Fragment>
          <NavMenu.Item link={EventRoutes.Series} exact>
            {showIcons && <img src={SeriesIcon} />}
            <span>Series</span>
          </NavMenu.Item>
          {seriesSubMenu}
        </React.Fragment>
      )}
      {canViewPipeline && (
        <NavMenu.Item link={EventRoutes.Pipeline} exact>
          {showIcons && <img src={PipelineIcon} />}
          <span>Pipeline</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default EventsTabMenuItems;
