import React from "react";
import classNames from "classnames";
import { NavMenu, SideColumn, useResponsive } from "@redriver/cinnamon";

const ContentWithMenu = ({ menuItems, children, className, columnFooter }) => {
  const { screenSize } = useResponsive();
  const isMobile = screenSize === "mobile";

  return (
    <SideColumn
      className={classNames({ collapsed: isMobile }, className)}
      renderColumn={() =>
        isMobile ? null : (
          <React.Fragment>
            <NavMenu vertical className="side-nav-menu">
              {menuItems}
            </NavMenu>
            {columnFooter && (
              <div className="column-footer">{columnFooter}</div>
            )}
          </React.Fragment>
        )
      }
    >
      {children}
    </SideColumn>
  );
};

export default ContentWithMenu;
