import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import {
  FormBuilder,
  Form,
  ErrorMessage,
  TriggerBuilder,
} from "@redriver/cinnamon";
import { UserTitleDropdown } from "components/forms";
import { SystemRoutes } from "constants/routes";
import { registerUser } from "./actions";
import ReCAPTCHA from "react-google-recaptcha";

const registerErrors = {
  2008: "This email address is already registered; please continue to log in using the link below. If you have forgotten your password you can reset it, or contact enquiries@wiltonpark.org.uk for further assistance and provide the name of the event that you are invited to.",
  2016: "This email address is already registered, but has not been verified. Please click on the link in your verification email, or contact enquiries@wiltonpark.org.uk for further assistance and provide the name of the event that you are invited to.",
  2017: "If you already have an account with us, please check your email to complete your user verification, or contact enquiries@wiltonpark.org.uk if you need any further assistance.",
};

const recaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY;

const Register = () => {
  const [requestSent, setRequestSent] = useState(false);
  const recaptchaRef = useRef();
  const [recaptchaError, setRecaptchaError] = useState(null);
  if (requestSent) {
    return (
      <div>
        <p>Your registration request has been sent.</p>
        <p>
          We will let you know when this has been approved, so please keep an
          eye on your inbox and check your junk folder.
        </p>
        <div style={{ marginTop: 20 }}>
          <Button primary fluid as={Link} to={SystemRoutes.Login}>
            Continue
          </Button>
        </div>
      </div>
    );
  }

  const submitUserSignUp = (data) => {
    // inject recaptcha value from ref value
    return registerUser(data, { recaptcha: recaptchaRef.current?.getValue() });
  };

  return (
    <FormBuilder
      submitAction={submitUserSignUp}
      onSubmitted={() => setRequestSent(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps}>
          <UserTitleDropdown
            field="title"
            label="Title"
            placeholder="Enter your title"
            disabled={submitting && slowSubmitting}
            autoFocus
            fluid
          />
          <Form.Input
            field="firstName"
            label="First Name"
            placeholder="Enter your first name"
            disabled={submitting && slowSubmitting}
            required
            fluid
          />
          <Form.Input
            field="lastName"
            label="Last Name"
            placeholder="Enter your last name"
            disabled={submitting && slowSubmitting}
            required
            fluid
          />
          <Form.Email
            field="email"
            label="Email"
            placeholder="Enter your email address"
            disabled={submitting && slowSubmitting}
            required
            fluid
          />
          <Form.TextArea
            field="eventComments"
            label="Please let us know the event you are interested in?"
            disabled={submitting && slowSubmitting}
            required
            requiredError="Please tell us about the event you're interested in"
            fluid
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReCAPTCHA sitekey={recaptchaSiteKey} ref={recaptchaRef} />
          </div>

          <TriggerBuilder
            onSubmit={events.onSubmit}
            renderTrigger={(fire, triggerState) => (
              <Button
                primary
                onClick={() => {
                  const recaptchaValue = recaptchaRef.current?.getValue();
                  setRecaptchaError(
                    recaptchaValue
                      ? null
                      : 'Please tick the "I\'m not a robot" box'
                  );
                  if (recaptchaValue) {
                    fire();
                  }
                }}
                disabled={submitting || triggerState.submitting}
                loading={
                  (submitting && slowSubmitting) ||
                  (triggerState.submitting && triggerState.slowSubmitting)
                }
                fluid
                size="large"
              >
                Sign up
              </Button>
            )}
          />
          <ErrorMessage
            header="Sign up error"
            error={error || recaptchaError}
            overrideMessages={registerErrors}
            omitCodes
          />
          <p>
            Return to <Link to={SystemRoutes.Login}>Log In</Link>
          </p>
          <p>
            Forgot Password?{" "}
            <Link to={SystemRoutes.ForgottenPassword}>Reset it</Link>
          </p>
        </Form>
      )}
    />
  );
};

export default Register;
