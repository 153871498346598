import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { PurchaseOrderRoutes } from "constants/routes";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";

const PurchaseOrderMenuItems = ({ purchaseOrderId, header }) => {
  return (
    <React.Fragment>
      {header && <NavMenu.Header>{header}</NavMenu.Header>}
      <NavMenu.Item
        link={PurchaseOrderRoutes.PurchaseOrder(purchaseOrderId)}
        exact
      >
        <span>Overview</span>
      </NavMenu.Item>
      <PermissionCheck action={Actions.View} target={Targets.PurchaseOrderNote}>
        <NavMenu.Item link={PurchaseOrderRoutes.Notes(purchaseOrderId)} exact>
          <span>Notes</span>
        </NavMenu.Item>
      </PermissionCheck>
    </React.Fragment>
  );
};

export default PurchaseOrderMenuItems;
