import React from "react";
import classNames from "classnames";
import { Placeholder } from "semantic-ui-react";

const ImageGalleryPlaceholder = ({ className }) => (
  <div className={classNames("image-gallery-placeholder", className)}>
    <Placeholder>
      <Placeholder.Image />
    </Placeholder>
  </div>
);

export default ImageGalleryPlaceholder;
