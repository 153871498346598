import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { lookupRequest, getLookupData } from "@redriver/cinnamon";
import { ConceptRoutes } from "constants/routes";
import { useRouteMatch } from "react-router";
import { ConceptMenuItems } from "components/menus";
import { conceptDetailsLookup } from "modules/lookups";

const ConceptSubMenu = () => {
  const conceptsMatch = useRouteMatch(ConceptRoutes.Concept());
  const conceptId = conceptsMatch?.params?.conceptId;

  const conceptData = useSelector((state) =>
    getLookupData(state, conceptDetailsLookup, conceptId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (conceptId) dispatch(lookupRequest(conceptDetailsLookup, conceptId));
  }, []);

  if (!conceptId || conceptData.loading || !conceptData.response) return null;

  return (
    <Menu.Menu className="submenu">
      <ConceptMenuItems
        conceptId={conceptId}
        header={conceptData.response.conceptRef}
      />
    </Menu.Menu>
  );
};

export default ConceptSubMenu;
