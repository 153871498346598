import React from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Header, Button } from "semantic-ui-react";
import {
  getAuthState,
  getAuthClaims,
  getAuthOptions,
} from "@redriver/cinnamon";
import { Login } from "features/System";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";

const LoginPage = ({
  loggedIn,
  emailVerified,
  useEmailVerification,
  location,
}) => {
  if (loggedIn && (emailVerified || !useEmailVerification)) {
    const nextRoute =
      location.state && location.state.nextPath
        ? location.state.nextPath
        : AppRoutes.Root;
    return <Redirect to={nextRoute} />;
  }

  return (
    <SystemPageContent
      title={
        <Header as="h1">
          <Header.Subheader>WELCOME BACK</Header.Subheader>
          Log in
        </Header>
      }
      headerAction={
        <Button basic as={Link} to={SystemRoutes.Register} size="large">
          Sign up
        </Button>
      }
      footer={
        <p>
          For help and support please contact{" "}
          <a href="mailto:enquiries@wiltonpark.org.uk">
            enquiries@wiltonpark.org.uk
          </a>{" "}
          providing the name of the event that you are invited to.
        </p>
      }
    >
      <Login nextPath={location?.state?.nextPath?.pathName} />
    </SystemPageContent>
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getAuthOptions();
  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
  };
};

export default connect(mapStateToProps)(LoginPage);
