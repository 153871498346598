import { apiGet, apiPatch } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/USER_SETUP";
export const VALIDATE_TOKEN = `${NAMESPACE}/VALIDATE`;
export const REQUEST_SETUP = `${NAMESPACE}/REQUEST`;

export const validateSetupToken = (token) =>
  apiGet(
    VALIDATE_TOKEN,
    `auth/setup-tokens/${token}`,
    {},
    { auth: false, preventErrorNotification: true }
  );

export const requestUserSetup = ({ newPassword }, token) =>
  apiPatch(
    REQUEST_SETUP,
    `auth/setup-tokens/${token}`,
    { isUsed: true, newPassword },
    { auth: false, preventErrorNotification: true }
  );
