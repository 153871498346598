import React from "react";
import classNames from "classnames";
import { Icon } from "semantic-ui-react";

const ToggleVisibilityButton = ({
  visible,
  name,
  className,
  onClick,
  ...props
}) => {
  return (
    <div
      className={classNames("toggle-visibility-button", className)}
      onClick={onClick}
    >
      <Icon className={`eye icon ${visible ? "slash" : ""}`} />
      {name && (
        <p>
          {visible ? "Hide" : "View"} {name}
        </p>
      )}
    </div>
  );
};

export default ToggleVisibilityButton;
