import React, { useState, useEffect, useCallback } from "react";
import { useEventListener } from "components/hooks";
import { useHistory } from "react-router-dom";
import { SystemRoutes } from "constants/routes";
import { getAuthClaims, useAuthClaims, useAuthState } from "@redriver/cinnamon";

const UserInactivityCheck = () => {
  const history = useHistory();
  const userClaims = useAuthClaims();
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const timeoutSeconds = parseInt(userClaims.systemTimeout ?? 1) * 3600;

  useEffect(() => {
    if (elapsedSeconds >= timeoutSeconds) history.push(SystemRoutes.Logout);

    const timer = setInterval(() => {
      setElapsedSeconds(elapsedSeconds + 60);
    }, 60 * 1000);

    return () => clearInterval(timer);
  }, [elapsedSeconds]);

  const handler = useCallback(() => {
    setElapsedSeconds(0);
  }, []);

  useEventListener("mousemove", handler);
  useEventListener("keydown", handler);
  useEventListener("mousedown", handler);

  return null;
};

export default UserInactivityCheck;
