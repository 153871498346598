import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";
import {
  getAuthState,
  getAuthClaims,
  getAuthOptions,
  FormBuilder,
  Form,
  ErrorMessage,
} from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { requestLogin } from "./actions";
import { EmailVerificationResender } from "../EmailVerification";
import { AzureAdSignInButton } from "./AzureAdSignInButton";

const loginErrors = {
  "Setup has not been completed":
    "There is no active account for this user. If you have been given an account, please check your email to complete your user verification by clicking on the verification link within, or contact enquiries@wiltonpark.org.uk for further assistance and provide the name of the event that you are invited to.",
};

const Login = ({ loggedIn, emailVerified, useEmailVerification, nextPath }) => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState(null);

  const isUserInternal = () => {
    const domains = ["river.red", "wiltonpark.org.uk"];
    return domains.some((x) =>
      email.toLowerCase().endsWith("@" + x.toLowerCase())
    );
  };

  if (loggedIn) {
    if (emailVerified || !useEmailVerification) {
      return <p>You have been successfully logged in</p>;
    }

    return (
      <div>
        <p>Your email address has not been verified</p>
        <EmailVerificationResender />
      </div>
    );
  }

  return (
    <FormBuilder
      submitAction={requestLogin}
      onSubmit={(formData) => setEmail(formData.email)}
      onSubmitted={({ secondFactor, tokens }) => {
        if ((secondFactor?.availableTypes ?? []).length > 0) {
          const {
            registeredTypes,
            availableTypes,
            recoveryTypes,
            token,
          } = secondFactor;
          const nextPath = location.state?.nextPath;
          history.push(SystemRoutes.Authenticate, {
            registeredTypes,
            availableTypes,
            recoveryTypes,
            token,
            loginTokens: tokens,
            nextPath,
          });
        }
      }}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps}>
          <Form.Email
            field="email"
            placeholder="Enter email address"
            disabled={submitting && slowSubmitting}
            autoFocus
            required
            fluid
            icon="at"
          />
          <Form.Password
            field="password"
            placeholder="Enter password"
            disabled={submitting && slowSubmitting}
            required
            fluid
            toggleVisibility
          />
          <Button
            primary
            onClick={events.onSubmit}
            disabled={submitting}
            loading={submitting && slowSubmitting}
            fluid
            size="large"
          >
            Log in
          </Button>
          <ErrorMessage
            header="Log in error"
            error={error}
            overrideMessages={
              email != null && isUserInternal()
                ? {
                    ...loginErrors,
                    "Wrong email address or password":
                      "If you are a Wilton Park employee, please use the 'Sign In with Microsoft' option below.",
                  }
                : loginErrors
            }
            omitCodes={
              error?.response[0]?.code === 915004 &&
              error?.response[0]?.message === "Wrong email address or password"
            }
          />
          <p>
            Forgot Password?{" "}
            <Link to={SystemRoutes.ForgottenPassword}>Reset it</Link>
          </p>
          <AzureAdSignInButton nextPath={nextPath} />
        </Form>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getAuthOptions();
  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
  };
};

export default connect(mapStateToProps)(Login);
