import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { DashboardRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";
import EventsIcon from "assets/icons/events.svg";
import ParticipantsIcon from "assets/icons/users.svg";
import FinancialIcon from "assets/icons/financial.svg";
import BusinessDevelopmentIcon from "assets/icons/concepts.svg";
import ImpactsAndEvidenceIcon from "assets/icons/evidence.svg";

const DashboardTabMenuItems = ({ showIcons }) => {
  const canViewEvents = usePermission(Targets.EventsDashboard, Actions.View);
  const canViewParticipants = usePermission(
    Targets.ParticipantsDashboard,
    Actions.View
  );
  const canViewFinancial = usePermission(
    Targets.FinancialDashboard,
    Actions.View
  );
  const canViewBusinessDevelopment = usePermission(
    Targets.BusinessDevelopmentDashboard,
    Actions.View
  );
  const canViewImpactsAndEvidence = usePermission(
    Targets.ImpactsAndEvidenceDashboard,
    Actions.View
  );

  return (
    <React.Fragment>
      {canViewEvents && (
        <NavMenu.Item link={DashboardRoutes.Events}>
          {showIcons && <img src={EventsIcon} />}
          <span>Events</span>
        </NavMenu.Item>
      )}
      {canViewParticipants && (
        <NavMenu.Item link={DashboardRoutes.Participants}>
          {showIcons && <img src={ParticipantsIcon} />}
          <span>Participants</span>
        </NavMenu.Item>
      )}
      {canViewFinancial && (
        <NavMenu.Item link={DashboardRoutes.Financial}>
          {showIcons && <img src={FinancialIcon} />}
          <span>Financial</span>
        </NavMenu.Item>
      )}
      {canViewBusinessDevelopment && (
        <NavMenu.Item link={DashboardRoutes.BusinessDevelopment}>
          {showIcons && <img src={BusinessDevelopmentIcon} />}
          <span>Business Development</span>
        </NavMenu.Item>
      )}
      {canViewImpactsAndEvidence && (
        <NavMenu.Item link={DashboardRoutes.ImpactsAndEvidence}>
          {showIcons && <img src={ImpactsAndEvidenceIcon} />}
          <span>Impacts & Evidence</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default DashboardTabMenuItems;
