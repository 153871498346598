import React from "react";
import classNames from "classnames";
import { Icon } from "semantic-ui-react";

const ImageGalleryAdd = ({ className, onClick }) => (
  <div className={classNames("image-gallery-add", className)} onClick={onClick}>
    <Icon name="add" />
  </div>
);

export default ImageGalleryAdd;
