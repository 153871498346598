import React from "react";
import classNames from "classnames";
import { ExternalLink } from "components/navigation";
import PropTypes from "prop-types";
import SharepointLogo from "assets/logos/sharepoint.svg";
import OneNoteLogo from "assets/logos/onenote.svg";

const QuickLinksDisplay = ({
  sharePointLink,
  oneNoteLink,
  hideOneNoteLink = false,
}) => (
  <div
    className={classNames("quick-links-display", {
      "one-note-hidden": hideOneNoteLink,
    })}
  >
    <div className={classNames("quick-link", { disabled: !sharePointLink })}>
      {sharePointLink ? (
        <ExternalLink href={sharePointLink}>
          <img src={SharepointLogo} />
        </ExternalLink>
      ) : (
        <img src={SharepointLogo} />
      )}
    </div>
    {!hideOneNoteLink && (
      <div className={classNames("quick-link", { disabled: !oneNoteLink })}>
        {oneNoteLink ? (
          <ExternalLink href={oneNoteLink}>
            <img src={OneNoteLogo} />
          </ExternalLink>
        ) : (
          <img src={OneNoteLogo} />
        )}
      </div>
    )}
  </div>
);

QuickLinksDisplay.propTypes = {
  sharePointLink: PropTypes.string,
  oneNoteLink: PropTypes.string,
  hideOneNoteLink: PropTypes.bool,
};

export default QuickLinksDisplay;
