import React from "react";
import classNames from "classnames";
import { PageContent } from "@redriver/cinnamon";

const AppPageContent = ({ middle, className, maxWidth, ...otherProps }) => (
  <React.Fragment>
    <div className="app-breadcrumbs"></div>
    <PageContent
      {...otherProps}
      className={classNames(
        "app-page-content",
        { middle, "max-page-size": maxWidth },
        className
      )}
    />
  </React.Fragment>
);

export default AppPageContent;
