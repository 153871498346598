import React from "react";
import { Button } from "semantic-ui-react";
import classNames from "classnames";

const EllipsisButton = ({ className, ...props }) => (
  <Button
    primary
    compact
    content=""
    icon="ellipsis horizontal"
    className={classNames("ellipsis-button", className)}
    {...props}
  />
);

export default EllipsisButton;
