export const ExternalUrls = {
  WiltonPark: {
    PrivacyPolicy: "https://www.wiltonpark.org.uk/privacy-notice/",
    InformationCharter: "https://www.wiltonpark.org.uk/information-charter/",
    TermsAndConditions: "https://www.wiltonpark.org.uk/terms-and-conditions/",
    Accessibility: "https://www.wiltonpark.org.uk/accessibility/",
    ContactUs: "https://www.wiltonpark.org.uk/homepage/contact-us/",
    Protocol:
      "https://www.wiltonpark.org.uk/your-stay-at-wilton-park/conference-format-and-guidelines/#Discussion_protocol",
    TravelPolicy:
      "https://www.wiltonpark.org.uk/app/uploads/2024/08/Wilton-Park-Travel-policy-for-participants-version-DT.pdf",
  },
  SocialMedia: {
    Twitter: "https://x.com/WILTONPARK",
    Facebook: "https://www.facebook.com/WiltonParkFCDO/",
    LinkedIn: "https://www.linkedin.com/company/wilton-park/",
    Instagram: "https://www.instagram.com/wiltonpark/",
    FriendsOfWiltonParkLinkedin: "https://www.linkedin.com/groups/13969953/",
  },
  GoogleMapsSearch: (query) =>
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      query
    )}`,
  Videos: {
    YouTubeMatch: /^(https?:\/\/)?(www\.)?((youtube\.com)|(youtu\.be))\/.+/i,
    VimeoMatch: /^(https?:\/\/)?(www\.)?vimeo\.com\/.+/i,
  },
  AuthenticatorQrCode: (secret, label = process.env.AUTHENTICATOR_LABEL) =>
    `otpauth://totp/${encodeURIComponent(label)}?secret=${encodeURIComponent(
      secret
    )}`,
};
