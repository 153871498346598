import React, { useState, useEffect } from "react";

const useWidthBreakPoint = (value, isMaxValue = true) => {
  const [triggered, setTriggered] = useState(undefined);

  useEffect(() => {
    function onResized() {
      const width = window.innerWidth;
      setTriggered(value && (isMaxValue ? width <= value : width >= value));
    }

    window.addEventListener("resize", onResized);

    onResized();
    return () => window.removeEventListener(onResized, null);
  }, []);

  return triggered;
};

export default useWidthBreakPoint;
