import React from "react";
import { Lookup } from "@redriver/cinnamon";
import { eventPartnerPrivacyLookup } from "modules/lookups";
import { CascadingRadioGroup } from "./fields";

const EventPartnerPrivacyRadioGroup = (props) => (
  <Lookup
    lookup={eventPartnerPrivacyLookup}
    render={({ response }) => (
      <CascadingRadioGroup
        {...props}
        disabled={response ? props.disabled : true}
        options={response || [{ value: "_" }]}
      />
    )}
  />
);

export default EventPartnerPrivacyRadioGroup;
