import React from "react";
import { Form } from "@redriver/cinnamon";
import CascadingRadioGroup from "./CascadingRadioGroup";

const YesNoRadioGroup = ({
  yesLabelOverride,
  noLabelOverride,
  vertical,
  ...props
}) => {
  const options = [
    { value: true, text: yesLabelOverride || "Yes" },
    { value: false, text: noLabelOverride || "No" },
  ];

  if (vertical) {
    return <Form.RadioGroup {...props} options={options} />;
  }

  return <CascadingRadioGroup {...props} options={options} />;
};

export default YesNoRadioGroup;
