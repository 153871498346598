import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { FormModal } from "components/modals";

const InsertLink = ({ quill, open, onClose }) => (
  <ModalBuilder
    withForm
    visible={open}
    onLoad={() => {
      if (!quill) return {};
      const editor = quill.getEditor();
      const format = editor.getFormat();
      return format.link ? { link: format.link } : {};
    }}
    onSubmit={({ link }) => {
      if (!quill) return;
      quill.focus();
      const editor = quill.getEditor();
      const range = editor.getSelection();
      const cursorPosition = range.index;
      const selection = editor.getText(cursorPosition, range.length);
      const source = "user";

      if (selection.length > 0) {
        const format = editor.getFormat();
        if (format.link === link) return;
        editor.removeFormat(range, source);
        editor.format("link", link, source);
      } else {
        editor.insertText(cursorPosition, link, source);
        editor.formatText(cursorPosition, link.length, "link", link, source);
      }
    }}
    onSubmitted={onClose}
    onCancelled={onClose}
    renderModal={(modalProps, formProps) => (
      <FormModal {...modalProps} header="Insert Link" size="tiny">
        <Form {...formProps}>
          <Form.Url field="link" label="URL" fluid required formatValue />
        </Form>
      </FormModal>
    )}
  />
);

export default InsertLink;
