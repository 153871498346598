import React from "react";
import { Feed } from "semantic-ui-react";

const AlertListContent = ({ children }) => (
  <Feed.Event className="alert-content">
    <Feed.Content>{children}</Feed.Content>
  </Feed.Event>
);

export default AlertListContent;
