import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { AppRoutes, ContactRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";
import ContactsIcon from "assets/icons/contacts.svg";
import OrganisationsIcon from "assets/icons/organisations.svg";
import SearchIcon from "assets/icons/search.svg";

const ContactsTabMenuItems = ({ showIcons, organisationSubMenu }) => {
  const canViewContacts = usePermission(Targets.Contact, Actions.View);
  const canViewOrganisations = usePermission(
    Targets.Organisation,
    Actions.View
  );
  const canViewContactSearch = usePermission(
    Targets.ContactSearch,
    Actions.View
  );

  return (
    <React.Fragment>
      {canViewContacts && (
        <NavMenu.Item link={AppRoutes.Contacts} exact>
          {showIcons && <img src={ContactsIcon} />}
          <span>Contacts</span>
        </NavMenu.Item>
      )}
      {canViewOrganisations && (
        <React.Fragment>
          <NavMenu.Item link={ContactRoutes.Organisations}>
            {showIcons && <img src={OrganisationsIcon} />}
            <span>Organisations</span>
          </NavMenu.Item>
          {organisationSubMenu}
        </React.Fragment>
      )}
      {canViewContactSearch && (
        <NavMenu.Item link={ContactRoutes.Search}>
          {showIcons && <img src={SearchIcon} />}
          <span>Advanced Contact Search</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default ContactsTabMenuItems;
