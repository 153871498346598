import React from "react";
import PotentialYearDropdown from "./PotentialYearDropdown";
import { getCurrentFinancialYear } from "modules/helpers";

const FinancialYearDropdown = (props) => {
  const currentYear = getCurrentFinancialYear();

  const years = [];
  for (let i = currentYear - 1; i <= currentYear + 3; i++) {
    years.push(i);
  }

  return <PotentialYearDropdown years={years} {...props} />;
};

export default FinancialYearDropdown;
