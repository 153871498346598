import { ResourceType } from "constants/enums";
import { ExternalUrls } from "constants/urls";
import { formatBytes, getFileExtension } from "./files";

export const ResourceFileType = {
  Image: "Image",
  PDF: "PDF",
  Word: "Word",
  Excel: "Excel",
  PowerPoint: "PowerPoint",
  Text: "Text",
  Zip: "Zip",
  Unknown: "Unknown",
};

export const ResourceFileExtensions = {
  [ResourceFileType.Image]: ["jpg", "jpeg", "png", "gif"],
  [ResourceFileType.PDF]: ["pdf"],
  [ResourceFileType.Word]: ["doc", "docx"],
  [ResourceFileType.Excel]: ["xls", "xlsx"],
  [ResourceFileType.PowerPoint]: ["ppt", "pptx"],
  [ResourceFileType.Text]: ["txt"],
  [ResourceFileType.Zip]: ["zip"],
};

export const ResourceUrlType = {
  Video: "Video",
  Link: "Link",
};

export const getResourceFileType = (fileName) => {
  const ext = getFileExtension(fileName);
  if (!ext) return ResourceFileType.Unknown;
  const fileType = Object.keys(ResourceFileExtensions).find((k) =>
    ResourceFileExtensions[k].includes(ext)
  );
  return fileType ?? ResourceFileType.Unknown;
};

export const getResourceUrlType = (url) => {
  if (
    url.match(ExternalUrls.Videos.YouTubeMatch) ||
    url.match(ExternalUrls.Videos.VimeoMatch)
  ) {
    return ResourceUrlType.Video;
  }
  return ResourceUrlType.Link;
};

export const formatResourceInfo = (type, fileName, fileSize, url) => {
  if (type === ResourceType.File) {
    const fileType = getResourceFileType(fileName);
    if (fileType === ResourceFileType.Unknown) return "";
    if (fileType === ResourceFileType.Image) return fileType;
    return fileSize ? fileType + " " + formatBytes(fileSize, 1) : fileType;
  }

  if (type === ResourceType.Url) {
    return getResourceUrlType(url);
  }

  return "";
};
