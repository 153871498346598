const actionClaimExists = (claimValue, action, any) => {
  if (!claimValue) return false;
  const claimArray = Array.isArray(claimValue) ? claimValue : [claimValue];
  if (Array.isArray(action)) {
    const hasClaim = (claim) => action.includes(claim);
    return any ? claimArray.some(hasClaim) : claimArray.every(hasClaim);
  } else {
    return claimArray.includes(action);
  }
};

export const claimExists = (claimData, target, action, any) => {
  if (!claimData) return false;
  if (Array.isArray(target)) {
    const targetClaimExists = (t) =>
      actionClaimExists(claimData[t], action, any);
    return any
      ? target.some(targetClaimExists)
      : target.every(targetClaimExists);
  } else {
    return actionClaimExists(claimData[target], action, any);
  }
};
