import React from "react";
import { Lookup, Form } from "@redriver/cinnamon";
import { eventFormatLookup } from "modules/lookups";

const EventFormatCheckboxGroup = (props) => (
  <Lookup
    lookup={eventFormatLookup}
    render={({ response }) => (
      <Form.CheckboxGroup {...props} options={response || []} />
    )}
  />
);

export default EventFormatCheckboxGroup;
