import { apiGet, apiPut, apiRequests, mockSuccess } from "@redriver/cinnamon";
import { getStage, getFilters } from "./selectors";
import { PipelineStageTypes } from "constants/enums";

const NAMESPACE = "EVENTS/PIPELINE";

export const ActionTypes = {
  LoadMoreFromIndividualStage: `${NAMESPACE}/LOAD_MORE_FROM_INDIVIDUAL_STAGE`,
  GetStageConcepts: `${NAMESPACE}/GET_STAGE_CONCEPTS`,
  SetFilters: `${NAMESPACE}/SET_FILTERS`,
  SetLoadingAll: `${NAMESPACE}/SET_LOADING_ALL`,
  UpdateConceptLastViewed: `${NAMESPACE}/UDPATE_CONCEPT_LAST_VIEWED`,
  UpdateAllStages: `${NAMESPACE}/UPDATE_ALL_STAGES`,
  AvailableSites: `${NAMESPACE}/AVAILABLE_SITES`,
  AvailableTeamMembers: `${NAMESPACE}/AVAILABLE_TEAMMEMBERS`,
  AvailablePotentialYears: `${NAMESPACE}/AVAILABLE_POTENTIAL_YEARS`,
};

const pageSize = 20;

export const setFilters = (filters) => ({
  type: ActionTypes.SetFilters,
  filters,
});

export const loadMoreFromIndividualStage = (stageType) => async (
  dispatch,
  getState
) => {
  const filters = getFilters(getState());
  const stage = getStage(getState(), stageType);
  const request = {
    ...filters,
    pageSize,
    pageNumber: stage.pageNumber + 1,
  };

  return dispatch(
    apiGet(
      ActionTypes.LoadMoreFromIndividualStage,
      `pipeline/${stageType}`,
      request,
      {
        actionCustomData: {
          stageType,
        },
      }
    )
  );
};

const getStageConcepts = ({ filters }, stageType) => {
  const request = {
    ...filters,
    pageSize,
    pageNumber: 1,
  };

  return apiGet(ActionTypes.GetStageConcepts, `pipeline/${stageType}`, request);
};

export const loadAllStages = () => async (dispatch, getState) => {
  const filters = getFilters(getState());

  const {
    dates = {},
    createdDates = {},
    lastViewedDates = {},
    ...otherFilters
  } = filters;
  const requestFilters = {
    dateFrom: dates.start,
    dateTo: dates.end,
    createdFrom: createdDates.start,
    createdTo: createdDates.end,
    lastViewedFrom: lastViewedDates.start,
    lastViewedTo: lastViewedDates.end,
    ...otherFilters,
  };

  const triggeredAt = new Date().getTime();

  dispatch(setLoadingAll(true, triggeredAt));
  const stage1 = dispatch(
    getStageConcepts({ filters: requestFilters }, PipelineStageTypes.StageOne)
  );
  const stage2 = dispatch(
    getStageConcepts({ filters: requestFilters }, PipelineStageTypes.StageTwo)
  );
  const stage3 = dispatch(
    getStageConcepts(
      { filters: requestFilters },
      PipelineStageTypes.ReadyToConvert
    )
  );
  const output = await Promise.all([stage1, stage2, stage3]);

  dispatch({
    type: ActionTypes.UpdateAllStages,
    triggeredAt,
    stagesData: [
      { ...output[0], stage: PipelineStageTypes.StageOne },
      { ...output[1], stage: PipelineStageTypes.StageTwo },
      { ...output[2], stage: PipelineStageTypes.ReadyToConvert },
    ],
  });
};

export const updateStage = (data) => ({
  type: ActionTypes.UpdateStage,
  data,
});

export const setLoadingAll = (loading, triggeredAt) => ({
  type: ActionTypes.SetLoadingAll,
  loading,
  triggeredAt,
});

export const updateConceptLastViewed = (conceptId) =>
  apiPut(
    ActionTypes.UpdateConceptLastViewed,
    `concepts/${conceptId}/last-viewed`
  );

export const getAvailableSites = ({ search }) => {
  const request = {
    search,
    maxResults: 10,
  };
  return apiGet(
    ActionTypes.AvailableSites,
    `concepts/available-sites`,
    request
  );
};

export const getAvailableTeamMembers = ({ search }) =>
  apiGet(ActionTypes.AvailableTeamMembers, `concepts/available-team-members`, {
    search,
  });

export const getAvailablePotentialYears = () =>
  apiGet(
    ActionTypes.AvailablePotentialYears,
    "concepts/available-potential-years"
  );
