import React from "react";
import classNames from "classnames";

const ClickArea = ({ className, children, ...props }) => {
  return (
    <div className={classNames("click-area", className)} {...props}>
      {children}
    </div>
  );
};
export default ClickArea;
