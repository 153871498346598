import React from "react";
import EditIcon from "assets/icons/pencil.svg";
import FloatingButton from "./FloatingButton";

const FloatingEditButton = ({
  onClick,
  large = false,
  className,
  ...props
}) => (
  <FloatingButton
    icon={EditIcon}
    onClick={onClick}
    large={large}
    className={className}
    {...props}
  />
);

export default FloatingEditButton;
