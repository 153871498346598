import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import {
  useAuthClaims,
  useApiGet,
  NavMenu,
  ActionMenu,
  useLookup,
} from "@redriver/cinnamon";
import { AppRoutes, AccountRoutes, SystemRoutes } from "constants/routes";
import { ProfileImageType } from "constants/enums";
import { UserIcon } from "components/icons";
import { myProfileImagesLookup } from "modules/lookups";

const AccountNavItem = () => {
  const { userName } = useAuthClaims();
  const [{ response }, resetLookup] = useLookup(myProfileImagesLookup);

  const profileImages = response ?? [];
  const userImage = profileImages.find((x) => x.type == ProfileImageType.User)
    ?.thumbnailUrl;

  return (
    <ActionMenu
      compact
      className="account-menu"
      trigger={
        <NavMenu.Item className="account-nav-item">
          <UserIcon userName={userName} image={userImage} />
          <Icon name="caret down" />
        </NavMenu.Item>
      }
    >
      <ActionMenu.Header>{userName}</ActionMenu.Header>
      <ActionMenu.Item as={Link} to={AccountRoutes.Profile}>
        My Public Profile
      </ActionMenu.Item>
      <ActionMenu.Item as={Link} to={AccountRoutes.Privacy}>
        Privacy Settings
      </ActionMenu.Item>
      <ActionMenu.Item as={Link} to={AppRoutes.Account}>
        My Account
      </ActionMenu.Item>
      <ActionMenu.Item as={Link} to={SystemRoutes.Logout}>
        Log Out
      </ActionMenu.Item>
    </ActionMenu>
  );
};

export default AccountNavItem;
